<app-navbar></app-navbar>
<!-- <div class="vaction-header ">
  <h1 class="title"> HOTELS </h1>
</div> -->


<div class="container mt-5">
  <div class="row">
    <div class="small-screen-container">
      <app-hotels-engine [loadingSpinner]="loadingSpinner" (hotelsDataflow)="addhotelsDataNew($event)"></app-hotels-engine>
    </div>

    <div class="col-3 filter my-3">
      <app-hotels-filter></app-hotels-filter>
    </div>
    <div class="col-8 result-hotels team mb-4 ">
      <div class="member d-flex align-items-start my-4 " data-aos="zoom-in" data-aos-delay="100" *ngFor="let item of hotelsData">
        <div class="pic" >
          <owl-carousel-o  [options]="customOptions">
            <ng-container  >
                <ng-template  carouselSlide >
                  <img
                    [src]="item.images[0]"
                    alt="hotels ph "
                    class=" w-100"
                    height="300px"
                    style="object-fit: cover;"
                  />
                </ng-template>
                <ng-template  carouselSlide >
                  <img
                    [src]="item.images[1]"
                    alt="hotels ph "
                    class=" w-100"
                    height="300px"
                    style="object-fit: cover;"
                  />
                </ng-template>
                <ng-template  carouselSlide >
                  <img
                    [src]="item.images[2]"
                    alt="hotels ph "
                    class=" w-100"
                    height="300px"
                    style="object-fit: cover;"
                  />
                </ng-template>
              </ng-container>
          </owl-carousel-o>
        </div>
        <div class="member-info " >
          <a [routerLink]="['/hotel-details/']">
          <h4> {{item.fao_hotel.name}}</h4>
          </a>
          <span>Entire Hotel</span>
          <span>Sleeps 9, 3 bedrooms, 2 bathrooms</span>
          <span>{{item.f_a_o_hotel_branch.City.name}} </span>
          <p><img style="width: 17px;margin-bottom: 7px;" class="mx-1" src="assets/images/hotels/icon/coffee-cup.png"> Breakfast included
            <strong class="mx-4">
              <img style="width: 17px;margin-bottom: 7px;" class="mx-1" src="assets/images/hotels/icon/kitchen.png">
              Kitchen </strong>
          </p>
          <div class="featured text-right w-100">
            <!--              <h3>Business Plan</h3>-->
            <h4>${{item.night_price}}</h4>
            <span>$400 total</span>
            <span>ncludes taxes & fees</span>
            <!--              <a href="#" class="buy-btn">Get Started</a>-->
          </div>
          <div class="d-flex">
           <span class="fw-bold pl-2">{{item.fao_hotel.hotel_rate}}</span><span>/10 -</span>
            <span class="mx-1"> ({{item.fao_hotel.number_of_rates}} reviews)</span>
          </div>
        </div>
      </div>
      <!-- <div class="member d-flex align-items-start my-4 " data-aos="zoom-in" data-aos-delay="100">
        <div class="pic">
          <owl-carousel-o  [options]="customOptions">
            <ng-container >
              <ng-container >
                <ng-template carouselSlide>
                  <img
                    src="assets/images/hotels/2.jpg"
                    alt="hotels ph "
                    class=" w-100"
                  />
                </ng-template>
                <ng-template carouselSlide>
                  <img
                    src="assets/images/hotels/3.jpg"
                    alt="hotels ph "
                    class=" w-100"
                  />
                </ng-template>
                <ng-template carouselSlide>
                  <img
                    src="assets/images/hotels/1.jpg"
                    alt="hotels ph "
                    class=" w-100"
                  />
                </ng-template>
              </ng-container>
            </ng-container>
          </owl-carousel-o>


      
        </div>
        

    </div> -->
   
  </div>
</div>


<app-footer></app-footer>


