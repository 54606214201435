<div class="details-traveler">
  <div class="section-details">
    <div class="form-details">
      <div class="header-content">
        <img alt="Additional-Info" title="Additional-Info"
          src="../../../../assets/images/dashboard/Additional-Info.svg" />
        <div class="item-header">
          <h4>Passengers</h4>
          <p>
            When you book your trip you can Automatically select your
            co-traveller And book faster.
          </p>
        </div>
      </div>
      <hr class="hr" />
      <div class="travellers">
        <div class="col-12">
          <ngx-skeleton-loader *ngIf="loading" [theme]="{ height: '300px' }"></ngx-skeleton-loader>
        </div>
        <div *ngFor="let member of familyMembers; let i = index" class="members">
          <div class="col-12">
            <div class="row">
              <ng-container *ngIf="noPassport && noPassport[member.id]">
                <span>passport is required</span>
              </ng-container>
            </div>
          </div>

          <div class="col-12">
            <div class="row align-items-center">
              <ng-container *ngIf="noPassport && noPassport[member.id]">
                <span>passport is required</span>
              </ng-container>
              <div class="col-5 d-flex align-items-center">
                <span *ngIf="member.image" class="col-2"><img [alt]="member.name"
                    style="height: 40px; width: 40px; border-radius: 20px" src="{{ member.image }}" />
                </span> <span *ngIf="!member.image" class="col-2"><img [alt]="member.name"
                    style="height: 40px; width: 40px; border-radius: 20px"
                    src="../../../../assets/newIcon/user-profile.svg" /></span>
                <span class="info col-10">{{
                  member.first_name.toUpperCase() +
                  " " +
                  member.middle_name.toUpperCase() +
                  " " +
                  member.last_name.toUpperCase()
                  }}</span>
              </div>
              <div class="col-2">
                <span class="info">{{ member.family_relation.name }}</span>
              </div>
              <div class="col-2">
                <span *ngIf="member.passport" class="info">{{
                  member.passport.passport_number
                  }}</span>
                <span *ngIf="!member.passport" class="info">----- -----</span>
              </div>
              <div class="col-3">
                <div class="row">
                  <button class="btn save-btn w-auto" *ngIf="member.passport" (click)="
                  getPassportInfo(
                    member.id,
                    member.first_name.toUpperCase() +
                      ' ' +
                      member.middle_name.toUpperCase() +
                      ' ' +
                      member.last_name.toUpperCase(),
                    member.family_relation.name
                  );
                  passportInfo = !passportInfo
                ">
                <img title="edit" alt="edit" style="height: 18px" src="../../../../assets/images/dashboard/edit.svg" />
              </button>
              <button class="btn save-btn w-auto" *ngIf="!member.passport"
                (click)="addPassport = !addPassport; newPassport(member.id)">
                <img alt="add" title="add" style="height: 18px" src="../../../../assets/images/dashboard/add%20(2).svg" />
              </button>
                  <button class="btn save-btn w-auto" style="margin-right: 10px"
                    (click)="info(member.id); showTraveler = !showTraveler">
                    <img alt="information" title="information" style="height: 18px"
                      src="./assets/images/dashboard/information.svg" />
                  </button>
                  <button *ngIf="!selectable" class="btn save-btn w-auto" (click)="openDialog(member.id)">
                    <img alt="Icon%20material-delete-forever" title="Icon%20material-delete-forever"
                      src="./assets/images/dashboard/Icon%20material-delete-forever.svg" />
                  </button>
                  <ng-container *ngIf="selectable">
                    <mat-checkbox [disableRipple]="true" (change)="toggleCheck($event, member)"></mat-checkbox>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-add-member *ngIf="addTraveler" (reloadFamilyMember)="getFamilyMembers()"></app-add-member>
    <app-edit-member *ngIf="showTraveler" [familyMembers]="familyMembers" [id]="memberId" (reloadFamilyMember)="getFamilyMembers()" (closeForm)="closeMember()">
    </app-edit-member>
    <app-add-passport *ngIf="addPassport" [id]="memberId" (reloadFamilyMember)="getFamilyMembers()" (closeForm)="closeAddPassport()"></app-add-passport>
    <app-edit-passport *ngIf="passportInfo" [id]="memberId" (reloadFamilyMember)="getFamilyMembers()" [memberName]="memberName" [memberRelation]="memberRelation"
      [familyMembers]="familyMembers" (closeForm)="closePassport()"></app-edit-passport>
  </div>
  <div class="Add-traveller-btn">
    <a (click)="opedAddMemberDialog()">
      <img alt="" src="./assets/images/dashboard/Group%2015504.svg" />
      <span>ADD MEMBER</span>
    </a>
  </div>
</div>