<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center Font-Family">Contact Us</h1>
        </div>
    </div>
    <div class="help-center-body">
        <!-- start contact-us -->
        <div class="container">
            <div class="contact-us">
                <form [formGroup]="contactUsForm" (ngSubmit)="onSubmitContactUsForm(contactUsForm.value)"
                    *ngIf="!formSubmitted">
                    <div class="inside">
                        <h3 class="Font-family text-center">Send Us a Message </h3>
                        <p class="Font-family text-center " style="font-size: 15px;"> Enter these details and we will contact you </p>
                        <!-- Full name -->
                        <div class="field">
                            <label for="name"
                            class="Font-family"
                                [ngClass]="contactUsForm.get('fullName').invalid && contactUsForm.get('fullName').touched ?'text-danger':''">Full
                                Name</label>
                            <input id="name" type="text" formControlName="fullName" required
                                [ngClass]="contactUsForm.get('fullName').invalid && contactUsForm.get('fullName').touched ? 'has-error':'no-error'"
                                (keypress)="navigateOnSamePage()" />
                            <span *ngFor=" let validation of validationMessages.fullName">
                                <small class="text-danger"
                                    *ngIf="contactUsForm.get('fullName').hasError(validation.type) && (contactUsForm.get('fullName').dirty || contactUsForm.get('fullName').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- Email -->
                        <div class="field">
                            <label for="email"
                            class="Font-family"
                                [ngClass]="contactUsForm.get('email').invalid && contactUsForm.get('email').touched ?'text-danger':''">Email
                                Address</label>
                            <input id="email" type="email" formControlName="email" required
                                [ngClass]="contactUsForm.get('email').invalid && contactUsForm.get('email').touched ? 'has-error':'no-error'"
                                (keypress)="navigateOnSamePage()" />
                            <span *ngFor=" let validation of validationMessages.email">
                                <small class="text-danger"
                                    *ngIf="contactUsForm.get('email').hasError(validation.type) && (contactUsForm.get('email').dirty || contactUsForm.get('email').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- mobile number -->
                        <div class="field">
                            <label for="mobile"
                            class="Font-family"
                                [ngClass]="contactUsForm.get('mobileNumber').invalid && contactUsForm.get('mobileNumber').touched ?'text-danger':''">Mobile
                                Number</label>
                            <input id="mobile" type="text" formControlName="mobileNumber" required
                                [ngClass]="contactUsForm.get('mobileNumber').invalid && contactUsForm.get('mobileNumber').touched ? 'has-error':'no-error'"
                                (keypress)="navigateOnSamePage()" />
                            <span *ngFor=" let validation of validationMessages.mobileNumber">
                                <small class="text-danger"
                                    *ngIf="contactUsForm.get('mobileNumber').hasError(validation.type) && (contactUsForm.get('mobileNumber').dirty || contactUsForm.get('mobileNumber').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        
                        <!-- subject -->
                        <div class="field">
                            <label for="subject"
                                [ngClass]="contactUsForm.get('subject').invalid && contactUsForm.get('subject').touched ?'text-danger':''">*Subject</label>
                            <input id="subject" type="text" formControlName="subject" required
                                [ngClass]="contactUsForm.get('subject').invalid && contactUsForm.get('subject').touched ? 'has-error':'no-error'"
                                (keypress)="navigateOnSamePage()" />
                            <span *ngFor=" let validation of validationMessages.subject">
                                <small class="text-danger"
                                    *ngIf="contactUsForm.get('subject').hasError(validation.type) && (contactUsForm.get('subject').dirty || contactUsForm.get('subject').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- message -->
                        <div class="field">
                            <label for="message"
                            class="Font-family"
                                [ngClass]="contactUsForm.get('message').invalid && contactUsForm.get('message').touched ?'text-danger':''">Your
                                Message</label>
                            <textarea id="message" placeholder="Tell us anything you want to.."
                                formControlName="message" required
                                [ngClass]="contactUsForm.get('message').invalid && contactUsForm.get('message').touched ? 'has-error':'no-error'"
                                (keypress)="navigateOnSamePage()"></textarea>
                            <span *ngFor=" let validation of validationMessages.message">
                                <small class="text-danger"
                                    *ngIf="contactUsForm.get('message').hasError(validation.type) && (contactUsForm.get('message').dirty || contactUsForm.get('message').touched)">
                                    {{validation.message}}</small>
                            </span>
                        </div>
                        <!-- <div class="text_blowe mb-3">
                            <span>
                                By submitting this form, you agree to receive telephone calls and text messages at anytime,
                                which include hours outside of business hours(8:00 a.m. PST - 9:00 p.m PST). 
                                This is so that we may reach you as soon as possible in order to consult on your potential case .
                            </span>
                        </div> -->
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label font-weight-bold font-family" for="exampleCheck1">By submitting this form, you agree to receive text messages at anytime, which include hours outside of business hours(8:00 a.m. PST - 9:00 p.m PST). This is so that we may reach you as soon as possible in order to consult on your potential case .</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label font-weight-bold font-family" for="exampleCheck1">By submitting this form, you agree to receive telephone calls at anytime, which include hours outside of business hours(8:00 a.m. PST - 9:00 p.m PST). This is so that we may reach you as soon as possible in order to consult on your potential case .</label>
                        </div>
                        <div class="form-check mb-2">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label Font-Family font-weight-bold" for="exampleCheck1">By pressing send button you are authorizing Lead Management team to contact you by SMS/TEXT.</label>
                          </div>

                        <div>
                            <re-captcha required (resolved)="resolved($event)" [siteKey]="siteKey">
                            </re-captcha>
                        </div>
                        <div><a class="Font-Family"[routerLink]="['/privacy']" href="https://flyallover.com/privacy">Privacy and Policies</a></div>
                        <div class="submit">
                            <button [ngClass]="contactUsForm.valid && captchaKey ? 'button active' :'button disabled'"
                                type="submit" [disabled]="!contactUsForm.valid && !captchaKey">SEND <i
                                    *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> </button>
                            <!-- <i *ngIf="roundSpinner" class="fas fa-spinner fa-spin"></i> -->
                        </div>
                    </div>
                </form>
                <div class="inside text-center" *ngIf="formSubmitted">
                    <h6 class="font-size">Thank you!</h6>
                    <p class="font-size" >Your message has been received and we will be contacting you shortly to follow up</p>
                    <div class="img">
                        <img title="Contact Us " alt="mail"  src="./assets/images/help-center/mail.svg" width="80" />
                    </div>
                    <a [routerLink]="['/']">Go back to homepage <i class="fas fa-angle-right"></i></a>
                </div>
            </div>
        </div>
        <!-- end contact-us -->
      </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
