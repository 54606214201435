                         <app-navbar></app-navbar>
<!-- start header-more-offers -->
<div class="header-blog">
  <div class="overlay">
    <div class="container">
      <h1 class="text-center"></h1>
    </div>
  </div>
</div>

<div class="search-box">
  <input type="text" [(ngModel)]="word" placeholder="Search here..."/>
  <!--        <input type="text" placeholder="Search here..." />-->
  <button>
    <img
      title="Search about trip"
      alt="search"
      src="./assets/images/blog/search.svg"
      width="30"
    />
  </button>
</div>
<div class="container mt-5" data-aos="fade-up">
  <div class="row">
    <div class="section-header text-center">
      <h2 class="pt-3 fao_mobile_title_large" style="color: #fff !important;">Offers</h2>
      <div class="underLine-travel" >

      </div>
    </div>
  </div>
</div>
<!-- end header-more-offers -->

<!-- cards offers Section -->
<section id="offers" class="section-with-bg mt-5">
  <div class="container">
    <div class="row mainRow headline mt-5">
      <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let item of data| offer : word; let i = index ">
        <div class="offer">
          <div class="offer-img my-auto">
            <img
              src="{{ item.images[0].full_url }}"
              alt="{{item.name}}"
              class=" rounded w-100"

              width="100%"
            />
            <div class=" py-1">
              <h3 class="fao_mobile_title_meduim">
                {{ item.name }}
              </h3>
              <p class="text-center fw-bold">
                <span class="To-color pt-1 DataFont">{{ item.departure_date | date: "MM/dd/yyyy" }} </span>
                <span class=" px-2 bold text-dark DataFont">To</span>
                <span class="To-color pt-1 DataFont">{{ item.return_date | date: "MM/dd/yyyy" }}
                </span>
              </p>
            </div>
          </div>
          <div
            style="
              background-color: #ddd4d4;
              height: 1px;
              width: 95%;
              margin: auto;
            "
          ></div>
          <div>
            <p class="fao_mobile_prgraph">
              {{ item.offer_description |slice:0:100}}
            </p>
            <div class="line-height px-4 ">
              <h4 class="price end_div Font-family">{{item.price | currency}}</h4>
              <span class=" text-muted end_div Font-family">Round Trip</span>
            </div>
            <div class=" center_div">
              <button class="fao_des_btn_med my-4" [routerLink]="['/offers/' ,item.slug]">
                MORE DETAILS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------------------------------------------------  pagination ------------------------------------------------------ -->
    <ngb-pagination class="d-flex justify-content-center" [collectionSize]="collectionSize" [pageSize]="pageSize"
                    (pageChange)="changePage(page)" [(page)]="page" >
                    <ng-template ngbPaginationPrevious>Prev</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
    <!--<ngb-pagination class="d-flex justify-content-center my-5 py-5" [collectionSize]="collectionSize"
                    (pageChange)="getpaginationblogs(page)" [(page)]="page" aria-label="Custom pagination">
    </ngb-pagination>-->
  </div>
</section>
<app-footer></app-footer>
