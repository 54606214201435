import {ModuleWithProviders, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatNativeDateModule} from '@angular/material/core';
import {GoogleMapsModule} from '@angular/google-maps';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
/************************************************************************************************************/
import {Ng5SliderModule} from 'ng5-slider'; // npm install --save ng5-slider
import {NgxChatboxModule} from 'ngx-chatbox'; // npm i ngx-chatbox
import {TransferHttpCacheModule} from '@nguniversal/common';
// import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {NgbAlertModule, NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FileInputAccessorModule} from 'file-input-accessor';
import {CookieService} from 'ngx-cookie-service';
import {GalleryModule} from 'ng-gallery';
import {LightboxModule} from 'ng-gallery/lightbox';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HelpCenterServices} from './services/help-center.service';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {RecaptchaModule} from 'ng-recaptcha';
import {ReversePipe} from './pipes/reverse.pipe';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import { QRCodeModule } from 'angularx-qrcode';

/************************************************************************************************************/
import {HomeComponent} from './pages/home/home.component';
import {DisableDirective} from './pages/disable.directive';
import {FlightsResultsComponent} from './pages/flights-results/flights-results.component';
import {NoResultsComponent} from './pages/no-results/no-results.component';
import {ConfirmationComponent} from './pages/confirmation/confirmation.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {AuthorizeComponent} from './pages/authorize/authorize.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {AboutComponent} from './pages/about/about.component';
import {OffersComponent} from './pages/offers/offers.component';
import {OfferComponent} from './pages/offer/offer.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {HotelsComponent} from './pages/hotels/hotels.component';
import {HotelsResultsComponent} from './pages/hotels-results/hotels-results.component';
import {HotelConfirmationComponent} from './pages/hotel-confirmation/hotel-confirmation.component';
import {HelpCenterComponent} from './pages/help-center/help-center.component';
import {HelpSearchResultsComponent} from './pages/help-search-results/help-search-results.component';
import {HelpBookingsComponent} from './pages/help-bookings/help-bookings.component';
import {HelpContactUsComponent} from './pages/help-contact-us/help-contact-us.component';
import {HelpTermsComponent} from './pages/help-terms/help-terms.component';
import {HelpPrivacyComponent} from './pages/help-privacy/help-privacy.component';
import {HelpFaqComponent} from './pages/help-faq/help-faq.component';
import {HelpNewsletterComponent} from './pages/help-newsletter/help-newsletter.component';
import {HelpDisclaimerComponent} from './pages/help-disclaimer/help-disclaimer.component';
import {HelpSignUpComponent} from './pages/help-sign-up/help-sign-up.component';
import {HelpLoginComponent} from './pages/help-login/help-login.component';
import {CovidTestingLocationsComponent} from './pages/covid-testing-locations/covid-testing-locations.component';
import {CovidStateComponent} from './pages/covid-state/covid-state.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {SignUpComponent} from './pages/sign-up/sign-up.component';
import {BlogComponent} from './pages/blog/blog.component';
import {ThingsTodoComponent} from './pages/things-todo/things-todo.component';
import {ExploreComponent} from './pages/explore/explore.component';
import {ExploreDetailsComponent} from './pages/explore-details/explore-details.component';
import {NoResultComponent} from './pages/no-result/no-result.component';
import {ViewItineraryComponent} from './pages/emails/view-itinerary/view-itinerary.component';
import {BirthdayEmailComponent} from './pages/emails/birthday-email/birthday-email.component';
import {WelcomeComponent} from './pages/emails/welcome/welcome.component';
import {LoginComponent} from './pages/login/login.component';
import {ForgetPasswordComponent} from './pages/forget-password/forget-password.component';
import {TopicComponent} from './pages/topic/topic.component';
import {CentreComponent} from './pages/centre/centre.component';
import {CentreArticleComponent} from './pages/centre-article/centre-article.component';
import {OfferFlightComponent} from './pages/offer-flight/offer-flight.component';
import {CovidCountrySearchComponent} from './pages/covid-country-search/covid-country-search.component';
import {HealthEntryComponent} from './pages/health-entry/health-entry.component';
import {SelfServiceComponent} from './pages/self-service/self-service.component';
import {VisaComponent} from './pages/visa/visa.component';
import {ReviewFlightComponent} from './pages/review-flight/review-flight.component';
/************************************************************************************************************/
import {FooterComponent} from './components/footer/footer.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {AlternativeDatesComponent} from './components/alternative-dates/alternative-dates.component';
import {PassengersNumberComponent} from './components/passengers-number/passengers-number.component';
import {SearchComponent} from './components/search/search.component';
import {PrintErrorComponent} from './components/print-error/print-error.component';
import {MyProfileComponent} from './components/my-profile/my-profile.component';
import {MyBookingsComponent} from './components/my-bookings/my-bookings.component';
import {CreateAccountComponent} from './components/create-account/create-account.component';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {MyTicketDashBoardComponent} from './components/dashboard/my-ticket-dash-board/my-ticket-dash-board.component';
import {UserDocsComponent} from './components/user-docs/user-docs.component';
import {IndexComponent} from './components/index/index.component';
import {ItineraryComponent} from './components/itinerary/itinerary.component';
import {HotelSearchComponent} from './components/hotel-search/hotel-search.component';
import {HotelPassengersNumberComponent} from './components/hotel-passengers-number/hotel-passengers-number.component';
import {OfferCardComponent} from './components/offer-card/offer-card.component';
import {SharePopupComponent} from './components/share-popup/share-popup.component';
import {NoResultErrorComponent} from './components/no-result-error/no-result-error.component';
import {MultipleSearchEnginesComponent} from './components/multiple-search-engines/multiple-search-engines.component';
import {ArticleCardComponent} from './components/article-card/article-card.component';
import {CovidPopupComponent} from './components/covid-popup/covid-popup.component';
import {TimerPopupComponent} from './components/timer-popup/timer-popup.component';
import {FlightPageComponent} from './components/flight-page/flight-page.component';
import {HotelPageComponent} from './components/hotel-page/hotel-page.component';
import {FeaturedHotelsComponent} from './components/featured-hotels/featured-hotels.component';
import {TrendingHotelComponent} from './components/trending-hotel/trending-hotel.component';
import {VisaEngineComponent} from './components/visa/visa-engine/visa-engine.component';
import {VisaFeaturesComponent} from './components/visa/visa-features/visa-features.component';
import {VisaFaqsComponent} from './components/visa/visa-faqs/visa-faqs.component';
import {FlightDetailsHeaderComponent} from './components/flight-details-header/flight-details-header.component';
import {
    FlightDetailsSingleFlightComponent
} from './components/flight-details-single-flight/flight-details-single-flight.component';
import {FareRulesComponent} from './components/fare-rules/fare-rules.component';
import {EngineComponent} from './components/home/engine/engine.component';
import {
    TravellersClassesInputComponent
} from './components/home/travellers-classes-input/travellers-classes-input.component';
import {ReturnInputComponent} from './components/home/return-input/return-input.component';
import {DepartInputComponent} from './components/home/depart-input/depart-input.component';
import {FromInputComponent} from './components/home/from-input/from-input.component';
import {RoundTripFormComponent} from './components/home/round-trip-form/round-trip-form.component';
import {
    RoundTripCalenderDesktopDialogComponent
} from './components/home/round-trip-calender-desktop-dialog/round-trip-calender-desktop-dialog.component';
import {ToInputComponent} from './components/home/to-input/to-input.component';
import {
    AirlinePreferenceInputComponent
} from './components/home/airline-preference-input/airline-preference-input.component';
import {DirectFlightInputComponent} from './components/home/direct-flight-input/direct-flight-input.component';
import {
    TravellersClassesDesktopDialogComponent
} from './components/home/travellers-classes-desktop-dialog/travellers-classes-desktop-dialog.component';
import {OneWayTripFormComponent} from './components/home/one-way-trip-form/one-way-trip-form.component';
import {HotelsFormComponent} from './components/home/hotels-form/hotels-form.component';
import {MultiCityFormComponent} from './components/home/multi-city-form/multi-city-form.component';
import {
    OneWayCalenderDesktopDialogComponent
} from './components/home/one-way-calender-desktop-dialog/one-way-calender-desktop-dialog.component';
import {FiltersComponent} from './components/filters/filters.component';
import {FlightResultsVisaComponent} from './components/flight-results-visa/flight-results-visa.component';
import {FlightResultsSearchComponent} from './components/flight-results-search/flight-results-search.component';
import {FlightResultsSortsComponent} from './components/flight-results-sorts/flight-results-sorts.component';
import {CheckInInputComponent} from './components/home/engine/check-in-input/check-in-input.component';
import {CheckOutInputComponent} from './components/home/engine/check-out-input/check-out-input.component';
import {
    TravellersHotelsDesktopDialogComponent
} from './components/home/travellers-hotels-desktop-dialog/travellers-hotels-desktop-dialog.component';
import {FlightDetailsDropDownComponent} from './components/flight-details-drop-down/flight-details-drop-down.component';
import {ReviewFlightSeatsComponent} from './components/review-flight/review-flight-seats/review-flight-seats.component';
import {
    ReviewFlightOneFlightSeatsComponent
} from './components/review-flight/review-flight-one-flight-seats/review-flight-one-flight-seats.component';
import {
    ReviewFlightSkeletonComponent
} from './components/review-flight/review-flight-skeleton/review-flight-skeleton.component';
import {
    ReviewFlightSeatsHeaderComponent
} from './components/review-flight/review-flight-seats-header/review-flight-seats-header.component';
import {
    ReviewFlightSeatsNavigationButtonsComponent
} from './components/review-flight/review-flight-seats-navigation-buttons/review-flight-seats-navigation-buttons.component';
import {
    ReviewFlightRightSectionComponent
} from './components/review-flight/review-flight-right-section/review-flight-right-section.component';
import {
    ReviewFlightTripProtectionComponent
} from './components/review-flight/review-flight-trip-protection/review-flight-trip-protection.component';
import {
    ReviewFlightConfirmSectionComponent
} from './components/review-flight/review-flight-confirm-section/review-flight-confirm-section.component';
import {
    ReviewFlightNavigationComponent
} from './components/review-flight/review-flight-navigation/review-flight-navigation.component';
import {
    ReviewFlightItineraryDetailsComponent
} from './components/review-flight/review-flight-itinerary-details/review-flight-itinerary-details.component';
import {
    ReviewFlightCreditCardComponent
} from './components/review-flight/review-flight-credit-card/review-flight-credit-card.component';
import {
    ReviewFlightAddTravellerComponent
} from './components/review-flight/review-flight-add-traveller/review-flight-add-traveller.component';
import {
    ReviewFlightSelectTravellersComponent
} from './components/review-flight/review-flight-select-travellers/review-flight-select-travellers.component';
import {
    ReviewFlightTimerReminderDialogComponent
} from './components/review-flight/review-flight-timer-reminder-dialog/review-flight-timer-reminder-dialog.component';
import {
    ReviewFlightAddTravellerFormComponent
} from './components/review-flight/review-flight-add-traveller-form/review-flight-add-traveller-form.component';
import {
    ReviewFlightEndTimerReminderDialogComponent
} from './components/review-flight/review-flight-end-timer-reminder-dialog/review-flight-end-timer-reminder-dialog.component';
import {
    ReviewFlightThanksComponent
} from './components/review-flight/review-flight-thanks/review-flight-thanks.component';
import {
    ReviewFlightFamilyMembersComponent
} from './components/review-flight/review-flight-family-members/review-flight-family-members.component';
import {
    ReviewFlightTripInfoComponent
} from './components/review-flight/review-flight-trip-info/review-flight-trip-info.component';
import {
    ReviewFlightReviewBookComponent
} from './components/review-flight/review-flight-review-book/review-flight-review-book.component';
import {
    ReviewFlightPaymentComponent
} from './components/review-flight/review-flight-payment/review-flight-payment.component';
import {
    ReviewFlightSecureSectionComponent
} from './components/review-flight/review-flight-secure-section/review-flight-secure-section.component';
import {AccountInfoComponent} from './pages/dashboard/account-info/account-info.component';
import {DashSidebarComponent} from './components/dashboard/dash-sidebar/dash-sidebar.component';
import {FeedbackDashboardComponent} from './pages/dashboard/feedback-dashboard/feedback-dashboard.component';
import {VisaDataComponent} from './components/visa-data/visa-data.component';
import {BookingDashboardComponent} from './pages/dashboard/booking-dashboard/booking-dashboard.component';
import {TravelDetailsComponent} from './pages/dashboard/travel-details/travel-details.component';
import {DashboardComponent} from './pages/dashboard/dashboard/dashboard.component';
import {
    ReviewTripTravellersDataComponent
} from './components/review-flight/review-trip-travellers-data/review-trip-travellers-data.component';
import {FamilyMembersComponent} from './components/dashboard/family-members/family-members.component';
import {AddMemberComponent} from './components/dashboard/add-family-member/add-member.component';
import {EditMemberComponent} from './components/dashboard/edit-family-member/edit-member.component';
import {AddPassportComponent} from './components/dashboard/add-member-passport/add-passport.component';
import {EditPassportComponent} from './components/dashboard/edit-member-passport/edit-passport.component';
import {TopDealsComponent} from './components/home/top-deals/top-deals.component';
import {DeleteMemberComponent} from './components/dashboard/delete-member/delete-member.component';
import {SignOutComponent} from './components/dashboard/sign-out/sign-out.component';
import {
    HomeSpecificSearchEngineComponent
} from './components/home/home-specific-search-engine/home-specific-search-engine.component';
import {OwnerComponent} from './components/owner/owner.component';
import {HomeVideosCitiesComponent} from './components/home/home-videos-cities/home-videos-cities.component';
import {HomeAboutSectionComponent} from './components/home/home-about-section/home-about-section.component';
import {MatSliderModule} from '@angular/material/slider';
import {HomeBrazilianBannerComponent} from './components/home/home-brazilian-banner/home-brazilian-banner.component';
import {OurPartnerBussinessComponent} from './components/home/our-partner-bussiness/our-partner-bussiness.component';
import {TimeSliderComponent} from './components/filters/time-slider/time-slider.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {TestComponent} from './components/test/test.component';
import {ThanksModalComponent} from './components/review-flight/thanks-modal/thanks-modal.component';
import {PnrInDashboardComponent} from './components/review-flight/pnr-in-dashboard/pnr-in-dashboard.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {QatarOfferComponent} from './components/home/qatar-offer/qatar-offer.component';
import {ChatComponent} from './pages/chat/chat.component';
import {
    HotelDestinationInputComponent
} from './components/home/hotel-destination-input/hotel-destination-input.component';
import {HomeVideoMainSectionComponent} from './components/home-video-main-section/home-video-main-section.component';
import {BaggageFeesComponent} from './pages/baggage-fees/baggage-fees.component';
import {
    LoadingFlightResultsDialogComponent
} from './components/loading-flight-results-dialog/loading-flight-results-dialog.component';
import {PopularDestComponent} from './components/popular-dest/popular-dest.component';
import {ThingsToDoComponent} from './components/things-to-do/things-to-do.component';
import {ThingsToDoPageComponent} from './pages/things-to-do-page/things-to-do-page.component';
import {HomeAirlinesLogosComponent} from './components/home/home-airlines-logos/home-airlines-logos.component';
import {AllPopularDestComponent} from './components/all-popular-dest/all-popular-dest.component';
import {PopularDestDescriptionComponent} from './pages/popular-dest-description/popular-dest-description.component';
import {AgmCoreModule} from '@agm/core';
import {AirlineCardsComponent} from './components/home/airline-cards/airline-cards.component';
import {WhyAboutUsComponent} from './components/why-about-us/why-about-us.component';
import {MorePopularDestinationsComponent} from './pages/more-popular-destinations/more-popular-destinations.component';
import {ImportantLinksComponent} from './components/important-links/important-links.component';
import {NewsLetterComponent} from './components/news-letter/news-letter.component';
import {ReadMoreModule} from 'ng-readmore';
import {FromInputStaticComponent} from './components/from-input-static/from-input-static.component';
import {ToInputStaticComponent} from './components/to-input-static/to-input-static.component';
import {SpinnerLoaderComponent} from './components/spinner-loader/spinner-loader.component';
import {EngineSearchCardsComponent} from './components/engine-search-cards/engine-search-cards.component';
import {HomeHotelsComponent} from './pages/home-hotels/home-hotels.component';
import {CityDetailsComponent} from './pages/city-details/city-details.component';
import {PageOffersComponent} from './pages/page-offers/page-offers.component';
import {MoreOffersComponent} from './pages/more-offers/more-offers.component';
import {HomeSliderOffersComponent} from './components/home-slider-offers/home-slider-offers.component';
import {NewBlogComponent} from './pages/new-blog/new-blog.component';
import {EnginePointComponent} from './components/engine-point/engine-point.component';
import {PointSliderOffersComponent} from './components/point-slider-offers/point-slider-offers.component';
import {BlogDescriptionComponent} from './pages/blog-description/blog-description.component';
import {AirportDescriptionComponent} from './pages/airport-description/airport-description.component';
import {AutosizeModule} from 'ngx-autosize';
import {SearchPipe} from './pipes/search.pipe';
import {OfferService} from './pipes/offer.service';
import {PopularDestSearchService} from './pipes/popularDestSearch.service';
import {ThingsToDoSearchPipe} from './pipes/things-to-do-search.pipe';
import {TravelComponent} from './pages/travel/travel.component';
import {
    AirlineBaggingInformationComponent
} from './pages/airline-bagging-information/airline-bagging-information.component';
import {AirlineOnlineCheckComponent} from './pages/airline-online-check/airline-online-check.component';
import {AirlineFAQComponent} from './pages/airline-faq/airline-faq.component';
import {AirlineDescriptionComponent} from './pages/airline-description/airline-description.component';
import {MatExpansionModule} from '@angular/material/expansion';
// import {MatIconModule} from '@angular/material/icon';
import {MatIconModule} from '@angular/material/icon';

import {
    ReviewFlightFlightPriceComponent
} from './components/review-flight/review-flight-flight-price/review-flight-flight-price.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {ThanksDialogComponent} from './pages/review-flight/thanks-dialog/thanks-dialog.component';
import {CheckRequirmentComponent} from './components/check-requirment/check-requirment.component';
import {FlightsAirlinesLogosComponent} from './components/flights-airlines-logos/flights-airlines-logos.component';
import {
    FlightResultsSearchDataComponent
} from './components/flight-results-search-data/flight-results-search-data.component';
import {FlightsSliderOffersComponent} from './components/flights-slider-offers/flights-slider-offers.component';
import {FlightEngineComponent} from './components/home/flight-engine/flight-engine.component';
import {ToastrModule} from 'ngx-toastr';
import {ShareReviewsComponent} from './pages/share-reviews/share-reviews.component';
import {ViewReviewDetailsComponent} from './pages/view-review-details/view-review-details.component';
import {EgyptairSearchComponent} from './pages/egyptair-search/egyptair-search.component';
import {OwlModule} from 'ngx-owl-carousel';
import {
    AppReviewFlightOfferAddTravellerFormComponent
} from './components/review-flight/app-review-flight-offer-add-traveller-form/app-review-flight-offer-add-traveller-form.component';
import {
    ReviewFlightOffersFamilyMembersComponent
} from './components/review-flight/review-flight-offers-family-members/review-flight-offers-family-members.component';
import {
    ReviewFlightAddMemberTravellerComponent
} from './components/review-flight/review-flight-add-member-traveller/review-flight-add-member-traveller.component';
import {DialogDiscountComponent} from './pages/egyptair-search/dialog-discount/dialog-discount.component';
import {AddReviewsComponent} from './components/add-reviews/add-reviews.component';
import {ShowRateComponent} from './components/show-rate/show-rate.component';
import { FooterAboutDialogComponent } from './components/footer-about-dialog/footer-about-dialog.component';
import { FlightDetailsDashboardComponent } from './components/flight-details-dashboard/flight-details-dashboard.component';
import { VacationsHotelsComponent } from './pages/vacations-hotels/vacations-hotels.component';
import { VacationsDetailsComponent } from './pages/vacations-details/vacations-details.component';
import { VacationsResultComponent } from './pages/vacations-result/vacations-result.component';
import { HotelSliderComponent } from './components/hotel-slider/hotel-slider.component';
import { HotelsEngineComponent } from './components/home/hotels-engine/hotels-engine.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
// import { MDBBootstrapModule } from 'angular-bootstrap-md';
// import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { HotelRoomComponent } from './components/hotel-room/hotel-room.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { RoomDetailsComponent } from './pages/room-details/room-details.component';
// @ts-ignore
import { VacationHotelDetailsComponent } from './components/vacation-hotel-details/vacation-hotel-details.component';
import { ShowVideoComponent } from './components/show-video/show-video.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';



import { FormVacationHotelPaymentComponent } from './components/form-vacation-hotel-payment/form-vacation-hotel-payment.component';
import { NewHotelsComponent } from './pages/new-hotels/new-hotels.component';
// import { NewHotlesComponent } from './pages/new-hotles/new-hotles.component';
import { FormVacationHotelTravellerDataComponent } from './components/form-vacation-hotel-traveller-data/form-vacation-hotel-traveller-data.component';
import { PayLaterComponent } from './pages/pay-later/pay-later.component';
import { VillasComponent } from './components/villas/villas.component';
import { AllVillasComponent } from './pages/all-villas/all-villas.component';
import { VillasDetailsComponent } from './pages/villas-details/villas-details.component';
import { HotelsFilterComponent } from './components/hotels-filter/hotels-filter.component';
// @ts-ignore
// import { NgxNouiSliderModule } from 'ngx-noui-slider';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxFontAwesomeModule } from 'ngx-font-awesome';
import { FlyHotelsSearchComponent } from './components/fly-hotels-search/fly-hotels-search.component';
import { ItinerarySelectRoundComponent } from './components/itinerary-select-round/itinerary-select-round.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
// import { GoogleMapsModule } from '@angular/google-maps';
// import { NgSelectModule } from '@ng-select/ng-select';
// import { FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ShareFlightDetailsComponent } from './pages/share-flight-details/share-flight-details.component';
import { PaymentItineraryComponent } from './components/payment-itinerary/payment-itinerary.component';
import { NewHelpCenterComponent } from './pages/new-help-center/new-help-center.component';
import {ChangePasswordComponent} from './pages/change-password/change-password.component';
import {NotePayLaterComponent} from './components/review-flight/note-pay-later/note-pay-later.component';
import { ChargebackPolicyComponent } from './pages/chargeback-policy/chargeback-policy.component';
import {PaymentDetailsComponent} from './components/review-flight/payment-details/payment-details.component';
import { CancellationPolicyComponent } from './pages/cancellation-policy/cancellation-policy.component';

import {
  ReviewFlightBaggingInformationComponent
} from './components/review-flight/review-flight-bagging-information/review-flight-bagging-information.component';
import {ReviewFlightFareRulesComponent} from './components/review-flight/review-flight-fare-rules/review-flight-fare-rules.component';
import { AirlineCancellationPolicyComponent } from './pages/airline-cancellation-policy/airline-cancellation-policy.component';
import {LoadingFakeFlightComponent} from './components/review-flight/loading-fake-flight/loading-fake-flight.component';



@NgModule({
    declarations: [
        ShowRateComponent,
        ReviewFlightFamilyMembersComponent,
        ReviewFlightThanksComponent,
        ReviewFlightEndTimerReminderDialogComponent,
        ReviewFlightAddTravellerFormComponent,
        ReviewFlightTimerReminderDialogComponent,
        ReviewFlightSelectTravellersComponent,
        ReviewFlightAddTravellerComponent,
        ReviewFlightCreditCardComponent,
        ReviewFlightSecureSectionComponent,
        ReviewFlightReviewBookComponent,
        ReviewFlightPaymentComponent,
        ReviewFlightItineraryDetailsComponent,
        ReviewFlightNavigationComponent,
        ReviewFlightConfirmSectionComponent,
        ReviewFlightTripProtectionComponent,
        ReviewFlightRightSectionComponent,
        ReviewFlightSeatsNavigationButtonsComponent,
        ReviewFlightSeatsHeaderComponent,
        ReviewFlightSkeletonComponent,
        ReviewFlightOneFlightSeatsComponent,
        ReviewFlightComponent,
        ReviewFlightSeatsComponent,
        ReviewFlightTripInfoComponent,
        ChatComponent,
        QatarOfferComponent,
        OwnerComponent,
        TopDealsComponent,
        ReviewTripTravellersDataComponent,
        TravelDetailsComponent,
        BookingDashboardComponent,
        DashboardComponent,
        VisaDataComponent,
        FeedbackDashboardComponent,
        DashSidebarComponent,
        AccountInfoComponent,
        FlightDetailsDropDownComponent,
        HotelDestinationInputComponent,
        FlightResultsSearchComponent,
        FlightResultsSortsComponent,
        FlightResultsVisaComponent,
        OneWayCalenderDesktopDialogComponent,
        MultiCityFormComponent,
        HotelsFormComponent,
        FiltersComponent,
        FareRulesComponent,
        OneWayTripFormComponent,
        TravellersClassesDesktopDialogComponent,
        DirectFlightInputComponent,
        AirlinePreferenceInputComponent,
        EngineComponent,
        TravellersClassesInputComponent,
        ReturnInputComponent,
        DepartInputComponent,
        ToInputComponent,
        FromInputComponent,
        RoundTripFormComponent,
        RoundTripCalenderDesktopDialogComponent,
        FlightDetailsSingleFlightComponent,
        FlightDetailsHeaderComponent,
        NotFoundComponent,
        DisableDirective,
        AppComponent,
        FooterComponent,
        NavbarComponent,
        HomeComponent,
        VisaComponent,
        VisaEngineComponent,
        VisaFeaturesComponent,
        VisaFaqsComponent,
        HealthEntryComponent,
        PassengersNumberComponent,
        FlightsResultsComponent,
        NoResultsComponent,
        SearchComponent,
        PrintErrorComponent,
        ConfirmationComponent,
        ProfileComponent,
        MyProfileComponent,
        MyBookingsComponent,
        AuthorizeComponent,
        CreateAccountComponent,
        SignInComponent,
        MyTicketDashBoardComponent,
        UserDocsComponent,
        PaymentComponent,
        IndexComponent,
        ItineraryComponent,
        HotelsComponent,
        HotelsResultsComponent,
        HotelSearchComponent,
        AboutComponent,
        OffersComponent,
        OfferComponent,
        HotelPassengersNumberComponent,
        HotelConfirmationComponent,
        HelpCenterComponent,
        HelpSearchResultsComponent,
        HelpBookingsComponent,
        HelpContactUsComponent,
        HelpTermsComponent,
        HelpPrivacyComponent,
        HelpFaqComponent,
        HelpNewsletterComponent,
        HelpDisclaimerComponent,
        HelpSignUpComponent,
        HelpLoginComponent,
        SignUpComponent,
        BlogComponent,
        ThingsTodoComponent,
        ExploreComponent,
        ExploreDetailsComponent,
        NoResultComponent,
        ViewItineraryComponent,
        BirthdayEmailComponent,
        WelcomeComponent,
        LoginComponent,
        ForgetPasswordComponent,
        TopicComponent,
        OfferCardComponent,
        SharePopupComponent,
        CovidTestingLocationsComponent,
        CovidStateComponent,
        ResetPasswordComponent,
        NoResultErrorComponent,
        CentreComponent,
        CentreArticleComponent,
        MultipleSearchEnginesComponent,
        ArticleCardComponent,
        OfferFlightComponent,
        ReversePipe,
        AlternativeDatesComponent,
        CovidPopupComponent,
        CovidCountrySearchComponent,
        TimerPopupComponent,
        FlightPageComponent,
        HotelPageComponent,
        FeaturedHotelsComponent,
        TrendingHotelComponent,
        SelfServiceComponent,
        CheckInInputComponent,
        CheckOutInputComponent,
        TravellersHotelsDesktopDialogComponent,
        DeleteMemberComponent,
        FamilyMembersComponent,
        AddMemberComponent,
        EditMemberComponent,
        AddPassportComponent,
        EditPassportComponent,
        SignOutComponent,
        HomeSpecificSearchEngineComponent,
        HomeVideosCitiesComponent,
        HomeAboutSectionComponent,
        HomeBrazilianBannerComponent,
        OurPartnerBussinessComponent,
        TimeSliderComponent,
        TestComponent,
        ThanksModalComponent,
        HomeVideoMainSectionComponent,
        BaggageFeesComponent,
        LoadingFlightResultsDialogComponent,
        PopularDestComponent,
        ThingsToDoComponent,
        ThingsToDoPageComponent,
        HomeAirlinesLogosComponent,
        AllPopularDestComponent,
        PopularDestDescriptionComponent,
        AirlineCardsComponent,
        ThingsToDoComponent,
        ThingsToDoPageComponent,
        WhyAboutUsComponent,
        MorePopularDestinationsComponent,
        ImportantLinksComponent,
        NewsLetterComponent,
        FromInputStaticComponent,
        ToInputStaticComponent,
        SpinnerLoaderComponent,
        EngineSearchCardsComponent,
        HomeHotelsComponent,
        CityDetailsComponent,
        PageOffersComponent,
        MoreOffersComponent,
        HomeSliderOffersComponent,
        NewBlogComponent,
        EnginePointComponent,
        PointSliderOffersComponent,
        BlogDescriptionComponent,
        AirportDescriptionComponent,
        SearchPipe,
        OfferService,
        PopularDestSearchService,
        ThingsToDoSearchPipe,
        TravelComponent,
        AirlineBaggingInformationComponent,
        AirlineOnlineCheckComponent,
        AirlineFAQComponent,
        AirlineDescriptionComponent,
        ReviewFlightFlightPriceComponent,
        ThanksDialogComponent,
        CheckRequirmentComponent,
        FlightsAirlinesLogosComponent,
        FlightResultsSearchDataComponent,
        FlightsSliderOffersComponent,
        FlightEngineComponent,
        ShareReviewsComponent,
        ViewReviewDetailsComponent,
        EgyptairSearchComponent,
        AppReviewFlightOfferAddTravellerFormComponent,
        ReviewFlightOffersFamilyMembersComponent,
        ReviewFlightAddMemberTravellerComponent,
        DialogDiscountComponent,
        AddReviewsComponent,
        FooterAboutDialogComponent,
        FlightDetailsDashboardComponent,
        VacationsHotelsComponent,
        VacationsDetailsComponent,
        VacationsResultComponent,
        HotelSliderComponent,
        HotelsEngineComponent,
        HotelRoomComponent,
        GoogleMapComponent,
        RoomDetailsComponent,
        VacationHotelDetailsComponent,
        ShowVideoComponent,
        FormVacationHotelPaymentComponent,
        NewHotelsComponent,
        // NewHotlesComponent,
        FormVacationHotelTravellerDataComponent,
        PayLaterComponent,
        VillasComponent,
        AllVillasComponent,
        VillasDetailsComponent,
        HotelsFilterComponent,
        FlyHotelsSearchComponent,
        ItinerarySelectRoundComponent,
        LoadingModalComponent,
        ShareFlightDetailsComponent,
        PaymentItineraryComponent,
        NewHelpCenterComponent,
        ChangePasswordComponent,
        NotePayLaterComponent,
        ChargebackPolicyComponent,
        PaymentDetailsComponent,
        CancellationPolicyComponent,
        ReviewFlightBaggingInformationComponent,
        ReviewFlightFareRulesComponent,
        AirlineCancellationPolicyComponent,
        LoadingFakeFlightComponent,
        PnrInDashboardComponent,

],
  imports: [

    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AgmCoreModule.forRoot({
        apiKey: 'AIzaSyB1KAbQnJIP11YSmWLb2OH8jMLyFpFluf4',
        libraries: ['places']
    }),
    AppRoutingModule, ReadMoreModule,
    MatDialogModule,
    CdkAccordionModule,
    NgbModule,
    // NouisliderModule,
    // NgxNouiSliderModule,
    // NgSelectModule,
    GooglePlaceModule,
    HttpClientModule,
    // NgxIntlTelInputModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatRadioModule,
    FormsModule,
    QRCodeModule,
    NgxSliderModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    CarouselModule,
    FileInputAccessorModule,
    NgxSkeletonLoaderModule,
    Ng5SliderModule,
    GalleryModule,
    LightboxModule,
    GoogleMapsModule,
    SocialLoginModule,
    InfiniteScrollModule,
    RecaptchaModule,
    TransferHttpCacheModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    BsDropdownModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatStepperModule,
    MatTabsModule,
    MatSliderModule,
    Ng2TelInputModule,
    IvyCarouselModule,
    NgxChatboxModule,
    NgbPaginationModule,
    NgbAlertModule,
    AutosizeModule,
    MatExpansionModule,
    MatIconModule,

    // MDBBootstrapModule.forRoot(),
    // MdbAccordionModule,
    AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyB1KAbQnJIP11YSmWLb2OH8jMLyFpFluf4'
      apiKey: 'AIzaSyBgjNW0WA93qphgZW-joXVR6VC3IiYFjfo'

    }),
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ReadMoreModule,
    MatDialogModule,
    NgbModule,
    HttpClientModule,
    // NgxIntlTelInputModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatRadioModule,
    FormsModule,
    QRCodeModule,
    NgxSliderModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    CarouselModule,
    FileInputAccessorModule,
    NgxSkeletonLoaderModule,
    Ng5SliderModule,
    GalleryModule,
    LightboxModule,
    GoogleMapsModule,
    SocialLoginModule,
    InfiniteScrollModule,
    RecaptchaModule,
    TransferHttpCacheModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    BsDropdownModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatStepperModule,
    MatTabsModule,
    MatSliderModule,
    Ng2TelInputModule,
    IvyCarouselModule,
    NgxChatboxModule,
    NgbPaginationModule,
    NgbAlertModule,
    AutosizeModule,
    AccordionModule.forRoot(),


    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBgjNW0WA93qphgZW-joXVR6VC3IiYFjfo'
    // }), MatExpansionModule, MatSidenavModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBgjNW0WA93qphgZW-joXVR6VC3IiYFjfo'
    // }),

    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule, ReadMoreModule,
    MatDialogModule,
    NgbModule,
    HttpClientModule,
    // NgxIntlTelInputModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatRadioModule,
    FormsModule,
    QRCodeModule,
    NgxSliderModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    CarouselModule,
    FileInputAccessorModule,
    NgxSkeletonLoaderModule,
    Ng5SliderModule,
    GalleryModule,
    LightboxModule,
    GoogleMapsModule,
    SocialLoginModule,
    InfiniteScrollModule,
    RecaptchaModule,
    TransferHttpCacheModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    BsDropdownModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatStepperModule,
    MatTabsModule,
    MatSliderModule,
    Ng2TelInputModule,
    IvyCarouselModule,
    NgxChatboxModule,
    NgbPaginationModule,
    NgbAlertModule,
    AutosizeModule,
    MatExpansionModule,
    MatSidenavModule,
    NgxFontAwesomeModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true,
    }),
    OwlModule, CdkAccordionModule, MatDividerModule, MatCardModule, MatToolbarModule,

  ],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '1059598253247-3edhg5bdcr5dkq1ck91gf7a33ccjo4s6.apps.googleusercontent.com'
                        ),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        DatePipe, CookieService, HelpCenterServices
    ],
})
export class AppModule {
    static forRoot(): ModuleWithProviders<AppModule> {
        return {
            ngModule: AppModule
        };
    }
}
