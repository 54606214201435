<app-navbar></app-navbar>
<div class="container section-details">
  <div class="row">
    <div class="col-xl-4 col-xxl-3">
      <app-dash-sidebar></app-dash-sidebar>
    </div>
    <div class="col-xl-8 col-xxl-9">
      <!-- <div class="header-image">
        <h1 class="text-center">Your Previous Bookings</h1>
        <h2 class="text-center" *ngIf="flights == 0">
          We're sorry, but it looks like you haven't booked any flights before,
          hopefully this will change soon.
        </h2>
        <div *ngIf="flights" class="slider">
          <owl-carousel-o [options]="carouselOptions">
            <ng-container *ngFor="let flight of flights">
              <ng-template carouselSlide>
                <div
                  class="box"
                  (click)="details = !details; flightDetails(flight.id)"
                >
                  <div class="right-section">
                    <div class="header">
                      <img
                        src="../../../../assets/images/dashboard/Group%2015507.svg"
                        alt="Group"
                        title="Your Previous Bookings"
                      />
                    </div>
                    <div class="content">
                      <h4>Air Booking :</h4>
                      <p>
                        {{ flight.origin }}- {{ flight.origin_city }}
                        <i class="fas fa-arrow-right"></i>
                        {{ flight.destination }}-{{ flight.destination_city }}
                      </p>
                      <p class="box-date">
                        <img
                          src="../../../../assets/images/dashboard/Path%2053426.svg"
                          alt="path"
                          title="flight departure date "
                        />{{ flight.departure_date | date: "dd-MM-yyyy" }}
                      </p>
                      <p>
                        {{ flight.traveller_num }} Travellers,
                        {{ flight.trip_type }}
                      </p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
      <div class="booking-sec">
        <mat-tab-group *ngIf="details">
          <mat-tab label="Flight Details">
            <div class="itinerary">
              <div class="row flights-summary mx-0 flightDataSection">
                <div class="col-md-12">
                  <div class="col-md-12 custom-mobile-view">
                    <div class="row">
                      <div class="covid_policy" (click)="closedStatus = false">
                        <span
                          ><img
                            src="/assets/images/icons_flights/protection%20(1).svg"
                            title=" COVID-19 policies"
                            alt="protection"
                        /></span>
                        <span>COVID-19 policies </span>
                        <span
                          ><img
                            src="/assets/images/icons_flights/arrow-point-to-right.svg"
                            title="COVID-19 policies"
                            alt="protection"
                            width="20px"
                            height="15px"
                            alt="arrow-point-to-right"
                        /></span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flight_headers mb-3"
                    style="display: flex; justify-content: space-between"
                  >
                    <div style="display: flex; align-items: center">
                      <div
                        style="margin-right: 25px"
                        class="covid_policy"
                        (click)="closedStatus = false"
                      >
                        <span
                          ><img
                            src="/assets/images/icons_flights/protection%20(1).svg"
                            title=" COVID-19 policies"
                            alt="protection"
                        /></span>
                        <span>COVID-19 policies </span>
                        <span
                          ><img
                            src="/assets/images/icons_flights/arrow-point-to-right.svg"
                            width="20px"
                            height="15px"
                            alt="arrow-point-to-right"
                            title=" COVID-19 policies"
                        /></span>
                      </div>
                      <div class="icons_services">
                        <div class="inner_icons">
                          <span
                            ><img
                              src="/assets/images/icons_flights/Path_1262.svg"
                              alt="Path"
                              title="icons_services"
                          /></span>
                          <span
                            ><img
                              src="/assets/images/icons_flights/Group_13924.svg"
                              title="icons_services"
                              alt="Group"
                          /></span>
                          <span
                            ><img
                              src="/assets/images/icons_flights/Group_13936.svg"
                              title="icons_services"
                              alt="Group"
                          /></span>
                          <span
                            ><img
                              src="/assets/images/icons_flights/Icon_material-live-tv.svg"
                              title="icons_services"
                              alt="Icon_material-live-tv"
                          /></span>
                          <span
                            ><img
                              src="/assets/images/icons_flights/Path_42007.svg"
                              title="icons_services"
                              alt="Path"
                          /></span>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: column">
                      <div style="display: flex; align-items: center">
                        <h3
                          class="flightPrice"
                          style="margin: 10px; color: #fd5021"
                        >
                          {{
                            itinerary.BaseFare.Amount + itinerary.taxfees.Amount
                              | currency
                          }}
                        </h3>
                        <span class="color-148181" style="font-size: 19px">{{
                          itinerary.prices.FareClassName
                        }}</span>
                      </div>
                      <div class="d-flex justify-content-end taxesSection">
                        <span>{{ itinerary.BaseFare.Amount | currency }}</span>
                        <span>+</span>
                        <span>{{ itinerary.taxfees.Amount | currency }}</span>
                        <span>(Taxes)</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        padding: 8px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: row;
                      "
                    >
                      <div
                        class="items-container"
                        [ngClass]="{
                          'full-width': flightData.trip_type == 'OneWay'
                        }"
                        *ngFor="
                          let flight of flightData.flight_details;
                          let i = index
                        "
                      >
                        <div
                          style="
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                          "
                        >
                          <div>
                            <span
                              class="color-148181 font-weight-600"
                              *ngIf="i == 0"
                              >Depart Flight</span
                            >
                            <span
                              class="color-148181 font-weight-600"
                              *ngIf="i == 1"
                              >Return Flight</span
                            >
                          </div>
                          <div>
                            <span
                              class="color-007bff"
                              style="margin-right: 5px"
                              >{{ flightData.baggage_info[0].length }}</span
                            >
                            <span class="color-007bff" style="font-size: 14px"
                              >Free Bags</span
                            >
                          </div>
                        </div>
                        <div
                          class="items"
                          style="display: flex; flex-direction: column"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              flex-direction: column;
                            "
                          >
                            <img
                              class="airCompanyLogo"
                              alt="airCompanyLogo"
                              title="Operating Airline Image"
                              src="https://c.fareportal.com/n/common/air/3x/{{
                                flight[0].OperatingAirline
                              }}.png"
                            />
                            <p
                              style="margin-top: 8px; margin-bottom: 0"
                              class="airline-name"
                            >
                              {{ flight[0].OperatingAirlineName }}
                            </p>
                            <div class="handelAirCraft">
                              <span style="font-size: 10px; text-align: left"
                                >Flight: {{ flight[0].FlightNumber }} |
                                Aircraft: {{ flight[0].AircraftName }}</span
                              >
                            </div>
                          </div>
                          <div class="handleRes">
                            <div class="item item-date-time first-date">
                              <span
                                class="airport"
                                style="text-align: right; font-weight: bold"
                                >{{ flightData.origin }}</span
                              >
                              <p class="item-date">
                                {{
                                  flight[0].DepartureDateTime
                                    | date: "dd-MMM-yyyy"
                                }}
                              </p>
                              <p class="time">
                                {{
                                  flight[0].DepartureDateTime | date: "HH:mm"
                                }}
                              </p>
                            </div>
                            <div
                              class="item stops"
                              (click)="hideShowStops($event)"
                            >
                              <span style="font-size: 12px">{{
                                calcTotalFlightTime(flight)
                              }}</span>
                              <span class="stops-line">
                                <i
                                  *ngIf="flight?.length - 1 > 0"
                                  class="fas fa-circle"
                                ></i>
                              </span>
                              <p
                                class="time-stop color_red"
                                *ngIf="flight?.length - 1 > 0"
                              >
                                {{ flight?.length - 1 }} Stop
                              </p>
                            </div>
                            <div class="item item-date-time second-date">
                              <span
                                class="airport second-airport"
                                style="
                                  text-align: left;
                                  display: flex;
                                  font-weight: bold;
                                "
                                >{{
                                  flight[flight.length - 1].ArrivalAirport
                                }}</span
                              >
                              <p class="item-date">
                                {{
                                  flight[flight.length - 1].ArrivalDateTime
                                    | date: "dd-MMM-yyyy"
                                }}
                              </p>
                              <p class="time">
                                {{
                                  flight[flight.length - 1].ArrivalDateTime
                                    | date: "HH:mm"
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="flight?.length - 1 > 0"
                          class="stops-tooltip hide p-2"
                        >
                          <ng-container
                            *ngFor="
                              let trip of flight;
                              let flightI = index;
                              last as isLast
                            "
                          >
                            <div *ngIf="!isLast" class="handleStopsTooltip">
                              <ng-container
                                *ngIf="flight[0] && flight[1] && !flight[2]"
                              >
                                <span class="stops-title">Arriving in</span>
                                <span
                                  >{{ flight[0].ArrivalAirportName }} At
                                  {{
                                    trip.ArrivalDateTime | date: "h:mm a"
                                  }}</span
                                >
                                <span class="layover"
                                  >({{
                                    convertTime(flight[1].FlightLayoverTime)
                                  }}) Layover Time</span
                                >
                                <span class="stops-title">Departing from</span>
                                <span>
                                  {{ flight[1].DepartureAirportName }} At
                                  {{
                                    flight[1].DepartureDateTime | date: "h:mm a"
                                  }}</span
                                >
                              </ng-container>
                              <ng-container *ngIf="flight[1] && flight[2]">
                                <span class="stops-title">Arriving in</span>
                                <span>
                                  {{ flight[1].ArrivalAirportName }} At
                                  {{
                                    trip.ArrivalDateTime | date: "h:mm a"
                                  }}</span
                                >
                                <span class="layover"
                                  >({{
                                    convertTime(flight[2].FlightLayoverTime)
                                  }}) Layover Time</span
                                >
                                <span class="stops-title">Departing from</span>
                                <span
                                  >{{ flight[2].DepartureAirportName }} At
                                  {{
                                    flight[2].DepartureDateTime | date: "h:mm a"
                                  }}</span
                                >
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                        <ng-container *ngIf="flightData.trip_type != 'OneWay'">
                          <div class="directions d-flex flex-column">
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-arrow-left"></i>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-3">
                  <div class="bottom d-flex justify-content-end">
                    <button
                      (click)="toggleFlightDetails()"
                      class="btn flightDetailsBtn"
                    >
                      Flight Details
                    </button>
                  </div>
                </div>
                <div class="flightDetails flightNumber hide">
                  <div class="container">
                    <app-flight-details-drop-down
                      [itinerary]="itinerary"
                    ></app-flight-details-drop-down>
                  </div>
                </div>
                <div class="col-12">
                  <div class="travellers-sec">
                    <div
                      class="d-flex color-181F22 font-size-28-regular justify-content-between mb-16"
                    >
                      <div>Flight PNR :</div>
                      <div class="pnr">{{ flightData.pnr }}</div>
                    </div>
                  </div>
                  <div
                    class="travellers-sec"
                    *ngIf="flightData.family_members != 0"
                  >
                    <div
                      class="d-flex justify-content-center font-size-38-500 color-464646 mb-30"
                    >
                      {{ flightData.family_members.length }}Travellers
                    </div>
                    <div
                      *ngFor="
                        let traveller of flightData.family_members;
                        let i = index
                      "
                    >
                      <div
                        class="d-flex color-777777 font-size-28-regular justify-content-between mb-16"
                      >
                        <div>Traveller {{ i + 1 }} :</div>
                      </div>
                      <div
                        class="d-flex color-181F22 font-size-20 justify-content-between mb-16"
                      >
                        <img
                          class="member-img"
                          title=" family member image"
                          alt="member mage"
                          src="{{ traveller.family_member?.image }}"
                        />
                        <div class="col-6">
                          {{
                            traveller.family_member?.first_name +
                              " " +
                              traveller.family_member?.middle_name +
                              " " +
                              traveller.family_member?.last_name
                          }}
                        </div>
                        <div class="col-2">
                          {{ traveller.family_member?.family_relation.name }}
                        </div>
                        <div class="col-2">
                          {{ traveller.family_member?.passport.passport_number }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="total-price text-center font-size-38-500 mb-38 mt-39"
                  >
                    <span class="font-size-38-500">Total Charge(usd)</span>
                    <span class="color-FE5448">{{
                      itinerary.BaseFare.Amount + itinerary.taxfees.Amount
                        | currency
                    }}</span>
                  </div>
                  <div class="col-w-100">
                    <hr />
                  </div>
                  <div class="font-size-24-500 mb-39 mt-39 text-center">
                    <h2>
                      You will pay in the local currency of your country 4536 eg
                    </h2>
                  </div>
                  <div class="col-w-100">
                    <hr />
                  </div>
                  <div class="d-flex flex-column price-details">
                    <div class="d-flex justify-content-between mb-40">
                      <span class="font-size-28-medium color-000000"
                        >Total Price Details:</span
                      >
                      <span class="color-29ABE0 font-size-28-medium">{{
                        itinerary.BaseFare.Amount + itinerary.taxfees.Amount
                          | currency
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-40">
                      <span class="font-size-20 font-weight-500"
                        >Fare Price :</span
                      >
                      <span class="font-size-20 font-weight-500">{{
                        itinerary.BaseFare.Amount | currency
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-40">
                      <span class="font-size-20 font-weight-500"
                        >Tax (14%) is not included</span
                      >
                      <span class="font-size-20 font-weight-500">{{
                        itinerary.taxfees.Amount | currency
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Fare Rules">
            <div class="">
              <div class="fare-rules">
                <div class="col-12">
                  <div class="d-flex flex-column">
                    <app-fare-rules [itinerary]="itinerary"></app-fare-rules>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="COVID-19 Policies">
            <div class="covid-container">
              <div class="container">
                <div class="row">
                  <div class="headr_covid col-12 text-center mt-4">
                    <h2>Airline Reassurance Policies</h2>
                  </div>
                </div>
                <div class="row d-flex flex-column">
                  <div class="col-md-12">
                    <hr class="d-block" />
                    <div class="header_text d-flex">
                      <h5 class="d-flex align-baseline mr-2">
                        <P class="green_color mr-1 p-7"
                          >{{ flightData.origin }}:</P
                        >
                        <span class="green_text">{{
                          itinerary.flights[0][0].AircraftName
                        }}</span>
                      </h5>
                      <h5 class="d-flex align-baseline">
                        <P class="gray_color mr-1 p-7"
                          >{{ flightData.destination }}:</P
                        >
                        <span class="green_color p-7 color-009fe2">{{
                          itinerary.flights[itinerary.flights.length - 1][0]
                            .AircraftName
                        }}</span>
                      </h5>
                    </div>
                    <hr class="d-block mt-1" />
                  </div>
                </div>
                <div class="row covid_items">
                  <div class="col-md-6">
                    <div class="covid_img">
                      <span
                        ><img
                          src="../../assets/images/icons/covid-icon/covid.svg"
                          title="Enhanced aircraft cleaning nightly"
                          alt="covid"
                      /></span>
                      <h5>
                        <h6>Enhanced aircraft cleaning nightly</h6>
                        <h5>
                          This flight features that will be thoroughly cleaned
                          <br />
                          using disinfectants on a nightly basis.
                        </h5>
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="covid_img">
                      <span
                        ><img
                          src="../../assets/images/icons/covid-icon/mask.svg"
                          title="Face covering"
                          alt="mask"
                      /></span>
                      <h5>
                        <h6>Face covering required</h6>
                        <h5>
                          All passengers are required to wear a face covering
                          throughout <br />
                          their journey
                        </h5>
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="covid_img">
                      <span
                        ><img
                          src="../../assets/images/icons/covid-icon/temp.svg"
                          title="temperature check "
                          alt="temperature"
                      /></span>
                      <h5>
                        <h6>No pre-flight temperature check</h6>
                        <h5>
                          A temperature check is not required before boarding
                        </h5>
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="covid_img">
                      <span
                        ><img
                          src="../../assets/images/icons/covid-icon/spacing.svg"
                          alt="spacing"
                          title="Adjacent seats"
                      /></span>
                      <h5>
                        <h6>No blocked adjacent seat</h6>
                        <h5>Adjacent seats are not blocked on this flight</h5>
                      </h5>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="covid_img">
                      <span
                        ><img
                          src="../../assets/images/icons/covid-icon/menu.svg"
                          alt="menu"
                          title="Standard passenger capacity"
                      /></span>
                      <h5>
                        <h6>Standard passenger capacity</h6>
                        <h5>Ticket sales are not limited for this flight</h5>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab
            label="Health Requirements To Enter {{
              flightData.destination_city
            }}"
          >
            <div class="body" *ngIf="isHealthRequirementsOpened">
              <div class="row p-1">
                <div class="col-md-12">
                  <div class="requirement">
                    <h3 class="requirementTitle">Entry Restrictions</h3>
                    <p
                      [innerHTML]="
                        visaData?.HealthEntryData?.entry_restrictions
                          | slice: 0:200
                      "
                    ></p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="requirement">
                    <h3 class="requirementTitle">
                      Entry & Health Requirements
                    </h3>
                    <p
                      *ngIf="readMoreEntryHealthRequirements"
                      [innerHTML]="
                        visaData?.HealthEntryData?.entry_health_requirements
                          | slice: 0:200
                      "
                    ></p>
                    <p
                      *ngIf="!readMoreEntryHealthRequirements"
                      [innerHTML]="
                        visaData?.HealthEntryData?.entry_health_requirements
                      "
                    ></p>
                    <a
                      href="javascript:void(0)"
                      *ngIf="
                        visaData?.HealthEntryData?.entry_health_requirements
                          ?.length > 200 && readMoreEntryHealthRequirements
                      "
                      class="readMore"
                      (click)="
                        readMoreEntryHealthRequirements =
                          !readMoreEntryHealthRequirements
                      "
                      >Read More</a
                    >
                    <a
                      href="javascript:void(0)"
                      *ngIf="!readMoreEntryHealthRequirements"
                      (click)="
                        readMoreEntryHealthRequirements =
                          !readMoreEntryHealthRequirements
                      "
                      class="readMore"
                      >Read Less</a
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="requirement">
                    <h3 class="requirementTitle">In-country Experience</h3>
                    <p
                      *ngIf="readMoreCountryExperience"
                      [innerHTML]="
                        visaData?.HealthEntryData?.country_experience
                          | slice: 0:200
                      "
                    ></p>
                    <p
                      *ngIf="!readMoreCountryExperience"
                      [innerHTML]="
                        visaData?.HealthEntryData?.country_experience
                      "
                    ></p>
                    <a
                      href="javascript:void(0)"
                      *ngIf="
                        visaData?.HealthEntryData?.country_experience?.length >
                          200 && readMoreCountryExperience
                      "
                      class="readMore"
                      (click)="
                        readMoreCountryExperience = !readMoreCountryExperience
                      "
                      >Read More</a
                    >
                    <a
                      href="javascript:void(0)"
                      *ngIf="!readMoreCountryExperience"
                      (click)="
                        readMoreCountryExperience = !readMoreCountryExperience
                      "
                      class="readMore"
                      >Read Less</a
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="requirement">
                    <h3 class="requirementTitle">Transit Restrictions</h3>
                    <p
                      *ngIf="readMoreTransitRestrictions"
                      [innerHTML]="
                        visaData?.HealthEntryData?.transit_restrictions
                          | slice: 0:200
                      "
                    ></p>
                    <p
                      *ngIf="!readMoreTransitRestrictions"
                      [innerHTML]="
                        visaData?.HealthEntryData?.transit_restrictions
                      "
                    ></p>
                    <a
                      href="javascript:void(0)"
                      *ngIf="
                        visaData?.HealthEntryData?.transit_restrictions
                          ?.length > 200 && readMoreTransitRestrictions
                      "
                      class="readMore"
                      (click)="
                        readMoreTransitRestrictions =
                          !readMoreTransitRestrictions
                      "
                      >Read More</a
                    >

                    <a
                      href="javascript:void(0)"
                      *ngIf="!readMoreTransitRestrictions"
                      (click)="
                        readMoreTransitRestrictions =
                          !readMoreTransitRestrictions
                      "
                      class="readMore"
                      >Read Less</a
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="requirement">
                    <h3 class="requirementTitle">Re-Entry Requirements</h3>
                    <p
                      *ngIf="readMoreEntryRequirements"
                      [innerHTML]="
                        visaData?.HealthEntryData?.re_entry_requirements
                          | slice: 0:200
                      "
                    ></p>
                    <p
                      *ngIf="!readMoreEntryRequirements"
                      [innerHTML]="
                        visaData?.HealthEntryData?.re_entry_requirements
                      "
                    ></p>
                    <a
                      href="javascript:void(0)"
                      *ngIf="
                        visaData?.HealthEntryData?.re_entry_requirements
                          ?.length > 200 && readMoreEntryRequirements
                      "
                      class="readMore"
                      (click)="
                        readMoreEntryRequirements = !readMoreEntryRequirements
                      "
                      >Read More</a
                    >
                    <a
                      href="javascript:void(0)"
                      *ngIf="!readMoreEntryRequirements"
                      (click)="
                        readMoreEntryRequirements = !readMoreEntryRequirements
                      "
                      class="readMore"
                      >Read Less</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group> -->
        <div class="row mainRow headline" style="margin-right: -10vw; margin-top: 6.5vh" *ngIf="flights" >
          <div style="padding-bottom: 17px"  class="col-4 position-relative" *ngFor="let item of flights"
           (click)="click(item.id)"    (click)="details = !details; flightDetails(item.id)"  >
              <div class="booking-confirmed" id={{item.id}}>
                  <div class="booking-top">
                      <!-- *ngIf="itinerary" -->
                      <div class="transport"   >
                          <span>{{item.origin}}</span>
                          <div class="line">
                            <span class="airplane">&#9992;</span>
                          </div>
                          <span>{{item.destination}}</span>
                      </div>
                      <!-- *ngIf="itinerary" -->
                      <div class="content" >
                          <div>
                              <span>
                                  <strong>flight no.</strong>
                                  <p>{{item.id}}</p>
                              </span>
                              <span>
                                  <strong>class</strong>
                                  <p>{{item.fare_class}}</p>
                              </span>
                              <span>
                                  <strong>terminal</strong>
                                  <p *ngIf="item.trip_type.toLowerCase() == 'return'">Round trip</p>
                                  <p *ngIf="item.trip_type.toLowerCase() != 'return'">{{item.trip_type}}</p>
                              </span>
                          </div>
                          <div>
                              <span>
                                  <strong>date</strong>
                                  <p>{{item.departure_date | date:'mediumDate'}}</p>
                              </span>
                              <span>
                                  <strong>time</strong>
                                  <p >{{item.departure_date | date:'shortTime'}}</p>
                              </span>
                              <span>
                                  <strong>pnr</strong>
                                  <p>{{item.pnr}}</p>
                              </span>
                          </div>
                      </div>

                  </div>


              </div>
          </div>
          <div *ngIf="addOne"  style="padding-bottom: 17px"  class="col-4 position-relative"></div>
          <div *ngIf="addTwo"  style="padding-bottom: 17px"  class="col-4 position-relative"></div>
          <div *ngIf="addTwo"  style="padding-bottom: 17px"  class="col-4 position-relative"></div>
        </div>
        <div class="recommended" *ngIf="flights == 0">
          <h2 class="text-center">We Recommend you try these places</h2>
          <div class="recommended-section">
            <div
              class="recommended-box"
              *ngFor="let place of recommendedPlaces"
            >
              <div class="head-img">{{ place.origin_city }}</div>
              <img
                src="{{ place.image }}"
                title="Recommended Place "
                [alt]="place.title"
              />
              <p>{{ place.title }}</p>
              <span>{{ place.offer_name }}</span>
              <span>
                Popular with travellers in {{ place.origin }},
                {{ place.origin_city }}</span
              >
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
<app-footer></app-footer>
