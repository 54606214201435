<div style="position: relative">
  <input type="text" class="form-control" #input (click)="input.select()" [ngbTypeahead]="flightsSearch"
    (selectItem)="selectAirport($event)" [(ngModel)]="inputValue" (focus)="onFocus()" (blur)="onFocus()" />
  <ng-container *ngIf="!isFocused && !inputValue">
    <div class="placeHolder" (click)="input.select()">
      <div>
        <span class="star">*</span>
        <span>Destination</span>
      </div>
      <div>
        <img alt="location" title="Destination" style="width: 24px;height: 24px;"
          src="/assets/images/icons/location-pin.svg">
      </div>
    </div>
  </ng-container>
</div>
