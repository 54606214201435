import { Component, OnInit, ViewChild, Output, EventEmitter, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { ApisService } from 'src/app/services/apis.service';
import { HotelPassengersNumberComponent } from '../hotel-passengers-number/hotel-passengers-number.component';

@Component({
  selector: 'app-hotel-search',
  templateUrl: './hotel-search.component.html',
  styleUrls: ['./hotel-search.component.css']
})
export class HotelSearchComponent implements OnInit {

  @Output() searchDataReady = new EventEmitter();

  @Input() roundSpinner: boolean = false;

  // select item of NgbTypeAhead
  selectArray = []
  selectArray2 = []
  hotelCityLong;
  hotelCityLat;

  // dates
  today: Date = new Date();
  initialDate: Date = new Date();

  // searchForm
  searchForm = this.fb.group({
    location: ['', Validators.required],
    checkInDate: ['', Validators.required],
    checkOutDate: ['', Validators.required],
    property: [''],
    businessTrip: [true],
    adults: [1],
    children: [0],
    children_ages: ['']
  })
  submitErrorHotels: boolean = false;

  passengerData: any;
  // this component is viewed to fetch passenger data
  @ViewChild(HotelPassengersNumberComponent)
  private hotelPassengerComponent: HotelPassengersNumberComponent;

  // roundSpinner: boolean = false;

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private apis: ApisService,
    private changeDetection: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    this.initialDate.setDate(this.today.getDate() + 2);
  }


  // when user submit his new search (in hotel-results page) by clicking search button this method is called
  // this method is used to format the data as the server needs and emit an event to the parent (hotelSearchComponent)
  formatFormData() {
    let hotelSearchData;

    if (this.searchForm.valid) {
      this.roundSpinner = true;
      this.submitErrorHotels = false;
      // add ready data to hotelSearchData
      hotelSearchData = this.searchForm.value;

      // format dates from "2020-06-25T22:00:00.000Z" to "2020-06-25"
      hotelSearchData.starte_date = this.datePipe.transform(hotelSearchData.checkInDate, 'yyyy-MM-dd');
      hotelSearchData.end_date = this.datePipe.transform(hotelSearchData.checkOutDate, 'yyyy-MM-dd');

      // extract city name and country from location and remove any trailing spaces
      let destinationArray = this.searchForm.value.location.split(',');
      hotelSearchData.country = destinationArray[destinationArray.length - 1].trim(); // the last element in the array
      hotelSearchData.city_name = destinationArray[destinationArray.length - 2].trim(); // element before the last one

      // add some constants
      hotelSearchData.sort_order = 'ASC';
      hotelSearchData.type = 'location';
      hotelSearchData.radius = 20;
      hotelSearchData.sabre_rating_min = 3;
      hotelSearchData.sabre_rating_max = 5;
      hotelSearchData.longitude = this.hotelCityLong;
      hotelSearchData.latitude = this.hotelCityLat

      // this fields indicates that the searchData is coming from this component, not from home page
      hotelSearchData.SearchFromComponent = true;
      this.searchDataReady.emit(hotelSearchData);
    } else {
      this.roundSpinner = false
      this.submitErrorHotels = true;
    }

  }

  // this method is called when user tarts typing in location input field
  hotelsSearch = (text$: Observable<string>) => {
    return text$.pipe(
      switchMap(term => {
        if (term.length >= 1) {
          return this.apis.geoAutocomplete(term, 'CITY', null, null).pipe(
            map(el => {
              if (el[0] == " ") {
                setTimeout(() => {
                  const button = this.document.querySelector('[id^=ngb-typeahead] .dropdown-item:first-of-type') as HTMLButtonElement
                  button.innerHTML = `<span class="text-danger"> <i class="fas fa-exclamation-circle mr-1"></i> No results Found<span>`
                  button.classList.add('no-results');
                  button.disabled = true
                }, 0);
                return [' ']
              } else {
                this.selectArray = el.data.arr
                this.selectArray2 = el.data.res
                return el.data.arr
              }
            }),
            catchError(() => {
              return of([]);
            })
          )
        } else {
          return of([]);
        }
      })
    )
  }
  selectItem(item) {
    let index = this.selectArray.indexOf(item.item)
    this.selectArray2.forEach(element => {
      if (this.selectArray2.indexOf(element) === index) {
        this.hotelCityLat = element.latitude;
        this.hotelCityLong = element.longitude;
      }
    });
  }
  // this method is used when passenger number is changed from the PassengersNumberComponent ,
  // so we copy this data to the parent (hotelSearchComponent)
  updatePassengerData() {
    this.passengerData = this.hotelPassengerComponent.fetchData();
    this.searchForm.patchValue({
      adults: this.passengerData.adults,
      children: this.passengerData.children,
      children_ages: this.passengerData.children_ages
    })
  }

  // this to update searchForm when moving from home page to hotel-results page
  // newSearchData comes most of the time from localStorage
  updateSearchData(newSearchData) {
    // update some fields in the form
    this.searchForm.patchValue({
      location: newSearchData.location,
      checkInDate: newSearchData.checkInDate,
      checkOutDate: newSearchData.checkOutDate,
    })

    // update passenger data in passengerNumberComponent using passengerData input
    this.passengerData = {
      adults: newSearchData.adults,
      children: newSearchData.children,
      children_ages: newSearchData.children_ages
    }
    // update passenger data in the forms
    this.searchForm.patchValue({
      adults: newSearchData.adults,
      children: newSearchData.children,
      children_ages: newSearchData.children_ages
    })
    this.changeDetection.detectChanges();
  }

  // closing passenger list
  closePassengerList(event) {
    const parentElement = event.target.closest('.passenger-list');
    if (!parentElement) {
      if (this.hotelPassengerComponent && this.hotelPassengerComponent.showList) {
        this.hotelPassengerComponent.closeList();
      }
    }
  }
}
