<div class="col-12">
  <div class="row justify-content-between">
    <ng-container *ngFor="let step of steps ;let i = index">
      <div class="step d-flex flex-column align-items-center" [ngClass]="{'active': i+1 < currentStep ,'last': i==3 , 'currentStep': i == currentStep-1 }"
           style="flex: 1">
        <div class="circle">
          <span class="Font-Family">{{i + 1}}</span>
        </div>
        <div class="title">
          <span class="Font-Family">{{step}}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

