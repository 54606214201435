<section>
  <app-important-links></app-important-links>
</section>

<div class="footer">
  <div class="footer-body">
    <div class="container">
      <div class="logo">
        <a [routerLink]="['/']">
          <div class="d-flex align-items-center">
          <img
            style="width: 56px; height: 56px; margin-top: 10px"
            src="/assets/images/home/coparess/logo4.png"
            class="d-inline-block align-top"
            alt="FlyAllOver"
            title="FlyAllOver"
          />
          <h1 class="siteName Font-Family">Fly All Over</h1>
          </div>
        </a>
      </div>
      <div class="row container-text">
        <div class="col p-0">
          <div class="item text">
            <h6 class="Font-Family">Who are we?</h6>
            <p class="Font-Family">FlyAllOver is a travel agency that specializes in making it easy and affordable for everyone to travel
              the world. We believe that everyone deserves the opportunity to....<button type="button" class="readMore Font-Family" (click)="openDialog()">read more</button> </p> 
              
          </div>
        </div>
        <div class="col container-links">
          <div class="item links Font-Family">
            <h6 class="Font-Family">Company</h6>
            <ul>
              <li ><a [routerLink]="['/about']" href="https://flyallover.com/about" class="Font-Family">About Us</a></li>
              <!-- <li><a [routerLink]="['/']">Careers</a></li> -->
              <!-- <li><a [routerLink]="['/']">Partners</a></li> -->
              <li><a class="Font-Family" [routerLink]="['/newsletter']" href="https://flyallover.com/newsletter">Newsletter</a></li>
            </ul>
          </div>
        </div>
        <div class="col container-links">
          <div class="item links">
            <h6 class="Font-Family">Help</h6>
            <ul>
              <li><a [routerLink]="['/help-center']" href="https://flyallover.com/support" class="Font-Family">Help Center</a></li>
              <li><a [routerLink]="['/contact-us']" href="https://flyallover.com/contact-us " class="Font-Family">Contact Us</a></li>
              <li><a [routerLink]="['/faq']" href="https://flyallover.com/faq" class="Font-Family">FAQ</a></li>
            </ul>
          </div>
        </div>
        <div class="col container-follow">
          <div class="item follow">
            <h6 class="Font-Family">Follow us on</h6>
            <ul>
              <li><a target="_blank" rel="nofollow" href="https://www.facebook.com/flyallover/"><img
                title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a></li>
              <li><a target="_blank" rel="nofollow" href="https://twitter.com/FlyAllOverUS"><img title="twitter icon"
                                                                                                 alt="twitter icon"
                                                                                                 src="assets/icons/294709_circle_twitter_icon.svg">
              </a></li>
              <li><a target="_blank" href="https://www.youtube.com/channel/UCzmieXOqtvOCgBVZhqLAmmg/featured"><img
                title="Youtube Icon" alt="Youtube Icon"
                src="assets/icons/3225180_app_logo_media_popular_social_icon.svg"></a></li>
              <li><a target="_blank" rel="nofollow" href="https://www.instagram.com/flyalloverus/"><img
                title="instagram icon" alt="instagram icon"
                src="assets/icons/3225191_app_instagram_logo_media_popular_icon.svg"> </a></li>
              <li><a target="_blank" rel="nofollow" href="https://www.linkedin.com/company/flyallover"><img
                title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col">
          <p class="Font-Family">© Copyright <a class="Font-Family" [routerLink]="['/']">Fly all over</a> – All Rights Reserved</p>
        </div>
        <div class="col">
          <ul>
            <li><a class="Font-Family"[routerLink]="['/terms']" href="https://flyallover.com/terms">Terms & Conditions</a></li>
            <li><a class="Font-Family"[routerLink]="['/privacy']" href="https://flyallover.com/privacy">Privacy Policies</a></li>
            <li><a class="Font-Family"[routerLink]="['/chargeback-policy']" href="https://flyallover.com/privacy">Chargeback policy</a></li>
            <li><a class="Font-Family"[routerLink]="['/cancellation-policy']" >Cancellation Policy</a></li>
            <li>
              <div class="d-flex">
                  <div >
                    <img src="../../../assets/images/arrow/phone.png" width="40px" class="mx-2">
                  </div>
                  <div style="line-height: 1; margin-top: 4px;">
                    <span class="Font-Family">+1 212-381-1167</span> <br>
                    <span class="Font-Family" style="font-size: 12px">For issues or complaints</span>
                  </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
