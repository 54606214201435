import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApisService } from "../../../services/apis.service";
import { EMPTY, Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SEOService } from "../../../services/seo.service";
import { LocalStorageService } from "../../../services/webApis/local-storage.service";
import { ActivatedRoute } from "@angular/router";
import { validators } from "sitemap";

@Component({
  selector: 'app-app-review-flight-offer-add-traveller-form',
  templateUrl: './app-review-flight-offer-add-traveller-form.component.html',
  styleUrls: ['./app-review-flight-offer-add-traveller-form.component.css']
})
export class AppReviewFlightOfferAddTravellerFormComponent implements OnInit {
  @Input() searchRequest: any;
  @Input() payLater;
  @Output() setOwnerEvent = new EventEmitter();
  @Output() setPayLater = new EventEmitter();

  errorMessage: any[] = [];
  currentDate = new Date();
  form: FormGroup;
  relations: any = [];
  message: any;
  spinner: any = false;
  right: any;
  errorCheckEmail: any;
  loginBtn: any = false;
  numberOfTeravellers = [];
  email: any = null;
  filteredCountries: Observable<string[]>;
  filteredCountryInfo: Observable<string[]>;
  genderErrors: any;
  formData: any;
  travellers: any[] = []
  errors: any = [[]];
  months: any = [
    { text: 'January', num: '01' },
    { text: 'February', num: '02' },
    { text: 'March', num: '03' },
    { text: 'April', num: '04' },
    { text: 'May', num: '05' },
    { text: 'June', num: '06' },
    { text: 'July', num: '07' },
    { text: 'August', num: '08' },
    { text: 'September', num: '09' },
    { text: 'October', num: '10' },
    { text: 'November', num: '11' },
    { text: 'December', num: '12' }
  ];
  Passenger: any[] = []
  tagId: any;
  allTraveller: any[] = [];
  allTravellerType: any[] = [];
  // DO Reactive Form Array
  formGroupAddTraveller: FormGroup;
  genders: any = [{ name: "Female", value: 'F' }, { name: "Male", value: 'M' }];
  filteredCountry: Observable<any>;
  countries: any[] = [];
  passportIssuedCountries: any;
  tripType: string;
  initFormContact(): FormGroup {
    return new FormGroup({
      CreditCardCountry: this.payLater == false ? new FormControl("",) : new FormControl("United states",),
      CreditCardstreetAddress: this.payLater == false ? new FormControl("",) : new FormControl("197 Bay 31 street",),
      CreditCardCity: this.payLater == false ? new FormControl("", [

        Validators.pattern('^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,}$'),

      ]) : new FormControl("Brooklyn", [

        Validators.pattern('^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,}$'),

      ]),
      CreditCardZip: this.payLater == false ? new FormControl("", [

        Validators.pattern("[0-9]{1,100}"),
      ]) : new FormControl("11214", [

        Validators.pattern("[0-9]{1,100}"),
      ]),
      cardHolderName: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z]{3,}(?: [a-zA-Z]+){1,}$')]),
      email: new FormControl("", [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),
      phone: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^-?([0-9]\d*)?$/)]),
    });
  }
  get forminfo() {
    return this.form.controls;
  }
  initForminfo(): FormGroup {

    return this.fb.group({
      // email:['',[ Validators.email,Validators.required]],
      first_name: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      middle_name: ['', Validators.pattern(/^\S*$/)],
      last_name: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
      family_member_id: ['0', [Validators.required]],
      date_of_birth: ['', [Validators.required]],
      nationality_id: !this.payLater ? ['', [Validators.required]] : ['42', [Validators.required]],
      nationality_code: !this.payLater ? ['', [Validators.required]] : ['EG', [Validators.required]],
      country_id: !this.payLater ? ['', [Validators.required]] : ['42', [Validators.required]],
      country_code: !this.payLater ? ['', [Validators.required]] : ['EG', [Validators.required]],
      'country-code': !this.payLater ? ['', [Validators.required]] : ['EG', [Validators.required]],
      country: !this.payLater ? ['', [Validators.required]] : ['Egypt', [Validators.required]],
      nationality: !this.payLater ? ['', [Validators.required]] : ['EG', [Validators.required]],
      gender: !this.payLater ? ['', [Validators.required]] : ['', [Validators.required]],
      // phone_type: ['H', Validators.required],
      // phone: ['', [Validators.required,Validators.pattern(/^-?([0-9]\d*)?$/)]],
      // PassengerType: ['',!this.payLater?Validators.required:Validators.requiredTrue],
      PassengerType: !this.payLater ? ['', Validators.required] : ['',],
      color: '#e67e22',
      passport: this.fb.group({
        passport_number: !this.payLater ? ['', [Validators.required]] : ['124554845', [Validators.required]],
        expire_date: !this.payLater ? ['', [Validators.required]] : ['2026-01-01', [Validators.required]],
        issue_date: !this.payLater ? ['', [Validators.required]] : ['2022-01-01', [Validators.required]],
        issued_country: !this.payLater ? ['', [Validators.required]] : ['EG', [Validators.required]],
        country: !this.payLater ? ['', [Validators.required]] : ['EG', [Validators.required]],
        issueDate: this.fb.group({
          issue_date_day: !this.payLater ? ['', [Validators.required, Validators.maxLength(2)]] : ['01', [Validators.required, Validators.maxLength(2)]],
          issue_date_month: !this.payLater ? ['', [Validators.required]] : ['01', [Validators.required]],
          issue_date_year: !this.payLater ? ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]] : ['2022', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
        }),
        expireDate: this.fb.group({
          expiry_date_day: !this.payLater ? ['', [Validators.required, Validators.maxLength(2)]] : ['01', [Validators.required, Validators.maxLength(2)]],
          expiry_date_month: !this.payLater ? ['', [Validators.required]] : ['01', [Validators.required]],
          expiry_date_year: !this.payLater ? ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]] : ['2026', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
        }),
        travellerDob: this.fb.group({
          traveller_dob_day: !this.payLater ? ['', [Validators.required, Validators.maxLength(2)]] : ['', [Validators.required, Validators.maxLength(2)]],
          traveller_dob_month: ['', [Validators.required]],
          traveller_dob_year: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
        }),
      }),
    });
  }
  seoUpdate() {
    this.seoService.updateTitle("Review Flight");
    this.seoService.updateDescription(
      "Get all information about trips, flights and hotels with your profile in flyallover just sign up "
    );
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
  // ***********************
  constructor(
    private fb: FormBuilder,
    private apis: ApisService,
    private seoService: SEOService,
    private localStorage: LocalStorageService,
    private _ActivatedRoute: ActivatedRoute

  ) {
    this.form = this.initFormContact();
    // console.log('this.form', this.form);

    this.tagId = this._ActivatedRoute.snapshot.queryParamMap.get('tagId');
    this.tripType = this._ActivatedRoute.snapshot.queryParamMap.get('trip_type');
  }

  ngOnInit() {

    if (this.tripType == 'OpenJaw') {
      // console.log('here 1');

      this.formData = JSON.parse(this.localStorage.getItem("searchRequestMultiCityPayLater"));
    }
    else {
      // console.log("here 2");

      this.formData = JSON.parse(this.localStorage.getItem("searchRequestOffers"));
    }

    for (let i = 0; i < this.formData.adult; i++) {
      this.allTraveller.push('adult')
      this.allTravellerType.push('ADT')
    }
    for (let i = 0; i < this.formData.child; i++) {
      this.allTraveller.push('child')
      this.allTravellerType.push('CNN')
    }
    for (let i = 0; i < this.formData.lap; i++) {
      this.allTraveller.push('lap')
    }
    for (let i = 0; i < this.formData.seat; i++) {
      this.allTraveller.push('seat')
    }
    // form Array
    // console.log('search request', this.searchRequest);

    this.getCountries();
    this.getFamilyRelations();
    this.formGroupAddTraveller = this.fb.group({
      formArrayAddTraveller: this.fb.array([])
    });
    for (let index = 0; index < this.searchRequest.travellers; index++) {
      this.memberTraveller.push(this.initForminfo());
    }
    this.form.valueChanges.subscribe((res: any) => {
      // console.log(this.form);

      if (this.form.valid) {
        this.checkValidations();
      }
    })
    this.formGroupAddTraveller.valueChanges.subscribe((res: any) => {
      if (this.formGroupAddTraveller.valid) {
        this.checkValidations();
      }
    })
    this.form.get('email').valueChanges.subscribe((res: any) => {
      if (!this.form.get('email').errors) {
        this.setEmails(this.form.get('email').value);
      }
    })

    // *************************
    // console.log(this.formData);
    this.numberOfTeravellers.length = this.searchRequest.travellers;
    this.travellers.length = this.numberOfTeravellers.length;
    // console.log(this.travellers.length);
    // this.getFamilyRelations();
    // this.gendersListener();
    // this.errors = Array(this.numberOfTeravellers.length)
    //   .fill(0)
    //   .map(() => Array(15).fill(0));
  }

  // form arrar **********************
  get memberTraveller() {
    return <FormArray>this.formGroupAddTraveller.controls["formArrayAddTraveller"];
  }
  getFamilyRelations() {
    this.apis.familyRelations().subscribe(
      (data: any) => {
        this.relations = data.data;
        // console.log('this.relations', data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  // auto compelete filter Country Info
  getfilterCountryInfo() {
    if (this.countries) {
      this.filteredCountryInfo = this.form.get('CreditCardCountry').valueChanges.pipe(
        startWith(''),
        map((value) => this._filterCountriesInfo(value)),
      );
    }
  }
  private _filterCountriesInfo(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.form.controls["CreditCardCountry"].setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  // auto compelete filter Country
  getCountries() {
    this.apis.getCountries().subscribe(
      (data: any) => { this.countries = data },
      (err) => { console.log(err); }
    );
  }
  getfilterCountry(i) {
    if (this.countries) {
      this.filteredCountry = this.memberTraveller.controls[i].get('passport').get('issued_country').valueChanges.pipe(
        startWith(''),
        map((value) => this._filterCountries(i, value)),
      );
    }
  }
  private _filterCountries(i: number, value: string): string[] {
    const filterValue = value.toLowerCase();
    if (
      this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      ).length == 0
    ) {
      this.memberTraveller.controls[i].get('passport').get('issued_country').setErrors({
        incorrect: true,
        someProp: "Country Not Found Please Select Valid Country",
      });
    } else {
      return this.countries.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  // *********************
  calculateDiff(index) {
    let thisDate = new Date();
    let dateSent = new Date(this.currentDate);
    // console.log("hazem check date" + Math.floor((Date.UTC(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24 * 365)))
    if (Math.floor((Date.UTC(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24 * 365)) < 18) {
      this.errorMessage[index] = "you should be older than 18"
    }
    else {
      this.errorMessage[index] = null;
    }
    return true;
  }
  checkDate(index, formGroupName) {
    this.memberTraveller.controls[index].get('passport').get(`${formGroupName}`).valueChanges.subscribe(res => {
      switch (formGroupName != undefined) {
        case formGroupName == 'travellerDob': {
          this.currentDate = new Date(parseInt(res.traveller_dob_year), parseInt(res.traveller_dob_month), parseInt(res.traveller_dob_day), 0, 0, 0);

          if (this.allTraveller[index] == "adult" && this.calculateDiff(index)) {
            // console.log('adult',this.allTraveller[index]);


            this.memberTraveller.controls[index].patchValue({
              date_of_birth: `${res.traveller_dob_year}-${res.traveller_dob_month}-${this.padLeft(res.traveller_dob_day, "0", 2)}`
            })



            break;
          }
          // if(this.allTraveller[index]!='adult')
          else {
            // console.log('Not adult',this.allTraveller[index]);
            this.memberTraveller.controls[index].patchValue({
              date_of_birth: `${res.traveller_dob_year}-${res.traveller_dob_month}-${this.padLeft(res.traveller_dob_day, "0", 2)}`
            })
            break;
          }

        }

        case (formGroupName == 'issueDate'): {

          if (!this.payLater) {

            // console.log('issueDate');
            this.memberTraveller.controls[index].get('passport').patchValue({
              issue_date: `${res.issue_date_year}-${res.issue_date_month}-${this.padLeft(res.issue_date_day, "0", 2)}`
            })
          }
          break;
        }
        case (formGroupName == 'expireDate'): {
          if (!this.payLater) {


            this.memberTraveller.controls[index].get('passport').patchValue({
              expire_date: `${res.expiry_date_year}-${res.expiry_date_month}-${this.padLeft(res.expiry_date_day, "0", 2)}`
            })
          }
          break;
        }

        default: {
          // console.log("No date exists!");
          break;
        }
      }
    })
  }
  countryPatchValue(index) {
    this.memberTraveller.controls[index].get('passport').get('issued_country').valueChanges.subscribe(res => {
      this.memberTraveller.controls[index].patchValue({
        country_id: !this.payLater ? this.getCountryObject(res).id : 42,
        country_code: !this.payLater ? this.getCountryObject(res).code : "EG",
        'country-code': !this.payLater ? this.getCountryObject(res).code : "EG",
        country: !this.payLater ? this.getCountryObject(res) : "EG",
        nationality_id: !this.payLater ? this.getCountryObject(res).id : "EG",
        nationality_code: !this.payLater ? this.getCountryObject(res).code : "EG"
      })
      this.memberTraveller.controls[index].get('passport').patchValue({
        country: !this.payLater ? this.getCountryObject(res) : "Egypt"
      })
      this.memberTraveller.controls[index].patchValue({
        nationality: !this.payLater ? this.getCountryObject(res) : "EG",
        country: !this.payLater ? this.getCountryObject(res) : "Egypt",
      })
    })
  }
  // get object country
  getCountryObject(country): any {
    let objCountry = this.countries.find((obj) => obj.name === country);
    return objCountry
  }
  getFamilyObject(relation): any {
    const objRelation = this.relations.find((obj) => obj.name === relation);
    return objRelation
  }
  get formMember() {
    return this.memberTraveller.controls
  }
  sendFormtoArray() {
    // console.log(this.formGroupAddTraveller.invalid);
    // this.setOwnerEvent.emit(this.allFormData(this.travellers));
  }
  setEmails(e) {
    if (e) {
      // console.log('email  =======>  ', e);
      this.message = "";
      this.right = false;
      this.errorCheckEmail = false;
      this.spinner = true;
      this.apis.checkMail(e).subscribe(
        (data: any) => {
          if (data.status) {
            this.spinner = false;
            this.right = true;
            this.email = e
            this.errorCheckEmail = false;
          }
        },
        (err) => {
          this.right = false;
          this.spinner = false;
          this.loginBtn = true;
          this.errorCheckEmail = true;
          this.message = "The email has already been taken.";
        }
      );
    } else {
      this.spinner = false;
      this.right = false;
      this.loginBtn = false;
      this.errorCheckEmail = true;
      this.message = "You have entered an invalid email address!";
    }
  }
  checkValidations() {
    if (this.formGroupAddTraveller.valid && this.form.valid) {
      // this.validForm = true
      this.changeTravellersFormat();
      // console.log("done", this.travellers);
      this.setOwnerEvent.emit(this.allFormData(this.travellers));
      this.setPayLater.emit({ travellers: this.travellers, initFormContact: this.form, validTravellers: true });
      // console.log("still done", this.allFormData(this.travellers));

    } else {
      // console.log("still not done", this.allFormData(this.travellers));
    }
  }
  // format Traveller data
  changeTravellersFormat() {
    this.travellers = []
    for (let i = 0; i < this.numberOfTeravellers.length; i++) {
      let formTraveller = (<FormArray>this.formGroupAddTraveller.get('formArrayAddTraveller')).value[i]
      // console.log('index ', i, formTraveller);
      var travellerGender = "";
      if (formTraveller.gender === "Male") {
        travellerGender = "M";
      }
      if (formTraveller.gender === "Female") {
        travellerGender = "F";
      }
      this.travellers.push({
        GivenName: formTraveller.first_name,
        MiddleName: formTraveller.middle_name,
        Surname: formTraveller.last_name,
        family_member_id: "0",
        family_relation_id: !this.payLater ? this.getFamilyObject(formTraveller.PassengerType).id.toString() : "1",
        traveller_dob: formTraveller.date_of_birth,
        passport_number: !this.payLater ? formTraveller.passport.passport_number : "124554845",
        expiry_date: !this.payLater ? formTraveller.passport.expire_date : "2026-01-01",
        issue_date: !this.payLater ? formTraveller.passport.issue_date : "2022-01-01",
        nationality_id: !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).id.toString() : "42",
        nationality_code: !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).code : "EG",
        country_id: !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).id.toString() : "42",
        country_code: !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).code : "EG",
        'country-code': !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).code : "EG",
        countryPassport: !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).code : "EG",
        nationality: !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).code : "EG",
        issuedCountry: !this.payLater ? this.getCountryObject(formTraveller.passport.issued_country).code : "EG",
        traveller_gender: travellerGender,
        phone_type: "H",
        PassengerType: !this.payLater ? this.getFamilyObject(formTraveller.PassengerType).passenger_type : this.allTravellerType[i],
        color: "#e67e22"
      });
    }
  }
  // format payload
  allFormData(travellerForm) {
    const object: any = {};
    const travelarFormSort = [];
    let HOF = []
    let SPS = []
    let FNN = []
    HOF = travellerForm.filter((x) => x.PassengerType == "HOF");
    HOF.forEach((item) => { travelarFormSort.push(item) })
    SPS = travellerForm.filter((x) => x.PassengerType == "SPS");
    SPS.forEach((item) => { travelarFormSort.push(item) })
    FNN = travellerForm.filter((x) => x.PassengerType == "FNN");
    FNN.forEach((item) => { travelarFormSort.push(item) })
    for (let i = 0; i < travelarFormSort.length; i++) {
      object[i + 1] = {};
      object[i + 1] = travelarFormSort[i];
    }
    object.tagId = this.tagId;
    object.origin = this.searchRequest.origin;
    object.destination = this.searchRequest.destination;
    object.departure_date = this.searchRequest.departure_date;
    object.return_date = this.searchRequest.return_date;
    object.passengerscount = this.searchRequest.travellers.toString();
    object.trip_type = this.searchRequest.trip_type;
    object.origin_country = this.getOriginCountry();
    object.destination_country = this.getDestinationCountry();
    object.email = this.email;
    object.phone = this.form.value.phone;
    object.CreditCardCity = this.payLater == false ? this.form.value.CreditCardCity : "Brooklyn";
    object.CreditCardstreetAddress = this.payLater == false ? this.form.value.CreditCardstreetAddress : "197 Bay 31 street";
    object.cardHolderName = this.form.value.cardHolderName;
    object.CreditCardCountry = this.payLater == false ? this.form.value.CreditCardCountry : "United states";
    object.CreditCardZip = this.payLater == false ? this.form.value.CreditCardZip : "11214";
    // console.log('object ===> ', object);

    return object;
  }
  getRelationObject(relation): any {
    const index = this.Passenger.findIndex((obj) => obj.member === relation);
    if (index > -1) {
      return this.Passenger[index];
    }
  }
  getOriginCountry() {
    const arr = this.searchRequest.origin.split(",");
    return arr[arr.length - 1];
  }
  getDestinationCountry() {
    const arr = this.searchRequest.destination.split(",");
    return arr[arr.length - 1];
  }
  format(e) {

    e.target.value = this.padLeft(e.target.value, "0", 2);
    // console.log(e.target.value)
  }
  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);

  }

















  // setGivenName(e, index) {
  //   if (e.target.value == "") {
  //     (<HTMLInputElement>(
  //       document.getElementById("firstName" + index)
  //     )).classList.add("show");
  //   } else {
  //     const mailFormat = /^[a-zA-Z ]+$/;
  //     if (e.target.value.match(mailFormat)) {
  //       this.firstName[index] = e.target.value;
  //       (<HTMLInputElement>(
  //         document.getElementById("firstName" + index)
  //       )).classList.remove("show");
  //     } else {
  //       (<HTMLInputElement>(
  //         document.getElementById("firstName" + index)
  //       )).classList.add("show");
  //     }
  //     console.log(this.travellers);
  //   }
  // }

  // setMiddleName(e, index) {
  //   this.middleNames[index] = e.target.value;
  //   console.log(this.middleNames);
  // }

  // setLastName(e, index) {
  //   const mailFormat = /^[a-zA-Z ]+$/;
  //   if (e.target.value.match(mailFormat)) {
  //     this.lastNames[index] = e.target.value;
  //     console.log(this.lastNames);
  //     (<HTMLInputElement>(
  //       document.getElementById("LastName" + index)
  //     )).classList.remove("show");
  //   } else {
  //     (<HTMLInputElement>(
  //       document.getElementById("LastName" + index)
  //     )).classList.add("show");
  //   }
  // }



  // setNumbers(e) {
  //   if (e.target) {
  //     const phoneFormat = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  //     if (e.target.value.match(phoneFormat)) {
  //       this.phoneError = 566556556;
  //       this.phone = e.target.value;
  //     } else {
  //       this.phoneError = true;
  //     }
  //   } else {
  //     this.phoneError = true;
  //   }
  //   this.checkValidation();
  // }


  // setPersonalCountries(e, index) {
  //   console.log(e);
  //   if (e == undefined) {
  //     this.countryErrors = index;
  //   } else {
  //     if (e.value.length > 0) {
  //       this.selectedPersonalCountries[index] = this.getCountryObject(
  //         e.value
  //       ).id;
  //       this.selectedCountryCodes[index] = this.getCountryObject(e.value).code;
  //       console.log(this.selectedPersonalCountries);
  //       this.countryErrors = 1214212;
  //     } else {
  //       this.countryErrors = index;
  //     }
  //   }
  //   if (this.selectedPersonalCountries[index]) {
  //     this.countryErrors = 155556;
  //   }
  //   this.checkValidation();
  // }

  // setRelations(e, index) {
  //   if (e.value == undefined) {
  //     this.relationsError = index;
  //   } else {
  //     if (e.value.length > 0) {
  //       let itemRelationFamilies = this.relations.filter((item)=> e.value == item.name);
  //       console.log('setRelations ==>',itemRelationFamilies);
  //       this.selectedRelations[index] = this.getRelationObject(e.value).type;
  //       this.familyRelationId[index] = itemRelationFamilies[0].id.toString()
  //       console.log('xxxxxxxxxxxxxxxxxxxxxxxx=>>',this.selectedRelations , this.familyRelationId);
  //       this.relationsError = 1214212;
  //     } else {
  //       this.relationsError = index;
  //     }
  //   }
  //   console.log(this.selectedRelations.length);
  //   if (this.selectedRelations[index]) {
  //     this.relationsError = 155556;
  //   }
  //   this.checkValidation();
  // }

  // setPassportNumbers(e, index) {
  //   if (e.target.value == "") {
  //     this.passportErrors = index;
  //   } else {
  //     const phoneFormat = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  //     if (e.target.value.match(phoneFormat)) {
  //       this.passportErrors = 566556556;
  //       this.passportNumber[index] = e.target.value;
  //       console.log(this.passportNumber);
  //     } else {
  //       this.passportErrors = index;
  //     }
  //   }
  //   this.checkValidation();
  // }

  // setTravellerNationality(e, index) {
  //   console.log(e);
  //   if (e == undefined) {
  //     this.nationalityErrors = index;
  //   } else {
  //     if (e.value.length > 0) {
  //       this.selectedNationalitiesCountries[index] = this.getCountryObject(
  //         e.value
  //       ).id;
  //       this.selectedNationalityCodes[index] = this.getCountryObject(
  //         e.value
  //       ).code;
  //       this.nationalityErrors = 155556;
  //       console.log(this.selectedNationalitiesCountries);
  //     } else {
  //       this.nationalityErrors = index;
  //     }
  //   }
  //   if (this.selectedNationalitiesCountries[index]) {
  //     this.nationalityErrors = 155556;
  //   }
  //   this.checkValidation();
  // }

  // setPassportCountries(e, index) {
  //   if (e == undefined) {
  //     this.passportCountryErrors = index;
  //   } else {
  //     if (e.value.length > 0) {
  //       this.selectedPassportCountries[index] = this.getCountryObject(
  //         e.value
  //       );
  //       console.log(this.selectedPassportCountries);
  //       this.passportCountryErrors = 155556;
  //     } else {
  //       this.passportCountryErrors = index;
  //     }
  //   }
  //   if (this.selectedPassportCountries[index]) {
  //     this.passportCountryErrors = 155556;
  //   }
  //   this.checkValidation();
  // }

  // setPassportNationalities(e, index) {
  //   if (e == undefined) {
  //     this.PassportNationalityErrors = index;
  //   } else {
  //     if (e.value.length > 0) {
  //       this.selectedPassportNationalities[index] = this.getCountryObject(
  //         e.value
  //       );
  //       this.PassportNationalityErrors = 155556;
  //     } else {
  //       this.PassportNationalityErrors = index;
  //     }
  //   }
  //   if (this.selectedPassportNationalities[index]) {
  //     this.PassportNationalityErrors = 155556;
  //   }
  //   this.checkValidation();
  // }

  // setIssuedCountries(e, index) {
  //   if (e == undefined) {
  //     this.issuedCountryErrors = index;
  //   } else {
  //     if (e.value.length > 0) {
  //       this.selectedIssuedCountries[index] = this.getCountryObject(
  //         e.value
  //       ).code;
  //       console.log(this.selectedIssuedCountries);
  //       this.issuedCountryErrors = 155556;
  //     } else {
  //       this.issuedCountryErrors = index;
  //     }
  //   }
  //   if (this.selectedPassportNationalities[index]) {
  //     this.issuedCountryErrors = 155556;
  //   }
  //   this.checkValidation();
  // }

  // setIssueDates(e, index) {
  //   console.log(this.issuedDate.day, this.issuedDate.month, this.issuedDate.year);
  //   if (this.issuedDate.day != undefined && this.issuedDate.month != undefined && this.issuedDate.year != undefined) {
  //     console.log(this.issuedDate.day, this.issuedDate.month, this.issuedDate.year);
  //     this.issueDateError = 2254354;
  //     console.log(new Date(this.issuedDate.month + '/' + this.issuedDate.day + '/' + this.issuedDate.year).valueOf(), new Date().valueOf())
  //     if (new Date(this.issuedDate.month + '/' + this.issuedDate.day + '/' + this.issuedDate.year).valueOf() > +new Date().valueOf()) {
  //       this.issueDateError = index;
  //     } else {
  //       this.issueDateError = 454544;
  //       this.issueDates[index] = this.issuedDate.year + '-' + this.issuedDate.month + '-' + this.issuedDate.day;
  //       if (this.issueDates[index].length > 0) {
  //         this.issueDateError = 155556;
  //       }
  //       this.checkValidation();
  //     }
  //   }
  // }
  // setissueDay(day) {
  //   this.issuedDate.day = day
  // }
  // setissueMonth(month) {
  //   this.issuedDate.month = month
  // }
  // setissuYear(year) {
  //   this.issuedDate.year = year
  // }
  // setExpireDay(day) {
  //   this.expireDate.day = day
  // }
  // setExpireMonth(month) {
  //   this.expireDate.month = month
  // }
  // setExpireYear(year) {
  //   this.expireDate.year = year
  // }
  // setBirthDateMonth(month) {
  //   this.birthDate.month = month
  // }
  // setBirthDateDay(day) {
  //   this.birthDate.day = day
  // }
  // setBirthDateYear(year) {
  //   this.birthDate.year = year
  // }
  // checkExpireDate(e, index) {
  //   if (this.expireDate.day != undefined && this.expireDate.month != undefined && this.expireDate.year != undefined) {
  //     this.expireDateError = index;
  //     const returnDate = new Date(this.formData.return_date).valueOf();
  //     if (new Date(this.expireDate.month + '/' + this.expireDate.day + '/' + this.expireDate.year).valueOf() < returnDate) {
  //       this.expireDateError = index;
  //     } else {
  //       this.expireDateError = 454544;
  //       this.expirationDates[index] = this.expireDate.year + '-' + this.expireDate.month + '-' + this.expireDate.day;
  //     }
  //     if (this.expirationDates[index].length > 0) {
  //       this.expireDateError = 155556;
  //     }
  //     this.checkValidation();
  //     console.log(this.expirationDates);
  //   }
  // }
  // checkAge(e, index) {
  //   console.log('check age =>', e, index)
  //   // console.log('all date out=>', this.year + '-' + this.month + '-' + this.day);
  //   if (this.birthDate.day != undefined && this.birthDate.month != undefined && this.birthDate.year != undefined) {
  //     const currentDate = new Date().getFullYear();

  //     if (currentDate - this.year < 18) {
  //       this.birthdateError = index;
  //     } else {
  //       this.birthdateError = 454544;
  //       this.travellerDOBs[index] = this.birthDate.year + '-' + this.birthDate.month + '-' + this.birthDate.day;
  //     }
  //     if (this.travellerDOBs[index].length > 0) {
  //       this.birthdateError = 155556;
  //     }
  //     this.checkValidation();
  //   }
  // }


  // gendersListener(e, index) {
  //   if (e == undefined) {
  //     this.genderErrors = index;
  //   } else {
  //     if (e.value.length > 0) {
  //       this.selectedGender[index] = e.value;
  //       console.log(this.selectedGender);
  //       this.genderErrors = 1214212;
  //     } else {
  //       this.genderErrors = index;
  //     }
  //   }
  //   if (this.selectedGender[index]) {
  //     this.genderErrors = 155556;
  //   }
  // }


  // filterCountries(evt) {
  //   evt = evt.target.value.toLowerCase();
  //   const filterValue = evt + "";
  //   if (!filterValue) {
  //     this.filteredPersonalCountries = this.personalCountries;
  //   } else {
  //     this.filteredPersonalCountries = this.personalCountries.filter((option) =>
  //       option.name.toLowerCase().includes(filterValue.toLowerCase())
  //     );
  //   }
  // }

  // filterNationality(evt) {
  //   evt = evt.target.value.toLowerCase();
  //   const filterValue = evt + "";
  //   if (!filterValue) {
  //     this.filteredNationalitiesCountries = this.personalNationalities;
  //   } else {
  //     this.filteredNationalitiesCountries = this.personalNationalities.filter(
  //       (option) =>
  //         option.name.toLowerCase().includes(filterValue.toLowerCase())
  //     );
  //   }
  // }

  // filterRelations(evt) {
  //   evt = evt.target.value.toLowerCase();
  //   const filterValue = evt + "";
  //   if (!filterValue) {
  //     this.filteredRelations = this.Passenger;
  //   } else {
  //     this.filteredRelations = this.Passenger.filter((option) =>
  //       option.member.toLowerCase().includes(filterValue.toLowerCase())
  //     );
  //   }
  // }

  // filterPassportCountries(evt) {
  //   evt = evt.target.value.toLowerCase();
  //   const filterValue = evt + "";
  //   if (!filterValue) {
  //     this.filteredPassportCountries = this.personalNationalities;
  //   } else {
  //     this.filteredPassportCountries = this.personalNationalities.filter(
  //       (option) =>
  //         option.name.toLowerCase().includes(filterValue.toLowerCase())
  //     );
  //   }
  // }

  // filterPassportNationalities(evt) {
  //   evt = evt.target.value.toLowerCase();
  //   const filterValue = evt + "";
  //   if (!filterValue) {
  //     this.filteredPassportNationalities = this.personalNationalities;
  //   } else {
  //     this.filteredPassportNationalities = this.personalNationalities.filter(
  //       (option) =>
  //         option.name.toLowerCase().includes(filterValue.toLowerCase())
  //     );
  //   }
  // }

  // filterPassportIssuedCountry(evt) {
  //   evt = evt.target.value.toLowerCase();
  //   const filterValue = evt + "";
  //   if (!filterValue) {
  //     this.filteredPassportIssuedCountries = this.passportIssuedCountries;
  //   } else {
  //     this.filteredPassportIssuedCountries =
  //       this.passportIssuedCountries.filter((option) =>
  //         option.name.toLowerCase().includes(filterValue.toLowerCase())
  //       );
  //   }
  // }

  // getCountries() {
  //   this.apis.getCountries().subscribe(
  //     (data: any) => {
  //       console.log('dafdfdfasfasf==============> ', data);

  //       this.Countries2 = data
  //       this.passportCountries = data;
  //       this.passportNationalities = data;
  //       this.passportIssuedCountries = data;
  //       this.personalCountries = data;
  //       this.personalNationalities = data;
  //       this.filteredPersonalCountries = this.personalCountries;
  //       this.filteredPersonalNationalities = this.personalNationalities;
  //       this.filteredPassportCountries = this.personalNationalities;
  //       this.filteredPassportNationalities = this.personalNationalities;
  //       this.filteredNationalitiesCountries = this.personalNationalities;
  //       this.filteredPassportIssuedCountries = this.passportIssuedCountries;
  //       if (this.Countries2) {
  //         this.filteredCountries =
  //           this.form.controls.CreditCardCountry.valueChanges.pipe(
  //             startWith(""),
  //             map((value) => this._filterCountries(value))
  //           );
  //       }
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  // private _filterCountries(value: string): string[] {
  //   const filterValue = value.toLowerCase();
  //   if (
  //     this.Countries2.filter((option) =>
  //       option.name.toLowerCase().includes(filterValue)
  //     ).length == 0
  //   ) {
  //     this.form.controls["CreditCardCountry"].setErrors({
  //       incorrect: true,
  //       someProp: "Country Not Found Please Select Valid Country",
  //     });
  //   } else {
  //     return this.Countries2.filter((option) =>
  //       option.name.toLowerCase().includes(filterValue)
  //     );
  //   }
  // }
  // if (e.value == undefined) {
  //   this.birthdateError = index;
  // } else {
  //   if (e.value == "") {
  //     this.birthdateError = index;
  //   } else {
  //     const currentDate = new Date().getFullYear();
  //     if (currentDate - this.year < 18) {
  //       this.birthdateError = index;
  //     } else {
  //       console.log('currentDate else =>',currentDate - this.year);
  //       this.birthdateError = 454544;
  //       this.travellerDOBs = this.year+'-'+this.month+'-'+this.day
  //     }
  //   }
  // }
  // if (this.travellerDOBs[index].length > 0) {
  //   this.birthdateError = 155556;
  // }
  // this.checkValidation();
  // checkValidation() {
  //   console.log(
  //     // this.selectedCountryCodes.length,
  //     this.selectedPassportCountries.length,
  //     this.issueDates.length,
  //     this.expirationDates.length,
  //     // this.selectedNationalityCodes.length,
  //     this.selectedPassportNationalities.length,
  //     this.selectedRelations.length,
  //     this.selectedIssuedCountries.length,
  //     this.firstName.length,
  //     this.lastNames.length,
  //     // this.numbers.length,
  //     this.selectedGender.length,
  //     this.travellerDOBs.length,
  //     this.selectedNationalitiesCountries.length,
  //     this.passportNumber.length,
  //     // this.emails.length
  //   );
  //   console.log(this.numberOfTeravellers.length);
  //   if (
  //     // Number(this.selectedCountryCodes.length) ===
  //     //   Number(this.numberOfTeravellers.length) &&
  //     Number(this.selectedPassportCountries.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.issueDates.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.expirationDates.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.selectedPassportNationalities.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.selectedRelations.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.selectedIssuedCountries.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.firstName.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.lastNames.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     // Number(this.numbers.length) === Number(this.numberOfTeravellers.length) &&
  //     Number(this.selectedGender.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     Number(this.travellerDOBs.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     // Number(this.selectedNationalitiesCountries.length) ===
  //     //   Number(this.numberOfTeravellers.length) &&
  //     Number(this.passportNumber.length) ===
  //     Number(this.numberOfTeravellers.length) &&
  //     this.email != null &&
  //     this.phone != null &&
  //     this.form.valid
  //   ) {
  //     this.validForm = true
  //     this.changeTravellersFormat();
  //     console.log("done", this.travellers);
  //     this.setOwnerEvent.emit(this.allFormData(this.travellers));
  //   } else {
  //     this.changeTravellersFormat();
  //     console.log("still not done", this.allFormData(this.travellers));
  //   }
  // }

  // formatDate(date: Date) {
  //   function pad(s) {
  //     return s < 10 ? "0" + s : s;
  //   }

  //   var d = new Date(date);
  //   return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()]
  //     .reverse()
  //     .join("-");
  // }
  // initTravellerObject(): any {
  //   const traveller: any = {};
  //   traveller.family_relation_id = this.form.value.familyRelationId;
  //   traveller.PassengerType = "ADT";
  //   traveller.phone_type = "H";
  //   traveller.GivenName = this.form.value.GivenName;
  //   traveller.MiddleName = this.form.value.MiddleName;
  //   traveller.Surname = this.form.value.Surname;
  //   traveller.phone = this.form.value.phone;
  //   traveller.traveller_dob = this.convertDate(
  //     String(this.form.value.travellerDOB)
  //   );
  //   traveller.passport_number = this.form.value.passportNumber;
  //   traveller.expiry_date = this.convertDate(
  //     String(this.form.value.expiryDate)
  //   );
  //   traveller.issue_date = this.convertDate(String(this.form.value.issueDate));
  //   // custom attributes
  //   traveller.nationality_id = this.getCountryObject(
  //     this.form.value.travellerNationality
  //   ).id;
  //   traveller.nationality_code = this.getCountryObject(
  //     this.form.value.travellerNationality
  //   ).code;
  //   traveller.country_id = this.getCountryObject(
  //     this.form.value.travellerCountry
  //   ).id;
  //   traveller.country_code = this.getCountryObject(
  //     this.form.value.travellerCountry
  //   ).code;
  //   traveller.countryPassport = this.getCountryObject(
  //     this.form.value.passportCountry
  //   ).code;
  //   traveller.nationality = this.getCountryObject(
  //     this.form.value.passportNationality
  //   ).code;
  //   traveller.issuedCountry = this.getCountryObject(
  //     this.form.value.passportIssuedCountry
  //   ).code;
  //   traveller["country-code"] = this.getCountryObject(
  //     this.form.value.travellerCountry
  //   ).code;
  //   if (this.form.value.gender === "Male") {
  //     traveller.traveller_gender = "M";
  //   }
  //   if (this.form.value.gender === "Female") {
  //     traveller.traveller_gender = "F";
  //   }
  //   return traveller;
  // }

  // addTraveller(): any {
  //   this.setOwnerEvent.emit(this.initTravellerObject());
  // }

  // getCountryObject(country): any {
  //   const index = this.passportCountries.findIndex(
  //     (obj) => obj.name === country
  //   );
  //   if (index > -1) {
  //     return this.passportCountries[index];
  //   }
  // }


  // getOfferPnr() {
  //   console.log(this.allFormData(this.travellers));
  //   this.apis.createOnlyPnr(this.allFormData(this.travellers)).subscribe({
  //     next: (res: any) => {
  //     }
  //   })
  // }
  // removeRequiredFormDisplayedErrors(errors): any {
  //   const index = errors.findIndex((obj) => obj === "required");
  //   if (index > -1) {
  //     errors = errors.splice(index, 1);
  //     return errors;
  //   } else {
  //     return errors;
  //   }
  // }

  // convertDate(d) {
  //   const parts = d.split(" ");
  //   const months:any = {
  //     Jan: "01",
  //     Feb: "02",
  //     Mar: "03",
  //     Apr: "04",
  //     May: "05",
  //     Jun: "06",
  //     Jul: "07",
  //     Aug: "08",
  //     Sep: "09",
  //     Oct: "10",
  //     Nov: "11",
  //     Dec: "12",
  //   };
  //   return parts[3] + "-" + months:any[parts[1]] + "-" + parts[2];
  // }

}
