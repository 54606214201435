<div class="formContainer ">
  <div *ngIf="errors" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{ errorMessage }}
  </div>
  <div *ngIf="errorOffers && !disabeled" class="alert alert-danger alert-dismissible fade show" role="alert">
    {{ errorOffersMessage }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-lg-8 col-xxl-9 pl-0">
      <div class="row justify-content-between align-items-center">
        <div class="col-4 col-xxl-4 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-from-input (setOriginEvent)="setOrigin($event)" [inputValue]="origin"></app-from-input>
            </div>
          </div>
        </div>
        <div class="col-4 col-xxl-4 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-to-input (setDestinationEvent)="setDestination($event)" [inputValue]="destination"></app-to-input>
            </div>
          </div>
        </div>
        <div class="col-2 col-xxl-2 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-depart-input (openDesktopCalenderEvent)="openDesktopCalender($event)"
                [departureDate]="departureDate"></app-depart-input>
            </div>
          </div>
        </div>
        <div class="col-2 col-xxl-2 p-0">
          <div class="row">
            <div class="inputContainer">
              <app-return-input (openDesktopCalenderEvent)="openDesktopCalender($event)" [returnDate]="returnDate">
              </app-return-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 p-0 col-lg-4 col-xxl-3">
      <div class="row justify-content-between align-items-center">
        <div class="col-5 p-0 col-12-sm">
          <div class="row ml-15">
            <div class="inputContainer">
              <app-travellers-classes-input [className]="className"  [classTrip]="classTrip" (travellersDetails)="getTravellersDetails($event)"
                                            [adult]=adult [child]=child [seat]=seat [lap]=lap
                                            [totalTravellers]="totalTravellers" (openTravellersDialogEvent)="openTravellersDialog()">
              </app-travellers-classes-input>

            </div>
          </div>
        </div>
        <div class="col-7 p-0 hidden-sm">
          <div class="row">
            <div class="inputContainer submitBtn">
              <button (click)="submit(); searchClose()">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-between ml-5" [class]="disabeled ? '':'d-none'">
    <div class="w-100">
      <app-direct-flight-input [color]="color" [stops]="stops" (setDirectFlightEvent)="setDirectFlight($event)">
      </app-direct-flight-input>
    </div>
  </div>
</div>
