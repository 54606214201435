<div class="text-center mt-5">
  <h2 class="fao_mobile_title_large mt-2">Popular Destination</h2>
  <div
    style="background-color: #000000; height: 3px; width: 175px; margin: auto; margin-bottom: 10px;"
  ></div>
</div>
<!-- POPULAR DESTINATIONS -->

<br/>
<!-- Portfolio-->
<div id="portfolio" class="container">
  <div class="container p-0">
    <div class="row g-0">
      <ng-container *ngFor="let item of data | reverse ; let i = index">
      <div *ngIf="i < 6" class="col-lg-4 col-sm-6">
        <a class="portfolio-box" [routerLink]="['../popular-destinations/', item.city.name,item.slug]" title="Project Name">
          <img
            class="img-fluid "
            src="{{ 'https://api.flyallover.com/storage/' + item.image }}"
            alt="{{ item.title_tag }}"
            title="{{ item.title_tag }}"
          />
          <div class="portfolio-box-caption">
<!--            <div class="project-category text-white-50">Category</div>-->
            <div class="fao_mobile_title_large" style="color: #fff !important;">{{ item.city.name }}</div>
          </div>
        </a>
      </div>
      </ng-container>

      <div class="text-center mt-5">
        <button class="fao_des_btn_large" [routerLink]="['/popular-destinations/']" >
          View More
        </button>
      </div>
    </div>
  </div>
</div>
