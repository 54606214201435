import {Component, OnInit} from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from '../../services/meta-tags.service';
import {AppComponent} from "../../app.component";
import {SEOService} from "../../services/seo.service";

@Component({
  selector: 'app-help-terms',
  templateUrl: './help-terms.component.html',
  styleUrls: ['./help-terms.component.css']
})
export class HelpTermsComponent implements OnInit {

  constructor(
    private apis: HelpCenterServices,
    private router: Router,
    private seoService: SEOService
  ) {
    this.seoUpdate();
  }

  termsConditions = {};
  loading: boolean = true;
  searchText = '';
  backEndError = false;

  seoUpdate(title = null, description = null) {

    this.seoService.updateTitle('Flyallover Terms |Flyallover');
    this.seoService.updateDescription('Here you can find everything you should know about using flyallover. If you cannot find an answer to your question, we look forward to your message ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
  }

  ngOnInit(): void {
    this.apis.getTermsConditions().subscribe(
      (res: any) => {
        this.termsConditions = res.data;
        this.loading = false;
        this.seoUpdate(this.termsConditions['title'], this.termsConditions['faqs_meta']);
      },
      (err) => {
        this.loading = false;
        this.backEndError = true;
      }
    );
  }

  onSearch() {
    this.router.navigate(['search-results'], {
      queryParams: {
        text: this.searchText
      }
    });
  }

}
