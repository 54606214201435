<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center Font-Family"> Chargeback Policy</h1>
      
      <div class="frm">
        <input
          type="text"
          placeholder="How can we help you?"
          [(ngModel)]="searchText"
          (keyup.enter)="onSearch()"
        />
        <button (click)="onSearch()">
          <img
            title="Privacy and Policy"
            alt="search"
            src="./assets/images/help-center/search.svg"
            height="30"
          />
        </button>
      </div>
    </div>
  </div>
  <!-- start skeleton preloader -->
  <!-- start search-results -->
  <div class="help-center-body" *ngIf="!chargeBackPolicyExist">
    
    <div class="container">
      <div class="search-results">
        <div class="search-results-header Font-Family">
          <h4 class="Font-Family">
            <ngx-skeleton-loader
              count="1"
              [theme]="{ width: '200px' }"
            ></ngx-skeleton-loader>
          </h4>
        </div>

        <div class="search-results-body">
          <div class="item Font-Family">
            <ngx-skeleton-loader
              count="4"
              [theme]="{
                width: '100%',
                height: '15px',
                'margin-bottom': '0px'
              }"
            >
            </ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    
  </div> 
  <!-- end search-results -->
  <div class="help-center-body" >
    <!-- start search-results -->
    <div class="container">
      <div class="search-results">
        <div class="search-results-header Font-Family">
          <h6 class="fao_mobile_title_large mt-5"[innerHTML]="chargeBackPolicy.title_content" ></h6>
        <div>
          
        </div>

        <div class="search-results-body Font-Family">
          <div class="item">
            <p  class="Font-Family" [innerHTML]="chargeBackPolicy.content"></p>
          </div>
         
        </div>
       
      </div>
    </div>
    <!-- end search-results -->
  </div>
  </div>
</div>
<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
