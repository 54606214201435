<div class="details-traveler">
    <div class="formTitle">
        <div class="d-flex justify-content-between">
            <span>Contact Information</span>
        </div>
    </div>
    <div class="col-12 py-4" [formGroup]="form">
        <div class="row">
            <div class="col-4 position-relative">
                <div class="item">
                    <mat-form-field class="mb-2" appearance="fill">
                        <mat-label>Full Name</mat-label>
                        <input matInput formControlName="cardHolderName" type="text"
                            placeholder="Full Name" />
                    </mat-form-field>
                    <mat-error
                        *ngIf="f['cardHolderName'].errors?.required  && f['cardHolderName'].touched">
                        Full Name Is Required
                    </mat-error>
                    <!--           <mat-error *ngIf="form.controls.CreditCardstreetAddress.errors?.required"> Address Is Required </div>-->
                </div>
            </div>
            <div class="col-4 position-relative">
                <div class="d-flex justify-content-between align-items-baseline">
                    <mat-form-field class="mb-2" appearance="fill">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email" placeholder="name@email.com"
                            type="email" name="email" />
                    </mat-form-field>
                </div>
                <div *ngIf="f['email'].touched">
                    <mat-error class="mat-error" *ngIf="f['email'].errors?.required" class="mat-error ">
                        Email Is Required
                    </mat-error>
                    <mat-error class="mat-error" *ngIf="f['email'].errors?.email" class="mat-error ">
                        Email Is invalid
                    </mat-error>
                    <mat-error class="mat-error" *ngIf="f['email'].errors?.pattern" class="mat-error ">
                        This value should be a valid email.
                    </mat-error>
                </div>
            </div>
            <!-- <div class="col-4 mobile-number position-relative">
                <div class="phone-container">
                    <label>Phone Number</label>
                    <input matInput required class="phone" ng2TelInput placeholder="Phone Number"
                        type="text" name="phone" formControlName="phone" />
                </div>
                <mat-error *ngIf="f['phone'].errors?.required && f['phone'].touched" class="mat-error ">
                    Phone Number Is Required and Type Only Numbers
                </mat-error>
            </div> -->
            <div  class="col-4 position-relative">
                <div class="item">
                    <mat-form-field appearance="fill">
                        <mat-label>Phone Number</mat-label>
                        <input matInput required formControlName="phone"  placeholder="phone number" type="text"
                            name="phone" />
                    </mat-form-field>
                </div>
                <mat-error *ngIf="f['phone'].errors?.required && f['phone'].touched" class="mat-error ">
                    Phone Number Is Required and Type Only Numbers
                </mat-error>
            </div>
            <div class="text_blowe mb-3">
                <span>
                    By submitting this form, you agree to receive telephone calls and text messages at anytime,
                    which include hours outside of business hours(8:00 a.m. PST - 9:00 p.m PST). 
                    This is so that we may reach you as soon as possible in order to consult on your potential case .
                </span>
            </div>

            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item">
                    <mat-form-field class="mb-2" appearance="fill">
                        <mat-label>Country</mat-label>
                        <input matInput formControlName="CreditCardCountry" type="text"
                            placeholder="Select your Country" (click)="$event.target.select()"
                            [matAutocomplete]="country_id" required />
                    </mat-form-field>
                    <mat-autocomplete autoActiveFirstOption #country_id="matAutocomplete">
                        <mat-option *ngFor="let option of filteredCountries | async"
                            [value]="option.name">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="f['CreditCardCountry'].errors?.someProp">
                        {{f['CreditCardCountry'].errors?.someProp}}
                    </mat-error>
                    <mat-error
                        *ngIf="f['CreditCardCountry'].errors?.required  && f['CreditCardCountry'].touched">
                        Country Is Required
                    </mat-error>
                </div>
            </div>
            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item letters">
                    <mat-form-field class="mb-2" appearance="fill">
                        <mat-label>City</mat-label>
                        <input matInput formControlName="CreditCardCity" type="text"
                            placeholder="City" required />
                    </mat-form-field>
                    <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern"> Please Typr
                        Only Letters Only</mat-error>
                    <mat-error
                        *ngIf="f['CreditCardCity'].errors?.required  && f['CreditCardCity'].touched">
                        City Is Required
                    </mat-error>
                </div>
            </div>
            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item">
                    <mat-form-field class="mb-2" appearance="fill">
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="CreditCardstreetAddress" type="text"
                            placeholder="Address" required />
                    </mat-form-field>
                    <mat-error
                        *ngIf="f['CreditCardstreetAddress'].errors?.required  && f['CreditCardstreetAddress'].touched">
                        Address Is Required
                    </mat-error>
                    <!--           <mat-error *ngIf="form.controls.CreditCardstreetAddress.errors?.required"> Address Is Required </div>-->
                </div>
            </div>
            <div *ngIf="!payLater" class="col-3 position-relative">
                <div class="item">
                    <mat-form-field class="mb-2" appearance="fill">
                        <mat-label>Zip</mat-label>
                        <input matInput formControlName="CreditCardZip" type="text" placeholder="Zip" required />
                    </mat-form-field>
                </div>
                <mat-error *ngIf="form.controls.CreditCardZip.errors?.pattern"> Please Type Only
                    Numbers
                </mat-error>
                <mat-error *ngIf="f['CreditCardZip'].errors?.required  && f['CreditCardZip'].touched">
                    Zip Is Required
                </mat-error>
            </div>


        </div>
    </div>
</div>
<div class="details-traveler">
    <div class="section-details">
        <div class="form-details">
            <div class="header-content">
                <img alt="Additional-Info" title="Members" src="./assets/images/dashboard/Additional-Info.svg">
                <div class="item-header">
                    <h4>Passengers</h4>
                    <p>When you book your trip you can Automatically select your co-traveller And book faster. </p>
                </div>
            </div>
            <hr class="hr">
            <div class="travellers">
                <div class="col-12" *ngIf="!familyMembers">
                    <ngx-skeleton-loader  [theme]="{height: '300px'}"></ngx-skeleton-loader>
                </div>
                <div *ngFor="let member of familyMembers; let i = index" class="members d-block">
                    <div class="col-12">
                        <div class="row">
                            <ng-container *ngIf="noPassport && noPassport[member.id]">
                                <span>passport is required</span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row align-items-center">
                            <ng-container *ngIf="noPassport && noPassport[member.id]">
                                <span>passport is required</span>
                            </ng-container>
                            <div class="col-5 d-flex align-items-center">
                                <span class="col-2">
                                    <img *ngIf="member.image" alt="member-image" title="Members"
                                        style=" bosrder-radius: 50%;" src="{{member.image}}">
                                    <img *ngIf="!member.image" alt=""
                                        style=" bosrder-radius: 50%;"
                                        src="../../../../assets/newIcon/user-profile.svg" />
                                </span>
                                <span class="info info-name col-10">{{member.first_name.toUpperCase() + ' ' +
                                    member.middle_name.toUpperCase() + ' ' + member.last_name.toUpperCase()}}</span>
                            </div>
                            <div class="col-3">
                                <div class="info relation-info">{{member.family_relation.name}}</div>
                            </div>
                            <div class="col-2">
                                <span *ngIf="member.passport" class="info"
                                    style="font-size: 18px;">{{member.passport.passport_number}}</span>
                                <span *ngIf="!member.passport" class="info">----- -----</span>
                            </div>
                            <div class="col-2">
                                <div class="row">
                                    <button class="btn save-btn" *ngIf="member.passport" (click)="getPassportInfo(member.id, member.first_name.toUpperCase() + ' ' + member.middle_name.toUpperCase() + ' ' + member.last_name.toUpperCase(), member.family_relation.name );
                    passportInfo = !passportInfo">
                                        <img alt="edit" style="height: 35px" src="./assets/images/dashboard/edit.svg"
                                            title="Edit image">
                                    </button>
                                    <button class="btn save-btn" *ngIf="!member.passport"
                                        (click)="addPassport = !addPassport ; newPassport(member.id)">
                                        <img style="height: 35px" alt="add"
                                            src="./assets/images/dashboard/add%20(2).svg" title="Add Image">
                                    </button>
                                    <!-- <button class="btn save-btn"
                            style="margin-right: 10px" (click)="info(member.id); showTraveler = !showTraveler">
                      <img alt="information" title="Imformation Image" style="height: 35px"
                           src="./assets/images/dashboard/information.svg">
                    </button> -->
                                    <button *ngIf="!selectable" class="btn save-btn" (click)="openDialog(member.id)">
                                        <img alt="Icon-material-delete-forever" title="Delete"
                                            src="./assets/images/dashboard/Icon%20material-delete-forever.svg">
                                    </button>
                                    <ng-container *ngIf="selectable">
                                        <mat-checkbox [disableRipple]="true" [disabled]="member.passport == null"
                                            (change)="toggleCheck($event ,member)"></mat-checkbox>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-add-member *ngIf="addTraveler"></app-add-member>
        <app-edit-member *ngIf="showTraveler" [familyMembers]="familyMembers" [id]="memberId"
            (closeForm)="closeMember()">
        </app-edit-member>
        <app-add-passport *ngIf="addPassport" [id]="memberId" (closeForm)="closeAddPassport()"
            (reloadFamilyMember)="this.familyMember2.emit(); addPassport = false"></app-add-passport>
        <app-edit-passport *ngIf="passportInfo" [id]="memberId" [memberName]="memberName"
            [memberRelation]="memberRelation" [familyMembers]="familyMembers" (closeForm)="closePassport()"
            (reloadFamilyMember)="this.familyMember2.emit(); passportInfo = false">
        </app-edit-passport>
    </div>
    <div class="Add-traveller-btn p-2 d-flex justify-content-end">
        <a (click)="addMember()">
            <img alt="Group" src="./assets/images/dashboard/Group%2015504.svg" title="Add Member">
            <span>ADD MEMBER</span>
        </a>
    </div>
    <app-review-flight-add-member-traveller [traveller]="traveller" [searchRequest]="searchRequest"
    (MemberTraveller)="setMemberTraveller($event)" (reloadFamilyMember)="reloadMember()" (lenthFormMember)="lenthFormMember($event)" (removeMember)="removeMember($event)">
    </app-review-flight-add-member-traveller>
</div>
