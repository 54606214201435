import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit {
  title = 'My first AGM project';
  allLocation : any [] = [];
  @Input() longitude;
  @Input() Latitude;
  // lat = 51.678418;
  // lng = 7.809007;
  // slug;
  // activities;
  curicon="/assets/newIcon/pin.png"
  constructor( private apis: ApisService,  private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    // this.slug = this.activatedRoute.snapshot.params.slug;
    // this.getActivitiesApi()
  }
  // getActivitiesApi(): void {
  //   this.apis.getActivities(this.slug).subscribe((data: any) => {
  //     this.activities = data.data;
  //     console.log(this.activities)
  //     this.allLocation = this.activities.activities.locations
  //     console.log(this.allLocation)
  //   }, (err: any) => {
  //   });
  // }
}
