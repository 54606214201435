import {Component, Output, EventEmitter, OnInit,Inject ,Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SEOService} from '../../../services/seo.service';
import {MatDialog} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {FareRulesComponent} from '../../fare-rules/fare-rules.component';
// import{itinerary} from '../../itinerary/itinerary.component'



@Component({
  selector: 'app-review-flight-confirm-section',
  templateUrl: './review-flight-confirm-section.component.html',
  styleUrls: ['./review-flight-confirm-section.component.css']
})

export class ReviewFlightConfirmSectionComponent implements OnInit{
  checkbox1 = new FormControl('');
  checkbox2 = new FormControl('');
  checkbox3 = new FormControl('');
  checkbox4 = new FormControl('');
  @Output() checkboxesEmitter = new EventEmitter();
  @Inject(DOCUMENT) private document: Document;
  checkboxes: any = 0;
  dialogRef: any;
  fareRules;
  @Input() itineraries: any;


constructor(
  private matDialog: MatDialog,
  public dialog: MatDialog,
  private seoService: SEOService) {
}
  validateCheckboxes(ob: MatCheckboxChange) {
    if (ob.checked == true) {
        this.checkboxes++;
    }else {
      this.checkboxes--;
    }
    this.checkboxesEmitter.emit(this.checkboxes);
  }
  ngOnInit() {
this.seoUpdate();
}

  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  openFareRulesDialog(itinerary) {
    this.dialogRef = this.matDialog.open(FareRulesComponent, {
      panelClass: 'FareRules',
      maxHeight: '90vh',
      width: '50%',
    });
    this.dialogRef.componentInstance.itinerary = itinerary;
  }


}
