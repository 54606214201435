import {Component, OnInit, Inject, EventEmitter, Output, Input} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {HomeSpecificSearchEngineComponent} from '../home-specific-search-engine/home-specific-search-engine.component';
import {EngineSearchCardsComponent} from '../../engine-search-cards/engine-search-cards.component';
@Component({
  selector: 'app-airline-cards',
  templateUrl: './airline-cards.component.html',
  styleUrls: ['./airline-cards.component.css']

})
export class AirlineCardsComponent implements OnInit {
  egyptAir = false;
  getBlue = false;
  NOS = false;
  ITA = false;
  emirates = false;
  cardsdialog = '';
  @Input() myinputMsg: string;
  @Output() myOutput: EventEmitter<string> = new EventEmitter();
  outputMessage = 'I am child component.';
  constructor(public dialog: MatDialog) {
  }

  openDialog(event) {
    this.dialog.open(EngineSearchCardsComponent, {
      panelClass: 'custom-dialog',
      data: event,
    });

  }
  sendValues(e) {
    this.cardsdialog = e;
  }
  egyptAirDisplay() {
    this.egyptAir = !this.egyptAir;

  }
  getBlueDisplay() {
    this.getBlue = !this.getBlue;
  }
  alitaliaDisplay() {
    this.NOS = !this.NOS;
  }
  // alitaliaDisplay() {
  //   this.ITA = !this.ITA;
  // }
  emiratesDisplay() {
    this.emirates = !this.emirates;
  }
  ngOnInit(): void {
    if (typeof document === 'undefined') {
    } else {
      const panels = document.querySelectorAll('.panel');
      const engines = document.querySelectorAll('.engine');
      removeActiveClasses();
      panels.forEach(panel => {
        panel.addEventListener('mouseover', () => {
          removeActiveClasses();
          panel.classList.add('active');
        });
      });
      panels.forEach(panel => {
        panel.addEventListener('mouseout', () => {
          removeActiveClasses();
        });
      });

      function removeActiveClasses() {
        panels.forEach(panel => {
          panel.classList.remove('active');
          // let item = panel.childNodes[0].childNodes[1].childNodes[0] as HTMLLIElement;
          // item.classList.remove('show');
        });
      }
    }


  }
}
