<app-navbar></app-navbar>
<div class="container">
  <div class="row g-0 mainRow headline add">
    <div class="col-12 pt-5">
      <div class="flexDiv" style="justify-content: space-between">
        <div class="flexDiv">
          <h1 class="popular-city fao_mobile_title_large">&nbsp;{{ cityName }}/{{ countryName }}</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row g-0 mainRow">
    <div class="col-8 ">
      <img class="mainImg mr-5 pr-5" src="{{ imageUrl }}" height="540"
        alt="{{ cityName }}" title="{{ cityName }}" />
    </div>

    <div class="col-4 ">
      <div class="portfolio-info" *ngIf="data">
        <h3 class="Font-family">{{ cityName }} Detailes</h3>
        <ul>
          <li class="li-description Font-family"><strong class="st-description Font-family">Country </strong>: {{ countryName }}</li>
          <li class="li-description Font-family"><strong class="st-description Font-family"> City </strong>: {{ cityName }}</li>
          <li class="li-description Font-family"><strong class="st-description Font-family"> AirPort </strong>:<a class="airport-url"
              href="/airport-description/{{airportcode}}"> {{ airportName }} </a>
          </li>
        </ul>
      </div>
      <div class="portfolio-description mt-2 mx-2 readMore">
        <h2 class="Font-family">{{ cityName }} Info</h2>
        <app-read-more *ngIf="display && about" [text]="about" maxLength="30" class="aboutText Font-family"></app-read-more>
      </div>
      <!--       share new icon      -->
      <section>
        <div class="col-lg-1 pt-4  mt-4 order-lg-first ">
          <div class="sticky-content">
            <div class="social-icons social-icons-colored social-icons-vertical">
              <div class="icon-share ">
                <span class="social-label Font-family">SHARE:</span>
                <a class="social-icon image-item" (click)="cop()" target="_blank">
                  <img *ngIf="!copied" title="copy" class="copy" alt="copy" src="./assets/newIcon/copy-blue-icon.svg" />
                  <img *ngIf="copied" title="copied" class="copied" alt="copied" src="./assets/newIcon/copied.png" />
                </a>
                <a (click)="fb()" class="social-icon social-facebook" title="Facebook">
                  <img title="facebook icon" alt="facebook icon" src="assets/icons/287739_facebook_icon.svg"></a>
                <a (click)="tw()" class="social-icon social-twitter" title="Twitter">
                  <img title="twitter icon" alt="twitter icon" src="assets/icons/294709_circle_twitter_icon.svg"></a>
                <a (click)="in()" class="social-icon social-linkedin" title="Linkedin">
                  <img title="Linked In" alt="Linked In" src="assets/icons/317750_linkedin_icon.svg"></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="container">
      <div class="row">
        <div class="row g-0">
          <div class="col-7">
            <app-show-rate [reviewData]="reviewData"></app-show-rate>
            <app-add-reviews [keyId]="'popular_destinations_id'" (addReview)="setReview($event)"
              [cityId]="popularDestinationsId"></app-add-reviews>

          </div>

          <div class="col-5 offerSlide">
            <!--            ////////////////////////////////////////////////////////////////////////////////-->
            <app-point-slider-offers></app-point-slider-offers>

          </div>
        </div>
        <!-- show review -->
        <div class="row ">
          <div class="">
            <div class="mb-5 handel-pos ">
              <div class="row">
                <div class=""></div>
                <div class="d-flex justify-content-center  col-7 matgin-left  mt-4">
                  <button class="fao_Show_btn_large"(click)="showReviews()" *ngIf="!showRev">Show All
                    Reviews
                  </button>
                  <button class="fao_Show_btn_large" (click)="showReviews()" *ngIf="showRev">Hide All Reviews
                  </button>
                </div>
              </div>
              <owl-carousel-o *ngIf="showRev" [options]="customOptions" class="d-flex justify-content-center mt-5">
                <ng-container *ngFor='let item of reviewData.reviews ; let i = index'>
                  <ng-container>
                    <ng-template carouselSlide>
                      <app-share-reviews [data]="item" [image]="item.popular_destination?.image"></app-share-reviews>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
          <!--                  show video-->
          <section>
            <div class="my-5 mx-auto">
              <app-show-video></app-show-video>
            </div>
            <!--             End show video-->
          </section>

          <div class="container">
            <div class="container">
              <div class="row  mt-5">
                <div class="col-12" id="otherDest">
                  <h2 class=" my-3 text-center fao_mobile_title_large" *ngIf="relatedPopularDestination">Other Popular Destinations</h2>
                  
                </div>
                <div *ngIf="relatedPopularDestination" style="
          background-color: #000000;
          height: 5px;
          width: 100px;
          margin: auto;
          border-radius: 2px;
        " class="mb-5"></div>
              </div>
            </div>
            <div class="container">
              <div class="row" *ngIf="relatedPopularDestination">
                <ng-container *ngFor="let item of relatedPopularDestination; let i = index">
                  <div class="col col-12 col-md-6 col-lg-4 p-16px" *ngIf="i<3">
                    <div class="popular card-body">
                      <div class="things-img">
                        <a [routerLink]="['/popular-destinations/', item.city.name, item.slug]"
                          (click)="refresh(item.slug)">
                          <img src="{{ 'https://api.flyallover.com/storage/' + item.image }}" alt="{{ item.title_tag }}"
                            title="{{ item.title_tag }}" class="rounded w-100" height="250px" />
                          <div class="">
                            <h4 class="fao_mobile_title_med">
                              {{ item.city.name }}
                            </h4>
                          </div>
                          <div style="
                  background-color: #ddd4d4;
                  height: 1px;
                  width: 95%;
                  margin: auto;
                "></div>
                        </a>
                      </div>

                      <div>
                        <p class="fao_mobile_prgraph">
                          {{ item.about | slice:0:155 }} ....
                        </p>

                        <div class="center-class">
                          <button class=" mb-3 fao_des_btn_med"
                            [routerLink]="['/popular-destinations/', item.city.name, item.slug]"
                            (click)="refresh(item.slug)">
                            MORE DETAILS
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="container">                                      
            <div class="row">
              <div class="col mt-5 pt-4">
                <h2 id="nearst" class="text-center fao_mobile_title_large" *ngIf="relatedThingsData">Things To Do</h2>
                <div style="
            background-color: #000000;
            height: 5px;
            width: 100px;
            margin: auto;
            border-radius: 2px;
          " class="mb-5"></div>
              </div>
              <div class="row">
                <ng-container *ngFor="let item of relatedThingsData; let i = index">
                  <div class="col-4 " *ngIf="i < 3">
                    <div>
                      <div class="thing  box-side">
                        <a [routerLink]="['/things-to-do/', cityName, item.slug]">
                          <img src="{{
                          'https://api.flyallover.com/storage/' +
                            item.featured_image
                        }}" width="100%" height="250px" class="rounded p-3" alt="{{ item.title_tag }}"
                            title="{{ item.title_tag }}" />
                        </a>

                        <div class="col-12   text-center">
                          <a style="text-decoration: none; " [routerLink]="['/things-to-do/', cityName, item.slug]">
                            <h3 class="fao_mobile_title_med text-dark mb-2">
                              {{ item.name }}
                            </h3>
                          </a>
                        </div>
                        <div style="
                      background-color: #ddd4d4;
                      height: 1px;
                      width: 95%;
                      margin: auto;
                    "></div>

                        <p class="fao_mobile_prgraph">
                          {{ item.about | slice:0:155 }} ....
                        </p>

                        <div class="center_div  ">
                          <button class="fao_des_btn_med mb-4" [routerLink]="['/things-to-do/', item.city.name,item.slug]">
                            MORE DETAILS
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

            </div>
          </div>
          <div class="row g-0 mb-5">
            <div class="col-lg-8 col-md-12 col-sm-12 col">
              <div class="row g-0 mainRow">
                <div class="col-12"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
