import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ApisService } from '../../../services/apis.service';

@Component({
  selector: 'app-from-input',
  templateUrl: './from-input.component.html',
  styleUrls: ['./from-input.component.css']
})

export class FromInputComponent implements OnInit {
  @Output() setOriginEvent = new EventEmitter();
  @Input() inputValue: any;
  isFocused = false;
  airPorts: any;
  selectedFromDropDown = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apis: ApisService,
  ) {
  }

  ngOnInit(): void {
  }

  onFocus(): void {
    if (this.inputValue && this.inputValue.length > 0) {
      this.isFocused = true;
    } else {
      this.isFocused = !this.isFocused;
    }
    if (this.airPorts && this.airPorts.length > 0 && this.inputValue.length < 4) {
      this.inputValue = this.airPorts[0];
    }
  }

  selectAirport(event): void {
    console.log('airport',event);

    this.inputValue = event.item;
    this.selectedFromDropDown = true;
    this.setOriginEvent.emit(this.inputValue);
    this.document.querySelectorAll('[class*=mat-tab-]').forEach((item) => {
      item.classList.remove('fixOverFlow');
    });
  }

  flightsSearch = (text$: Observable<string>) => {
    return text$.pipe(
      switchMap(term => {
        console.log('term', term);
        if (term.length >= 1) {
          this.document.querySelectorAll('[class*=mat-tab-]').forEach((item) => {
            item.classList.add('fixOverFlow');
          });
          return this.apis.geoAutocomplete_v2(term, 'AIR').pipe(
            tap(term => {
              this.airPorts = term;
              console.log('shgshshshsh', this.airPorts);
              if (term[0] === ' ') {
                setTimeout(() => {
                  const button = this.document.querySelector('[id^=ngb-typeahead] .dropdown-item:first-of-type') as HTMLButtonElement;
                  button.innerHTML = `<span class="text-danger"> <i class="fas fa-exclamation-circle mr-1"></i> No results Found<span>`;
                  button.classList.add('no-results');
                  button.disabled = true;
                }, 0);
              }
            }),
            catchError(() => {
              return of([]);
            })
          );
        } else {
          return of([]);
        }
      })
    );
  }


}
