import {Component, OnInit, Inject, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ApisService} from 'src/app/services/apis.service';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-fare-rules',
  templateUrl: './fare-rules.component.html',
  styleUrls: ['./fare-rules.component.css']
})
export class FareRulesComponent implements OnInit {
  @Input() public itinerary;
  itineraries: any = [];
  airlinesRules: any;
  error = false;
  public fareRules;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private apis: ApisService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.fareRules = this.itinerary;
    if (this.fareRules) {
      this.rulesByAirline();
    }
    // console.log(this.itineraries);
    // console.log(this.fareRules.flights);

  }

  closeCalenderDialog(): void {
    this.matDialog.closeAll();
  }

  rulesByAirline() {
    const fareData = {
      destination_location: this.fareRules.flights[0][(this.fareRules.flights[0].length) - 1].ArrivalAirport,
      origin_location: this.fareRules.flights[0][0].DepartureAirport,
      marketing_carrier: this.fareRules.flights[0][0].MarketingAirline,
      fare_basis: this.fareRules.FareBasisCode
    };
    this.getFareRules(fareData);
  }

  getFareRules(fareData) {
    this.apis.fareRules(fareData).subscribe((res: any) => {
      this.airlinesRules = Object.values(res.data.Paragraph) ;
      this.error = false;
    }, err => {
      this.airlinesRules = null;
      this.error = true;
    });
  }
}
