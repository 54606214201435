import { Component, OnInit } from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import {EnginePointComponent} from "../engine-point/engine-point.component";
import {MatDialog} from '@angular/material/dialog';
import {RoomDetailsComponent} from "../../pages/room-details/room-details.component";

@Component({
  selector: 'app-hotel-room',
  templateUrl: './hotel-room.component.html',
  styleUrls: ['./hotel-room.component.css']
})
export class HotelRoomComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    autoplayTimeout: 7000,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },

  };

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  // openDialog() {
  //   this.dialog.open(RoomDetailsComponent, {
  //     panelClass: 'custom-dialog',
  //
  //   });
  // }
  openDialog() {
    const dialogRef = this.dialog.open(RoomDetailsComponent,{
      panelClass: 'custom-dialog',
      width: '60%',
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }


}
