<div class="row">
  <div class="col-12 p-0" *ngIf="offers != 'false'">
    <div class="hr"></div>
  </div>
  <div class="col-12 p-0">
    <div class="review-trip-section">
      <div class="traveller-details">
        <div class="traveller-detail">
          <div class="title d-flex justify-content-between">
            <div>
              <h5>Secure SSL Booking</h5>
            </div>
          </div>
          <div class="row form mx-0  justify-content-between align-items-center">
            <h3 class="w-auto">FlyAllOver has passed trustedSites SECURE's daily security scan</h3>
            <img class="w-auto" alt="trusted site" title="Secure SSL Booking" src="./assets/images/trusted.PNG">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
