import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/webApis/local-storage.service';
import { ApisService } from '../../services/apis.service';
// import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alternative-dates',
  templateUrl: './alternative-dates.component.html',
  styleUrls: ['./alternative-dates.component.css']
})

export class AlternativeDatesComponent implements OnInit {
  @Output() selectTagId = new EventEmitter();
  @Output() selectedObject = new EventEmitter();
  @Output() selectedItinerary = new EventEmitter();
  returnDatesArray = [];
  depDates: any;
  departDatesArray: any;
  formData: any;
  // depDatesValues: any;
  res: any;
  counter = 0;
  tripType: any;
  returnDate: any;
  alternativeDatesResponse: any;
  lowestPrice: any;
  lowestPriceVar: any;
  selectedAlternativeDateTo: any;
  selectedAlternativeDateAirCarrier: any;
  isHealthRequirementsOpened = false;
  isHealthRequirementsOpenedR = false;
  // dialogRef: any;
  return: any[] = [];
  depart: any[] = [];
  dates: object;
  singleItinerary: any;
  loadings = [
    { width: '200'},
    { width: '200'},
    { width: '200'},
    { width: '200'},
    { width: '200'},
    { width: '200x'},
    { width: '200'},
  ];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    // private dialogRef: MatDialogRef<AlternativeDatesComponent>,
    private router: Router,
    private localStorage: LocalStorageService,
    private apis: ApisService,
  ) {
  }

  selectedAlternativeDateHandler(): void {
    const className = '.depart-' + this.formData.departure_date + '.return-' + this.formData.return_date;
    const data = this.document.querySelector(className);
    if (data) {
      this.document.querySelector(className).classList.toggle('chosen-dates');
    }
  }

  ngOnInit(): void {
    // this.formData = JSON.parse(this.localStorage.getItem('searchRequestOffers'));
    this.getBestAlternativeDate();
    // console.log(this.formData)
    this.tripType = this.formData.trip_type;
    // console.log(this.tripType);
  }

  getBestAlternativeDate() {
    this.formData = JSON.parse(this.localStorage.getItem('searchRequestOffers'));
    // console.log('this.formData',this.formData);
    this.apis.searchAlternativeDate(this.formData).subscribe((data: any) => {
      this.alternativeDatesResponse = data.data.Itineraries;
      // console.log('alternativeDatesResponse => ', this.alternativeDatesResponse);

      // unique depart dates
      // .toISOString().slice(0, 10))
      // console.log(this.alternativeDatesResponse.map(itinerary => new Date(itinerary.flights[0][0].DepartureDateTime)));
      let uniqueDepartDates = this.alternativeDatesResponse.map(itinerary => new Date(itinerary.flights[0][0].DepartureDateTime).toString().slice(0, 10));
      this.departDatesArray = [...new Set(uniqueDepartDates)]

      //sort departure dates asc
      this.departDatesArray.sort(function(a,b){

        return (new Date(a).getTime()- new Date(b).getTime());
      });
      // console.log('depart before slice =>', this.departDatesArray);

      // console.log('depart =>', this.departDatesArray);
      // unique Return dates
      if(this.tripType=='Return'){
      let uniqueReturnDates = this.alternativeDatesResponse.map(itinerary => new Date(itinerary.flights[1][0].DepartureDateTime).toString().slice(0, 10));
      this.returnDatesArray = [...new Set(uniqueReturnDates)]
      //sort return dates asc
      this.returnDatesArray.sort(function(a,b){

        return (new Date(a).getTime()- new Date(b).getTime());
      });
      // console.log('return =>', this.returnDatesArray);
    }
      // filter response for add key and value of dates
      let datesKey = {};
      this.alternativeDatesResponse.forEach((itinerary, index) => {
        let filter = this.alternativeDatesResponse.filter((item) =>
          new Date(itinerary.flights[0][0].DepartureDateTime).toString().slice(0, 10) == new Date(item.flights[0][0].DepartureDateTime).toString().slice(0, 10)
        )
        datesKey[`${new Date(itinerary.flights[0][0].DepartureDateTime).toString().slice(0, 10)}`] = filter;
      })
      this.depDates = datesKey;


      // console.log('depDates ===>', this.depDates);
      this.lowestPrice = this.alternativeDatesResponse.reduce(
        (acc, loc) => acc.prices?.ItinTotalFare < loc.prices?.ItinTotalFare ? acc : loc
        )
        // console.log('lowest =>',this.lowestPrice);







      setTimeout(() => {
        this.initJs();
        this.selectedAlternativeDateHandler();
      }, 4000);
if(this.tripType=='Return'){
      for(let i = 0;i < this.departDatesArray.length;i++){
        // console.log('cccccccc',this.depDates[this.departDatesArray[i]].length);

        if(this.depDates[this.departDatesArray[i]].length != 7 ){
          let diffrence = 7 - this.depDates[this.departDatesArray[i]].length
          for(let x = 0;x < diffrence;x++){
            this.depDates[this.departDatesArray[i]].unshift(0)
          }
        }
      }
      // console.log('xxxx',this.depDates);

    }}
    , err => {
      console.log(err);
    });
  }

  initJs() {
    let __script = document.createElement('script');
    __script.src = './assets/js/alternative-dates.js';
    document.head.appendChild(__script);
  }

  getPrice(obj): any {
    return parseFloat(this.formatPrice(obj[Object.keys(obj)[0]].price));
  }

  getLowestPriceFormat(e) {
    return parseFloat(this.formatPrice(e));
  }

  formatPrice(price) {
    return price.replace(/\,/g, '');
  }

  selectDate(AL_TagID) {
    for (let i = 0; i < this.alternativeDatesResponse.length; i++) {
      if(this.alternativeDatesResponse[i].TagID == AL_TagID){
        this.singleItinerary= this.alternativeDatesResponse[i];
        this.selectedItinerary.emit(this.alternativeDatesResponse[i]);
      } 
      window.scrollTo({top: 900, behavior: 'smooth'})

    }
  }
  // close(): void {
  //   this.dialogRef.close();
  // }
  getAirCarrierCode(obj) {
    return obj[Object.keys(obj)[0]].carrierCode;
  }

  getAirCarrierName(obj) {
    return obj[Object.keys(obj)[0]].carrireName;
  }

  getReturnDateKey(obj) {
    return Object.keys(obj)[0];
  }

  getReturnDatesLength(obj) {
    var tds = [];
    tds.length = (7 - obj.length);
    return tds;
  }

  isValidReturnDate(date) {
    return true;
  }

  APi(formData) {
    this.apis.search(formData).subscribe((data: any) => {
      this.router.navigate(['flight-results'], { state: data.data });
    }, err => {
      console.log(err);
    });
  }

  scrollRight() {
    document.getElementById('table').scrollLeft += 100;
  }

  scrollLeft() {
    document.getElementById('table').scrollLeft -= 100;
  }
  returnFunction() {
  }
}
