import {Component, OnInit, Inject, SimpleChanges} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators, RequiredValidator} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {CookieService} from 'ngx-cookie-service';
import {SocialAuthService, GoogleLoginProvider} from 'angularx-social-login';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {ApisService} from 'src/app/services/apis.service';
import {DOCUMENT} from '@angular/common';
import {LocalStorageService} from 'src/app/services/webApis/local-storage.service';
import {AppComponent} from 'src/app/app.component';
import {SEOService} from '../../services/seo.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  formSubmitted: boolean = false;
  formValue :any

  // google recaptcha
  siteKey: string = '6Le5Oc8ZAAAAAG7mV010kqB5Js34u4RdXmbncokS';
  captchaKey: string;

  // form
  signUpForm: FormGroup;
  // validation messages
  validationMessages = {
    'firstName': [
      {type: 'required', message: 'First name is required'},
      // { type: 'pattern', message: 'First name cannot contains numbers' },
      {type: 'maxlength', message: 'First name cannot be more than 255 characters long'}
    ],
    'LastName': [
      {type: 'required', message: 'Last name is required'},
      {type: 'maxlength', message: 'Last name cannot be more than 255 characters long'}
    ],
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Enter a valid email'}
    ],
    'password': [
      {type: 'required', message: 'Password is required'},
      {type: 'minlength', message: 'Password must be at least 6 characters long'},
    ],
    'confirmPassword': [
      {type: 'required', message: 'Confirm password is required'},
      {type: 'minlength', message: 'Password must be at least 6 characters long'},
    ]
  }
  url: string;
  queryParams = {
    firstName: '',
    lastName: '',
    email: ''
  }

  // spinner
  roundSpinner: boolean = false;
  // backend error
  passwordMissMatch: boolean = false;
  backendError: boolean = false;
  backednErrorMsg: string = '';
  // checkbox1 = new FormControl('');
  // checkbox2 = new FormControl('');


  constructor(
    private seoService: SEOService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private centerApis: HelpCenterServices,
    private cookie: CookieService,
    private authService: SocialAuthService,
    private apis: ApisService,
    @Inject(DOCUMENT) private document: Document,
    private localStorage: LocalStorageService,
    private title: Title,
    private metaService: MetaTagsService
  ) {
    this.seoUpdate();
  }

  seoUpdate() {

    this.seoService.updateTitle('Sign up Account | Flyallover');
    this.seoService.updateDescription('Sign up for your account in Flyallover for booking The cheapest tickets with best airlines');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  ngOnInit(): void {
    this.url = this.router.url;
    this.createForm();
  }

  createForm() {
    this.signUpForm = this.fb.group({
      firstName: new FormControl('', Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z ]*$'),
        Validators.maxLength(255)
      ])),
      lastName: new FormControl('', Validators.compose([
        Validators.required,
        // Validators.pattern('^[a-zA-Z ]*$')
        Validators.maxLength(255)
      ])),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
      ])),
      checkbox1: new FormControl(false, Validators.required),
      // newsLetter: new FormControl(false)
    })
  }

  // to keep the form values if reload
  // navigateOnSamePage() {
  //   this.queryParams.firstName = this.signUpForm.value.firstName
  //   this.queryParams.email = this.signUpForm.value.email
  //   this.queryParams.lastName = this.signUpForm.value.lastName

  //   this.router.navigate([`${this.url}`], {
  //     queryParams: {
  //       firstName: this.signUpForm.value.firstName,
  //       email: this.signUpForm.value.email,
  //       lastName: this.signUpForm.value.lastName
  //     }
  //   })
  // }

  onSubmitSignUpForm(formValue) {
    this.formValue=formValue;
    this.backendError = false;
    if ((formValue.password !== formValue.confirmPassword)
      && this.signUpForm.controls.password.touched
      && this.signUpForm.controls.confirmPassword.touched
    ) {
      this.passwordMissMatch = true;
    } else {
      this.passwordMissMatch = false;
      let signupData = {
        email: formValue.email,
        password: formValue.password,
        confirm_password: formValue.confirmPassword,
        first_name: formValue.firstName,
        last_name: formValue.lastName,
        'g-recaptcha-response': this.captchaKey
      }
      this.roundSpinner = true;
      this.centerApis.register(signupData).subscribe(
        (res: any) => {
          if (formValue.newsLetter === true) {
            // this.apis.subscribeByEmail(formValue.email).subscribe(
            //   (res) => { },
            //   (err) => { }
            // )
          }
          this.roundSpinner = false;
          this.cookie.set('flyallover_token', res.data.access_token)
          this.router.navigate(['/profile/dashboard'])
        },
        (err: any) => {
          const errorDiv = this.document.querySelector('.logo');
          if (errorDiv) errorDiv.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
          this.roundSpinner = false;
          this.backednErrorMsg = err.error.error[Object.keys(err.error.error)[0]][0];
          this.backendError = true;
        }
      )
    }
  }


  resetError() {
    this.passwordMissMatch = false;
  }

  // socail login
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((socialUser) => {
        let user = {
          email: socialUser.email,
          first_name: socialUser.firstName,
          last_name: socialUser.lastName,
          provider: socialUser.provider,
          provider_id: socialUser.id
        }
        this.centerApis.socialLogin(user).subscribe(
          (res: any) => {
            this.cookie.set('flyallover_token', res.data.access_token)
            this.router.navigate(['/profile'])

          },
          (err) => {

          }
        );
      },
      (err) => {
      }
    );
  }

  resolved(responseEvent) {
    this.captchaKey = responseEvent;
  }
}
