<header id="side" class="header-home container">
  <app-navbar class="fixed" [ngClass]="{'mat-elevation-z5' : true, 'sticky' : isSticky}"></app-navbar>
  <div class="headers container">
    <h1 class="title-site" >Explore the world and book the cheapest flights with Fly All Over</h1>
  </div>
  <ng-container *ngIf="!isSticky">
      <div class="engine-width container">
          <app-engine [payLater]="paylater"  [color]="'#fff'" (offsetHeight)="getHeight()"></app-engine>
          <!-- <button class="PayLaterBtn" [routerLink]="['/pay-later']">PAY LATER</button> -->
          <!-- <div class="center-box">
            <button>PAY LATER</button>
          </div> -->
      </div>

  </ng-container>
</header>
<section class="video-header">
<ng-container>
      <app-home-video-main-section [images]="images" (finishedLoading)="hasLoadedMainSec = $event" [offsetHeight]="elementHeight">
      </app-home-video-main-section>
  </ng-container>
</section>
<div class="down-port">
<app-home-airlines-logos *ngIf="data" [airLines]="data"></app-home-airlines-logos>
<!-- <app-home-slider-offers></app-home-slider-offers> -->
<app-airline-cards></app-airline-cards>
</div>
<app-why-about-us></app-why-about-us>
<app-popular-dest></app-popular-dest>
<app-things-to-do></app-things-to-do>
 <!-- <app-home-hotels></app-home-hotels> -->
<!-- <app-important-links></app-important-links> -->
<app-footer ></app-footer>
