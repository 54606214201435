<section class="terms" >
  <p class="title billing-note">
    Please note: your credit/ debit Card may be billed in multiple charges totaling the final total
    price.
  </p>
  <div class="col-12">
    <!-- <span class="alert-primary d-block p-2 mb-1">Please check boxes below</span> -->
    <div class="form-check m-3 ">
      <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox1" type="checkbox" checked="" class="form-check-input checkbox-lg position"></mat-checkbox>
      <label class="form-check-label">I Agree that I have read and accepted the flyAllOver's
        <a [routerLink]="['/terms']" target="_blank">Terms & Conditions.</a>
      </label>
    </div>
    <div class="form-check m-3">
      <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox2" checked="" type="checkbox" class="form-check-input checkbox-lg position"></mat-checkbox>
      <label class="form-check-label">I Agree on the
        <a href="javascript:void(0)"  (click)="openFareRulesDialog(itineraries[0])">Fare Rules</a> of each one of the airlines of this trip.
      </label>

    </div>
    <div class="form-check m-3 ">
      <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox3" type="checkbox" checked="" class="form-check-input checkbox-lg position"></mat-checkbox>
      <label class="form-check-label">Please Acknowledge Our <a
        href="https://flyAllOver.com/cancellation-policy/" target="_blank"> Cancellation
        Policy.</a></label>
    </div>
  </div>
</section>

<div class="alert-body">
  <div class="alert-text col-12">
    <div class="form-check m-3 p-0">
      <div class="alert-Info">i</div>
      <label class="form-check-label">This itinerary is for an alternate date and nearby airport. Depart ({{itineraries[0].flights[0][0].DepartureDateTime | date: 'EEE, MMM d ' }}), Return ({{itineraries[0].flights[itineraries[0].flights.length - 1][0].ArrivalDateTime | date: 'EEE, MMM d' }}).</label>
    </div> 
  </div>
  <div class="alert-text col-12">
    <div class="form-check m-3 ">
      <mat-checkbox (change)="validateCheckboxes($event);" [formControl]="checkbox4" type="checkbox" checked="" class="form-check-input checkbox-lg position"></mat-checkbox>
      <label class="form-check-label" style="
      font-weight: 500 !important;
  ">Please check this box to indicate that you've confirmed the date(s) and airport(s) to be correct and that you're ready to book this flight.</label>
    </div>
  </div>
</div>