<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">
  <div class="help-center-header">
    <div class="container">
      <h1 class="text-center Font-Family"> Cancellation Policy</h1>
      
      <div class="frm">
        <!-- <input type="text" placeholder="How can we help you?" [(ngModel)]="searchText"
            (keyup.enter)="onSearch()" /> -->
            <input type="text" [(ngModel)]="searchTerm" placeholder="Enter search term" (input)="highlight()">
        
      
    </div>
  
  </div>
</div>
<div class="help-center-body" *ngIf="!cancellationPolicyExist">
    
  <div class="container">
    <div class="search-results">
      <div class="search-results-header Font-Family">
        <h4 class="Font-Family">
          <ngx-skeleton-loader
            count="1"
            [theme]="{ width: '200px' }"
          ></ngx-skeleton-loader>
        </h4>
      </div>

      <div class="search-results-body">
        <div class="item Font-Family">
          <ngx-skeleton-loader
            count="4"
            [theme]="{
              width: '100%',
              height: '15px',
              'margin-bottom': '0px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  
</div> 
<!-- end search-results -->
<div class="help-center-body" >
  <!-- start search-results -->
  <div class="container">
    <div class="search-results">
      <div class="search-results-header Font-Family">
        <h6 class=" fao_mobile_title_large mt-5"[innerHTML]="cancellationPolicy.title_content" ></h6>
      <div>
        
      </div>

      <div class="search-results-body Font-Family">
        <div class="item">
          <p  class="Font-Family" [innerHTML]="cancellationPolicy.content"></p>
        </div>
       
      </div>
     
    </div>
  </div>
  <!-- end search-results -->
</div>
</div>



</div>
<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->



<!-- <div class="search-results-body Font-Family">
  <div class="item">
    <p  class="Font-Family my-3"> At FLY ALL OVER Website, we strive to provide you with the best travel booking experience. We understand that plans may change, and sometimes cancellations are necessary. It is important to note that every airline has its own cancellation policy, which may vary from one another.</p>
 <p class="Font-Family mt-3" > By purchasing your tickets through our website, you are automatically agreeing to the cancellation policy set by the respective airline. We highly recommend reviewing the cancellation policy of the airline before making your booking.
</p>
 <h3 class="Font-Family mt-3"> Here are a few key points to consider regarding cancellations:</h3>
<div class="ml-3">
    <p class="Font-Family mt-3"> 1- Cancellation Fees: Each airline may have its own set of cancellation fees, which can vary depending on factors such as the type of ticket, the time of cancellation, and the fare rules. These fees are determined solely by the airline and are not under the control of Fly All Over website . </p>
    <p class="Font-Family mt-3"> 2- Refunds: The refund policy also varies by airline. Some airlines may offer a full refund, while others may provide a partial refund or issue travel credits for future use. It is important to familiarize yourself with the specific refund policy of the airline you are booking with.</p>
    <p class="Font-Family mt-3"> 3- Processing Time: The processing time for refunds can vary as well. While some airlines may process refunds promptly, others may take longer. We recommend contacting the airline directly for any refund-related inquiries.</p>

    <p class="Font-Family mt-3"> 4- Change of Travel Dates: If you need to change your travel dates, you may be subject to additional fees or fare differences imposed by the airline. It is essential to check the airline's policy regarding date changes before proceeding. </p>
    <p class="Font-Family mt-3"> 5- Communication: In the event of a cancellation or any changes to your travel plans, it is important to stay in touch with both [Your Online Travel Agency] and the airline. We will do our best to assist you and provide any necessary support, but please note that the final decision regarding cancellations and refunds rests with the airline.</p>
</div>
<p class="Font-Family mt-3"> We want to ensure that you have a seamless travel experience, and we are here to assist you with any questions or concerns you may have. However, it is crucial to understand that [Your Online Travel Agency] cannot alter or waive the cancellation policies set by the airlines.</p>
<p class="Font-Family mt-3"> Please feel free to contact our customer support team if you require any further clarification or assistance regarding your booking or the cancellation policies of specific airlines. </p>
<p class="Font-Family mt-3"> Thank you for choosing Fly All Over. We appreciate your business and look forward to serving you in the future.</p>
</div>
 
</div> -->