import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Input,
  PLATFORM_ID,
  AfterViewInit,
  OnChanges,
  DoCheck,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe, DOCUMENT, ViewportScroller } from "@angular/common";
import { ApisService } from "src/app/services/apis.service";
import { LocalStorageService } from "../../services/webApis/local-storage.service";
import { WindowService } from "src/app/services/webApis/window.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SEOService } from "../../services/seo.service";
import { MatDialog } from "@angular/material/dialog";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { LoadingFlightResultsDialogComponent } from "src/app/components/loading-flight-results-dialog/loading-flight-results-dialog.component";
import {DataService} from '../../services/data.service';

@Component({
  selector: "app-flights-results",
  templateUrl: "./flights-results.component.html",
  styleUrls: ["./flights-results.component.css"],
})
export class FlightsResultsComponent
  implements OnInit, OnChanges {
  searchRequest: any;
  noResult = false;
  page = 1;
  selectedIndex: any;
  pageSize = 10;
  collectionSize: number;
  countRequest = 10;
  loading = false;
  resultsArrived = false;
  selectRound=false;
  searchAlternative = true;
  errorSearch = false;
  searchResults: any;
  itineraries: any[] = [];
  flights: any;
  origin: any;
  destination: any;
  requestId: string;
  airlines: any;
  airlinesNumber: number;
  airlinesNameChecked: string[] = [];
  detailsSorts: any;
  openSortDetails: any = false;
  dialogLoadingRef: any;
  alternativeDatesResponse: any;
  display: boolean = true;
  metaTitle: any;
  metDescription: any;
  offerId: any;
  message: any = "cheap travel deals";
  cheapestFlights: any;
  response: any;
  tripType: any;
  lowestPrice: any;
  lowestflight: any;
  selectedItineraryTagId: any;
  selected_Itinerary:any[]  =[];
  selected_Flights:any[]  =[] ;
  select_Flights:any[]  =[] ;
  Bg_color = false;
  alternativeSend: any;
  viewAlternativeDates = false;
  interval: NodeJS.Timeout;
  randomMin: any;
  airlineImg: any = [];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private seoService: SEOService,
    private router: Router,
    private apis: ApisService,
    private dataFake: DataService,
    private localStorage: LocalStorageService,
    private viewportScroller: ViewportScroller,
    private modalService: NgbModal,
    private windowService: WindowService,
    private datePipe: DatePipe,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // this.seoUpdate();
    this.handleSeoData();

  }
  getArrayValues(index) {
    this.interval = setInterval(() => {
      if (index == this.dataFake.airlinesLog.length) {
        this.airlineImg = [];
        index = 0;
        return;
      }
      this.airlineImg.push(this.dataFake.airlinesLog[index]);
      index++;
    }, 500);
  }
  ngOnInit(): void {
    this.getArrayValues(0);
    this.searchRequest = this.route.snapshot.queryParams;
    this.tripType = this.searchRequest.trip_type
    this.dataHandler();
    this.getBestAlternativeDate(this.searchRequest);
    this.localStorage.removeItem('selectedItinerary')
  }
  // getArrayValues(index) {
  //   this.interval = setInterval(() => {
  //     if (index == this.dataFake.airlinesLog.length) {
  //       this.airlineImg = [];
  //       index = 0;
  //       return;
  //     }
  //     this.airlineImg.push(this.dataFake.airlinesLog[index]);
  //     index++;
  //   }, 500);
  // }
  applyFiltersEvent(searchRequest): void {
    this.searchRequest = searchRequest;
    this.callFiltersNewApi();
  }

  ngOnChanges(changes) { }
  BactToIti(){
    this.selectRound=false;
    window.location.reload()

  }

  changeCollectionNumberOfPages(event){
    this.collectionSize = event;
  }
  getSelecteditinerary(event){
    // console.log('selected ticket in itinerary',event);
      this.selectRound=event.selctedRound;
      this.selectedIndex=event.selectedIndex
      this.selectedItineraryTagId=event.selectedItineraryTagId
      }
      selectedItinerary(e){
        this.Bg_color=true ;
        this.selected_Itinerary.splice(0);
          this.selected_Itinerary.push(e);
          console.log( this.selected_Itinerary, ' this.selected_Itinerary.push(e;')

          // this.selected_Flights.splice(1);
          // this.selected_Flights.push(e.flights);
          this.selected_Flights= e.flights;
          console.log(  this.selected_Flights, '  this.selected_Flights.push(e.flights)')
      }

  getBestAlternativeDate(formData) {
    this.apis.searchAlternativeDate(formData).subscribe(
      (data: any) => {
        this.alternativeDatesResponse = data.data;
        if (this.noResult == true) {
          this.loading = false;
          // console.log('search results', this.alternativeDatesResponse);

          this.itineraries = this.alternativeDatesResponse.Itineraries
          this.flights = this.alternativeDatesResponse.flight
          this.lowestflight = this.alternativeDatesResponse.Itineraries.reduce(
            (acc, loc) => acc.prices?.ItinTotalFare < loc.prices?.ItinTotalFare ? acc : loc
          )
          this.lowestPrice = this.lowestflight.prices.ItinTotalFare
          // console.log('lowest =>', this.lowestPrice);
          this.origin = this.alternativeDatesResponse.origin;
          this.destination = this.alternativeDatesResponse.destination;
          this.requestId = this.alternativeDatesResponse.RequestID;
          this.collectionSize = this.alternativeDatesResponse.pages.total_item;
          if (this.requestId) {
            this.callBestFlightsApi();
          }

          // console.log('no result data', this.itineraries, this.flights);

        }
        // console.log(this.alternativeDatesResponse);

      },
      (err) => {
        this.searchAlternative = false
      }
    );
  }

  callFiltersNewApi() {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.windowService.nativeWindow.scrollTo(0, 0);
    // }
    this.loading = true;
    // this.noResult = false;
    this.apis.newFilters(this.searchRequest).subscribe(
      (data: any) => {
        this.noResult = false;
        this.loading = false;

        this.searchResults = data.data;
        this.itineraries = this.searchResults.Itineraries;
        this.flights = this.searchResults.flights;

        //  this.origin = this.searchResults.origin;
        // this.destination = this.searchResults.destination;
        this.requestId = this.searchResults.RequestID;
        this.page = 1;
        this.collectionSize = this.searchResults.pages.total_item;
      },
      (err) => {
        this.noResult = true;
      }
    );
  }

  closeFlightSort(): any {
    this.openSortDetails = false;
  }

  applyModifiedSearch(searchRequest): void {
    this.searchRequest = searchRequest;
    this.tripType = this.searchRequest.trip_type
    this.callSearchAPi();
    // this.viewportScroller.scrollToPosition([400, 400]);
    // this.dataHandler();
  }
  dataHandler(): void {
    this.searchRequest = this.route.snapshot.queryParams;
    if (this.searchRequest.return_date && this.searchRequest.departure_date) {
      this.metaTitle = this.searchRequest.origin + ' ' + 'TO' + ' ' + this.searchRequest.destination + ' ' + this.searchRequest.departure_date.substring(5,) + ' ' + 'To'
        + ' ' + this.searchRequest.return_date.substring(5,);
    }
    this.metDescription = 'FlyAllOver en providing you the cheapest flight tickets, hotels';
    this.seoUpdate(this.metaTitle, this.metDescription);
    if (!this.searchRequest) {
      this.router.navigate(['/']);
    }
    this.callSearchAPi();
  }

  handleSeoData(): void {
    this.searchRequest = this.route.snapshot.queryParams;
    //this.callSearchAPi();
    if (this.searchRequest.return_date && this.searchRequest.departure_date) {
      this.metaTitle =
        this.searchRequest.origin +
        " " +
        "TO" +
        " " +
        this.searchRequest.destination +
        " --- " +
        this.searchRequest.departure_date.substring(5) +
        " " +
        "To" +
        " " +
        this.searchRequest.return_date.substring(5);
    }
    this.metDescription =
      "FlyAllOver en providing you the cheapest flight tickets, hotels";
    this.seoUpdate(this.metaTitle, this.metDescription);
  }

  // dataHandler(): void {
  //   this.searchRequest = this.route.snapshot.queryParams;
  //   //this.callSearchAPi();
  //   // if (this.searchRequest.return_date && this.searchRequest.departure_date) {
  //   //   this.metaTitle =
  //   //     this.searchRequest.origin +
  //   //     " " +
  //   //     "TO" +
  //   //     " " +
  //   //     this.searchRequest.destination +
  //   //     " --- " +
  //   //     this.searchRequest.departure_date.substring(5) +
  //   //     " " +
  //   //     "To" +
  //   //     " " +
  //   //     this.searchRequest.return_date.substring(5);
  //   // }

  //   // this.metDescription =
  //   //   "FlyAllOver en providing you the cheapest flight tickets, hotels";
  //   // this.seoUpdate(this.metaTitle, this.metDescription);
  //   if (!this.searchRequest) {
  //     this.router.navigate(["/"]);
  //   }
  //   this.callSearchAPi();
  // }


  seoUpdate(
    title = "flights results",
    description = "flights results",
    image = "https://flyallover.com/assets/images/home/metaDataImageHome.png",
    url = "https://api.flyallover.com/storage/"
  ) {
    if (title != null) {
      this.seoService.updateTitle(title);
    }

    if (description != null) {
      this.seoService.updateDescription(description);
    }

    this.seoService.updateUrl();

    if (image != null) {
      this.seoService.updateImage(image);
    }

    // this.seoService.updateUrl();
    // this.seoService.updateTitle(title);
    // this.seoService.updateDescription(description);
    // this.seoService.updateImage(image);
  }

  // callSearchAPi(request = this.searchRequest) {
  //   // if (isPlatformBrowser(this.platformId)) {
  //   //   this.windowService.nativeWindow.scrollTo(0, 0);
  //   // }

  //   this.loading = true;
  //   this.noResult = false;
  //   this.apis.search(request).subscribe(
  //     (data: any) => {
  //       this.loading = false;
  //       this.searchResults = data.data;
  //       // console.log('search results', this.searchResults);

  //       this.itineraries = this.searchResults.Itineraries;
  //       this.flights = this.searchResults.flight;
  //       if (this.itineraries.length == 0) {
  //         this.noResult = true;
  //       }
  //       this.origin = this.searchResults.origin;
  //       this.destination = this.searchResults.destination;
  //       this.requestId = this.searchResults.RequestID;
  //       this.collectionSize = this.searchResults.pages.total_item;
  //       if (this.requestId) {
  //         this.callBestFlightsApi();
  //       }
  //     },
  //     (err) => {
  //       this.noResult = true;

  //       // this.getBestAlternativeDate(request);
  //       // this.itineraries=this.alternativeDatesResponse.itineraries;
  //       // console.log('alternative dates',this.alternativeDatesResponse);

  //       // this.flights=this.alternativeDatesResponse.flight
  //       // this.loading= false;
  //     }
  //   );
  // }
  callSearchAPi(request = this.searchRequest) {
    this.loading = true;
    this.noResult = false;
    this.apis.search(request).subscribe(
      (data: any) => {
        this.loading = false;
        this.searchResults = data.data;
        console.log('searchResults if', this.searchResults)
        if(this.searchResults.Itineraries.length < 10) {
          this.itineraries=this.searchResults.Itineraries;
          console.log('itineraries if', this.itineraries)
        }else {
          this.itineraries = this.searchResults.Itineraries.slice((0 ), ((this.page ) * (this.pageSize)));
          // this.itineraries = this.searchResults.Itineraries.slice((this.page * this.pageSize), ((this.page + 1) * (this.pageSize)));
          console.log('itineraries else', this.itineraries)
        }
        this.flights = this.searchResults.flight;
        this.origin = this.searchResults.origin;
        this.destination = this.searchResults.destination;
        this.requestId = this.searchResults.RequestID;
        this.collectionSize = this.searchResults.pages.total_item;
        if (this.requestId) {
          this.callBestFlightsApi();
        }
      },
      (err) => {
        console.log('error',err);
        
        this.noResult = true;
        if(this.searchAlternative == false){
          this.errorSearch = true;
          this.loading= false;
        }
      }
    );
  }

  offerCollection() {
    this.offerId = this.searchRequest.offer_id;
    const obj = {
      offer_id: this.offerId,
      request_id: this.requestId,
      iata: this.cheapestFlights.code,
      new_price: this.cheapestFlights.price,
    };
    this.updateOffers(obj);
  }

  filterAlternativeDates(data): void {
    const obj = {
      departure_date: data.departureDate,
      return_date: data.returnDate,
    };
    this.router
      .navigate([], {
        queryParams: obj,
        queryParamsHandling: "merge",
        skipLocationChange: true,
      })
      .then(() => {
        const request = JSON.parse(
          JSON.stringify(this.route.snapshot.queryParams)
        );
        this.callSearchAPi(request);
      });
  }

  setOpenSortDetails(): void {
    this.openSortDetails = !this.openSortDetails;
  }

  // getNewPage() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.windowService.nativeWindow.scrollTo(0, 0);
  //   }
  //   this.resultsArrived = false;
  //   this.loading = true;

  //   if(this.selectRound){
  //     this.apis
  //     .pagination(this.requestId, this.page, this.searchRequest.trip_type,"1")
  //     .subscribe(
  //       (data: any) => {
  //         this.loading = false;
  //         this.searchResults = data.data;
  //         this.itineraries = this.searchResults.Itineraries;
  //         this.flights = this.searchResults.flight;

  //         for (let i = 0; i < this.itineraries.length; i++) {


  //           if (this.selectedIndex == 0) {
  //             this.itineraries[i].flights.shift();
  //           }
  //           else {
  //             this.itineraries[i].flights.pop();
  //           }
  //         }
  //         this.resultsArrived = true;
  //         // this.viewFlightTrip = data.data.Itineraries;
  //         // //this.collectionTotalItemsCount =  data.data.pages.total_item;
  //         // this.collectionTotalItemsCount.emit(data.data.pages.total_item);
  //         // for (let i = 0; i < this.viewFlightTrip.length; i++) {
  //         //   if (this.selectedIndex == 0) {
  //         //     this.viewFlightTrip[i].flights.shift();
  //         //   }
  //         //   else {
  //         //     this.viewFlightTrip[i].flights.pop();
  //         //   }
  //         // }
  //       },
  //       (err) => {
  //         this.resultsArrived = false;
  //         this.loading = true;
  //       }
  //     );
  //   }else{
  //     this.apis
  //     .pagination(this.requestId, this.page, this.searchRequest.trip_type,"0")
  //     .subscribe(
  //       (data: any) => {
  //         this.loading = false;
  //         this.searchResults = data.data;
  //         this.itineraries = this.searchResults.Itineraries;
  //         this.flights = this.searchResults.flight;
  //         this.resultsArrived = true;
  //       },
  //       (err) => {
  //         this.resultsArrived = false;
  //         this.loading = true;
  //       }
  //     );
  //   }


  // }
  getNewPage() {
    if (isPlatformBrowser(this.platformId)) {
      this.windowService.nativeWindow.scrollTo(0, 0);
    }

    this.resultsArrived = false;
    this.loading = true;
    this.itineraries = this.searchResults.Itineraries.slice((this.page*this.pageSize),((this.page+1)*(this.pageSize)));
    console.log(this.searchResults);
    console.log(this.itineraries ,'this.itineraries ')
    this.resultsArrived = true;
    this.loading = false;


    // this.apis.pagination(this.requestId, this.page, this.searchResults.trip_type).subscribe((data: any) => {
    //   this.loading = false;
    //   this.searchResults = data.data;
    //   this.itineraries = this.searchResults.Itineraries;
    //   this.resultsArrived = true;
    // }, err => {
    //   this.resultsArrived = false;
    //   this.loading = true;
    // });
  }

  openLoadingDialog() {
    this.dialogLoadingRef = this.dialog.open(
      LoadingFlightResultsDialogComponent,
      {
        maxHeight: "70vh",
        width: "50vw",
        maxWidth: "100vw",
        data: { message: this.message },
      }
    );
    this.dialogLoadingRef.afterClosed().subscribe((result) => { });
  }

  createRange(range) {
    return Array(range);
  }

  setCheapest(e) {
    this.cheapestFlights = e["cheapest"][0];
  }

  select(TagID) {
    // console.log('select by tag id',TagID.tagId);

    if (TagID.tagId) {
      const oneResult = {
        tagId: TagID.tagId,
        firstIndexId: TagID.first_index_id,
        second_index_id: TagID.second_index_id,
        second_tag_id: TagID.second_tag_id,
        origin: this.origin,
        class: this.searchRequest.class,
        trip_type: this.searchRequest.trip_type,
        selectedBy: 'tagId',
        offers: this.searchRequest.offers,
        payLater: this.searchRequest.payLater,
        multiTicket:true
      }
      // console.log('if TagID.first_index_id');
      this.localStorage.setItem("selectedFlight", JSON.stringify(oneResult));

      // this.router.navigate(["review-flight"], { queryParams: oneResult });
      this.router.navigate([`/review-flight/${TagID?.tagId}/${this.searchRequest?.trip_type}`]);
    }
    else {
      const oneResult = {
        tagId: TagID,
        origin: this.origin,
        // destination: JSON.stringify(this.destination),
        // flights: JSON.stringify(this.flights),
        class: this.searchRequest.class,
        trip_type: this.searchRequest.trip_type,
        selectedBy: 'tagId',
        offers: this.searchRequest.offers,
        payLater: this.searchRequest.payLater,
        multiTicket:false
      };
      // console.log('if no TagID.first_index_id');
      const array =TagID.split('-');
      /*this.localStorage.setItem("selectedFlightIndex",array[1])
      this.localStorage.setItem("selectedFlightObject",JSON.stringify(this.flights[array[1]]))
      this.localStorage.setItem("selectedFlight", JSON.stringify(oneResult));
      this.router.navigate(["review-flight"], { queryParams: oneResult });*/
      this.router.navigate([`/review-flight/${TagID}/${this.searchRequest?.trip_type}`]);

    }
  }
  selectedObject(itinerary) {
    const oneResult = {
      tagId: itinerary.TagID,
      origin: this.origin,
      // destination: JSON.stringify(this.destination),
      // flights: JSON.stringify(this.flights),
      class: this.searchRequest.class,
      trip_type: this.searchRequest.trip_type,
      selectedBy: 'object',
      offers: this.searchRequest.offers,
      payLater: this.searchRequest.payLater
      // itinerary:itinerary
    };
    this.localStorage.setItem("selectedFlight", JSON.stringify(oneResult));
    this.localStorage.setItem("selectedItinerary", JSON.stringify([itinerary]));
    this.router.navigate(["review-flight"], { queryParams: oneResult });


  }
  updateOffers(data) {
    this.apis.updateOffers(data).subscribe(
      (res: any) => { },
      (err) => {
      }
    );
  }

  callBestFlightsApi() {
    this.apis.getSortValues(this.requestId).subscribe(
      (response: any) => {
        this.response = response.data;

        this.cheapestFlights = this.response["cheapest"][0];


        if (this.searchRequest.offer_id && this.cheapestFlights) {
          this.offerCollection();
        }
      },
      (err) => {
      }
    );
  }

  removeRedundantElements(array): string[] {
    const uniqueElementsArray = [];
    array.forEach((el) => {
      if (uniqueElementsArray.indexOf(el) === -1) {
        uniqueElementsArray.push(el);
      }
    });
    return uniqueElementsArray;
  }
  alternative(e){
    this.alternativeSend=e;
  }
  ShowMenu(event: any) {
    if (event == 'viewAlternativeDates') {

      this.viewAlternativeDates = !this.viewAlternativeDates

  }
  this.selected_Itinerary.splice(0);
}
}

