<section class="thanks-modal">
  <!-- <div class="logo text-center">
    <img src="/assets/images/logo3.png" alt="logo" title="FlyAllOver">
    <span> FlyAllOver </span>
  </div>
  <div class="d-flex justify-content-center success">
    <img src="/assets/images/success.svg" alt="logo" title="FlyAllOver">
  </div>
  <h4 class="text-center ticket-details-header">Your reservation has been confirmed! Please<a href="/dashboard">click here</a>
    to log into your
    account for important information regarding your booking.</h4>
  <div class="row">
    <div mat-dialog-actions class="col-12 justify-content-center">
      <button mat-flat-button color="primary" class="mat-dialog-button font-weight-normal" (click)="onNoClick()">CLOSE
      </button>
    </div>
  </div> -->
  <div class="position-relative" *ngIf="itinerary != null">
    <!--  <div class="booking-confirmed">
        <div class="booking-top">
          <div class="booking-header">
            <img src="assets/images/home/coparess/logo4.png" alt="logo">
            <p class="fao_mobile_title_small text-center">Reservation</p>
          </div>
          <div class="container ">
            <div class="row">
              <div class="transport" *ngIf="itinerary">
                <span class="Font-Family ">{{itinerary?.order.origin}}</span>
                <div class="line">
                  <span class="airplane Font-Family">&#9992;</span>
                </div>
                <span>{{itinerary?.order.destination}}</span>
              </div>
              <div class="container " *ngIf="itinerary">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                            <span class="Font-Family ">
                              <strong class="Font-Family Font-sizing">Order no</strong>
                            </span>
                      </div>
                      <div class="col-6">
                        <p class="Font-Family ">{{itinerary?.order.id}}</p>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-6">
                            <span class="Font-Family ">
                              <strong class="Font-Family Font-sizing">Class</strong>
                          </span>
                      </div>
                      <div class="col-6">
                            <span class="Font-Family ">
                              <p>{{flightDetails?.prices.FareClassName}}</p>
                          </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                            <span class="Font-Family ">
                              <strong class="Font-Family Font-sizing">Trip type</strong>
                          </span>
                      </div>
                      <div class="col-6">
                            <span class="Font-Family ">
                              <p class="Font-Family " *ngIf="itinerary?.order.trip_type.toLowerCase() == 'return'">Round trip</p>
                              <p class="Font-Family "
                                 *ngIf="itinerary?.order.trip_type.toLowerCase() != 'return'">{{itinerary?.order.trip_type}}</p>
                          </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                            <span class="Font-Family ">
                              <strong class="Font-Family Font-sizing">Total price</strong>
                          </span>
                      </div>
                      <div class="col-6">
                            <span class="Font-Family ">
                              <p class="Font-Family ">{{flightDetails?.prices.AdultPrice | currency}}</p>
                          </span>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-6">
                              <span class="Font-Family ">
                                <strong class="Font-Family ">Refundable</strong>
                            </span>
                      </div>
                      <div class="col-6">
                            <span class="Font-Family ">
                              <p class="Font-Family " *ngIf="flightDetails?.refundable">Yes</p>
                              <p class="Font-Family " *ngIf="!flightDetails?.refundable">No</p>
                          </span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                            <span>
                              <strong class="Font-Family ">Reservation Code</strong>
                          </span>
                      </div>
                      <div class="col-6">
                            <span class="Font-Family ">
                              <p class="Font-Family ">{{itinerary?.order.pnr}}</p>
                          </span>
                      </div>

                    </div>

                  </div>
                  &lt;!&ndash; <div class="col-6">

                  </div> &ndash;&gt;


                </div>
                <div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="space col-12">
        <span class="hr"></span>
      </div>

      <div class="booking-confirmed">
        <div class="booking-top">
          <div class="container">
            <div class="row">
              <div class="traveler-information text-center">
                <span class="fao_mobile_title_small text-center">Traveler information</span>
                <div class="traveler-data" *ngFor="let traveler of itinerary?.order.family_members">
                  <span class="master Font-Family text-center" *ngIf="traveler.family_member.gender=='Male'">MR</span>
                  <span class="master Font-Family text-center" *ngIf="traveler.family_member.gender=='Female'">MRS</span>
                  <div
                    class="traveler-name Font-Family text-center">{{traveler.family_member.last_name + ' ' + traveler.family_member.first_name}}</div>
                </div>
              </div>
            </div>
          </div>

          <div>
                <span class="Font-Family"><strong style="color: #FE521A;">Not Ready !!! </strong><br>
                Please contact our customer care team for any queries <br>and pay with in 24 hours</span></div>
          <div class="or Font-Family">
            <span class="title-traveler Font-Family">OR</span>
          </div>
          <div class="d-flex justify-content-center">
            <button class="fao_mobile_btn_small" type="button" mat-dialog-close="" (click)="payment()">book now</button>
          </div>

          &lt;!&ndash; <div class="btn-dashboard">
              <button type="button"  (click)="routToDashboard(itinerary?.order.id)" mat-dialog-close="" >Book now & pay within 24H</button>
          </div> &ndash;&gt;
        </div>
        <div class="booking-bottom">
          <div class="qr">
            &lt;!&ndash; <img src="assets\images\Group 8.png" alt="qr"> &ndash;&gt;
          </div>
          <div class="thanks">
            <p class="Font-Family">thanks for traveling with flyallover</p>
          </div>
        </div>
      </div>
      <div class="light-blue"></div>
      <div class="dark-blue"></div>-->
    <div class="body-ticket-part">
      <div class="row pnr_home Font-Family-bold design_1">
        <div class="col-3 p-0">
          <img src="../../../../assets/images/arrow/logo_sign_shadow.png" alt="logo" (click)="payment()"
            class="logo-ticket-pnr">
        </div>
        <ng-container *ngIf="ticketStep==0">
          <div class="col-9 header-body">

            <p class="Font-Family fontHandel "><span class="mr-1"
                *ngIf="flightData?.departure_date">{{flightData?.departure_date| date:"dd MMM yyy"}}</span> <img
                *ngIf="flightData?.return_date" src="assets/images/arrow/Polygon.png" class="mx-2"> <span class="mx-1"
                *ngIf="flightData?.return_date">{{flightData?.return_date| date:"dd MMM yyy"}}</span>
            </p>
            <p class=" Font-Family trip-part" *ngIf="flightData?.origin_city_object.city_name"><small
                class=" font-ticket-lv-3 col-4 p-0">TRIP FROM</small> <span
                class="text2Handel col-8 p-0">{{flightData?.origin_city_object.city_name}}
                , {{flightData?.origin_city_object.country_code}} </span>
            </p>
            <p class=" Font-Family trip-part" *ngIf="flightData?.destination_city_object.city_name"><small
                class=" font-ticket-lv-3 col-4 p-0">TRIP TO</small> <span
                class="text2Handel col-8 p-0">{{flightData?.destination_city_object.city_name}}
                , {{flightData?.destination_city_object.country_code}}</span>
            </p>
            <div class="hr-part"></div>
            <div class="">
              <p class="font-ticket-lv-3 Font-Family"><small> RESERVATION CODE</small></p>
              <span class="font-ticket-lv-1 Font-Family-bold" *ngIf="flightData.pnr">{{flightData.pnr}}</span>
              <div class="">
                <p class="font-ticket-lv-3 " *ngIf="itinerary.trip_class"> Cabin <small
                    class="text2Handel">{{itinerary.trip_class}} </small></p>
                <p class="font-ticket-lv-3 " *ngIf="flightData?.order_status"> Status<small class="text2Handel">{{
                    flightData?.order_status}} </small></p>
              </div>
              <div class="hr-part"></div>

            </div>

          </div>
        </ng-container>
        <ng-container class="" *ngFor="let bigFlight of flightDetails?.flights ;let i = index ">
          <ng-container class="" *ngFor="let Flight of bigFlight;let x = index;">
            <ng-container *ngIf="ticketStep==i * bigFlight.length + x+1">
              <div class="col-9 header-body">

                <div class=" Font-Family-bold ">
                  <span class="font-ticket-lv-2 Font-Family-bold" *ngIf="Flight.OperatingAirlineName">
                    {{Flight.OperatingAirlineName}} </span>
                  <p class="font4Handel_sec2" *ngIf="Flight.OperatingAirlineCode">{{Flight.OperatingAirlineCode}}
                    {{Flight.FlightNumber}} <br>
                    {{Flight.AircraftName}}</p>
                </div>
                <div class="">
                  <span class="font-ticket-lv-2 Font-Family-bold"> Duration: </span>
                  <p class="font4Handel_sec2 "> {{convertTime(Flight.ElapsedTime )}}</p>
                </div>
                <div class="" *ngIf="Flight.FlightLayoverTime ">
                  <span class="font-ticket-lv-2 Font-Family-bold"> Total Duration: </span>
                  <p class="font4Handel_sec2 "> {{convertTime(Flight.FlightLayoverTime + Flight.ElapsedTime +
                    bigFlight[x-1].ElapsedTime)}}</p>
                </div>
                <div class="hr-part"></div>

                <div class="">
                  <p class="font-ticket-lv-3 Font-Family" *ngIf="flightData.pnr"> RESERVATION CODE </p>
                  <span class="font-ticket-lv-1 Font-Family-bold">{{flightData.pnr}}</span>
                </div>
                <div class="hr-part"></div>


              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="ticketStep==0" class="ticket-step-1">
        <div class="up-title">PREPARED FOR</div>
        <div class="down-title pb-0 word-wrap">
          {{handelName(familyName?.last_name)+ ' / ' + handelName(familyName?.first_name)+ ' ' +handelName( familyName?.middle_name )+ ' '}}
          <span *ngIf="familyName?.gender=='Male'">MR</span>
          <span *ngIf="familyName?.gender!='Male'">MRS</span>
        </div>
        <div class="hr-part"></div>
        <div class="up-title" *ngIf="flightData?.departure_date">DEPARTURE :</div>
        <div class="down-title">{{flightData?.departure_date| date:"EEE dd MMM"}}</div>
        <div class="up-title" *ngIf="itinerary?.trip_type!='OneWay'">Return :</div>
        <div class="up-title" *ngIf="itinerary?.trip_type=='OneWay'">ARRIVAL :</div>
        <div class="down-title" *ngIf=" itinerary?.trip_type!='OneWay'&& itinerary?.return_date">{{itinerary?.return_date| date:"EEE dd MMM "}}</div>
        <div class="down-title" *ngIf="itinerary?.trip_type=='OneWay' && flightDetails.flights[0][flightDetails.flights[0].length - 1]?.ArrivalDateTime">
          {{flightDetails.flights[0][flightDetails.flights[0].length - 1]?.ArrivalDateTime | date:"EEE dd MMM "}}
        </div>
        <div class="mb-2 " *ngIf="flightDetails.BrandFeatures.length == 0"
             style="color: #585757; font-size: 20px; font-family: -apple-system, BlinkMacSystemFont, Arial, Helvetica Neue, Segoe UI, Roboto, sans-serif; font-weight: 400; word-wrap: break-word">
          <span *ngIf="flightDetails.BrandFeatures.BG"> Baggage Info</span>
        </div>
        <ul *ngFor="let item of flightDetails.BrandFeatures.BG; let i = index ">
          <li *ngIf="item.application =='F'">
            <div class="font-ticket-lv-2 Font-Family-bold">
              <p class="font4Handel_sec2">{{item.commercialName}} </p>
            </div>
          </li>
        </ul>
        <div class="down-title-p">Please verify flight times prior to departure</div>
        <div class="col-12 p-0 btn-ticket-port" [ngClass]="{'d-flex justify-content-center': ticketStep==0}">
          <div class="btn-ticket back" *ngIf="ticketStep !=ticketMaxStep" (click)="goToPreviousStep()"
            [ngClass]="{'d-none': ticketStep==0}">
            <span>Back</span>

          </div>
          <div class="btn-ticket back" *ngIf="ticketStep ==ticketMaxStep" (click)="openNote()" mat-dialog-close="">

            <span>Cancel</span>

          </div>
          <div class="btn-ticket next" (click)="goToNextStep()">
            <span>{{nextName}}</span>

          </div>
        </div>
      </ng-container>

      <div class="" *ngFor="let bigFlight of flightDetails?.flights ;let i = index ">
        <div class="" *ngFor="let Flight of bigFlight;let x = index;">
          <ng-container *ngIf="ticketStep==i * bigFlight.length + x+1 && bigFlight[x].FlightLayoverTime">
            <div class="dialog-stop">
              <span class="border-stop" *ngIf="Flight.FlightLayoverTime ">
                <small class="total-trip"><span style="color: #000;font-weight: bold;">Total Duration : </span>
                  {{convertTime(Flight.FlightLayoverTime + Flight.ElapsedTime + bigFlight[x-1].ElapsedTime)}}</small>
                <div style="padding-left: 14px;">
                  <div class="d-flex">
                    <span class="stops-title white-space-no-wrap mr-1 font">Stop At : </span>
                    <span class="font white-space-no-wrap">{{ Flight.DepartureAirportName }} <br>{{
                      convertTime(Flight.FlightLayoverTime) }}
                      Layover </span>
                  </div>
                  <div class="d-flex mt-2 position-relative" *ngIf="Flight.length-1>1">
                    <span class="line-dot"></span>
                    <p class="stops-title white-space-no-wrap mr-1 font">Stop At: </p>
                    <span class="font white-space-no-wrap">{{ Flight.DepartureAirportName }} <br> {{
                      convertTime(Flight.FlightLayoverTime) }}
                      Layover </span>
                  </div>
                </div>
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="ticketStep==i * bigFlight.length + x+1">
            <div class="d-flex justify-content-between mb-3">
              <div class="col-6 p-0 text-center">
                <span class="city-code">{{Flight.DepartureAirport}}<br />
                </span>
                <span class="city-title">{{Flight.DepartureAirportName}}
                </span>
              </div>
              <div class=" p-0 text-center"><span class="city-code">{{Flight.ArrivalAirport}}<br /></span>
                <span class="city-title">{{Flight.ArrivalAirportName}}</span>

              </div>
            </div>
            <div class="d-flex justify-content-between mb-3">
              <div class="col-6 p-0 date-ticket">
                <span class="date-ticket-text" *ngIf="Flight.DepartureDateTime"> Departing
                  At</span><br>{{Flight.DepartureDateTime| date :"hh:mm a"}}
                <br />{{Flight.DepartureDateTime| date :"EEE , MMM dd"}}
              </div>
              <div class="col-6 p-0 date-ticket">
                <span class="date-ticket-text" *ngIf="Flight.ArrivalDateTime"> Arriving
                  At</span><br>{{Flight.ArrivalDateTime| date :"hh:mm a"}}
                <br />{{Flight.ArrivalDateTime| date :"EEE ,MMM dd"}}
              </div>

            </div>
            <div class="d-flex justify-content-between mb-4">
              <div class="col-6 p-0 text-center">

                <span class="date-ticket-text"> Terminal:</span><br />

                <span class="city-title" *ngIf="Flight.DepartureAirportTerminal">TERMINAL
                  {{Flight.DepartureAirportTerminal}}</span> <span class="city-title-none"
                  *ngIf="!Flight.DepartureAirportTerminal">Contact airline for more information</span>
              </div>
              <div class="col-6 p-0 text-center"><span class="date-ticket-text"> Terminal:</span><br />
                <span class="city-title" *ngIf="Flight.ArrivalAirportTerminal">TERMINAL
                  {{Flight.ArrivalAirportTerminal}}</span>
                <span class="city-title-none" *ngIf="!Flight.ArrivalAirportTerminal">Contact airline for more
                  information</span>
              </div>
            </div>
            <div class="col-12 p-0 btn-ticket-port" [ngClass]="{'d-flex justify-content-center': ticketStep==0}">
              <div class="btn-ticket back" *ngIf="ticketStep !=ticketMaxStep" (click)="goToPreviousStep()"
                [ngClass]="{'d-none': ticketStep==0}">
                <span>Back</span>

              </div>
              <div class="btn-ticket back" *ngIf="ticketStep ==ticketMaxStep" (click)="openNote()" mat-dialog-close="">

                <span>Cancel</span>

              </div>
              <div class="btn-ticket next" (click)="goToNextStep()">
                <span *ngIf="!bigFlight[x+1]">{{nextName}}</span>
                <span *ngIf="bigFlight[x+1]&&bigFlight[x+1].FlightLayoverTime"><span class="go-to-stop">Go
                    TO</span>Stop</span>

              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!--
            <ng-container *ngIf="ticketStep==1.1 && itinerary.order?.itinerary_details.flights[0][0]" class="ticket-step-2">
              <div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div class="col-6 p-0 text-center">
                    <span class="city-code">{{.DepartureAirport}}<br/>
                    </span>
                  <span class="city-title">{{itinerary.order?.itinerary_details.flights[0][0].DepartureAirportName}}
                  </span>
                </div>
                <div class=" p-0 text-center"><span class="city-code">{{itinerary.order?.itinerary_details.flights[0][0].ArrivalAirport}}<br/></span>
                  <span class="city-title">{{itinerary.order?.itinerary_details.flights[0][0].ArrivalAirportName}}</span>

                </div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div class="col-6 p-0 date-ticket">
                   <span class="date-ticket-text" *ngIf="itinerary.order?.itinerary_details.flights[0][0].DepartureDateTime"> on</span><br>{{itinerary.order?.itinerary_details.flights[0][0].DepartureDateTime| date :"hh:mm a"}}
                  <br/>{{itinerary.order?.itinerary_details.flights[0][0].DepartureDateTime| date :"EEE , MMM dd"}}
                </div>
                <div class="col-6 p-0 date-ticket">
                  <span class="date-ticket-text" *ngIf="itinerary.order?.itinerary_details.flights[0][0].ArrivalDateTime"> on</span><br>{{itinerary.order?.itinerary_details.flights[0][0].ArrivalDateTime| date :"hh:mm a"}}
                  <br/>{{itinerary.order?.itinerary_details.flights[0][0].ArrivalDateTime| date :"EEE ,MMM dd"}}
                </div>

              </div>
              <div class="d-flex justify-content-between mb-4">
                <div class="col-6 p-0 text-center">

                     <span class="date-ticket-text"> at</span><br/>

                  <span
                    class="city-title" *ngIf="itinerary.order?.itinerary_details.flights[0][0].DepartureAirportTerminal">TERMINAL {{itinerary.order?.itinerary_details.flights[0][0].DepartureAirportTerminal}}</span>
                </div>
                <div class="col-6 p-0 text-center"><span class="date-ticket-text"> at</span><br/>
                  <span
                    class="city-title" *ngIf="itinerary.order?.itinerary_details.flights[0][0].ArrivalAirportTerminal">TERMINAL {{itinerary.order?.itinerary_details.flights[0][0].ArrivalAirportTerminal}}</span>
                </div>
              </div>

            </ng-container>
      -->

    </div>
  </div>


  <div *ngIf="itinerary == null">
    <div class="booking-error">
      <h1 class="Font-Family">Note !</h1>
      <p class="Font-Family">sorry we are not able to complete your booking please find details below</p>
      <p class="error" *ngIf="errorMessages">{{errorMessages}}</p>
      <p></p>
      <div *ngFor="let item of errorData">
        <ul class="error" *ngIf="errorData">
          <li class="Font-Family">{{item}}</li>
        </ul>
        <p class="error Font-Family" *ngIf="!errorData">There is a problem with your visa number</p>


        <!-- <p class="error" *ngIf="item" >{{item}}</p>
        <p class="error" *ngIf="!item" >'no messsages to show'</p> -->

      </div>
      <!-- <button (click)="f()">show error message</button> -->
      <div class=" my-3">
        <button class="fao_mobile_btn_small" type="button" mat-dialog-close="" (click)="goToHome()">back to
          home</button>
      </div>
    </div>
  </div>
</section>
<!-- <div class="row" *ngIf="errorMessage">
  <div class="col-8">
    <div class="mat-error">
      <span class="font-weight-bold">exception : </span>
      {{errorMessage.exception}}
    </div>
    <div class="mat-error mt-4">
      <span class="font-weight-bold">file : </span>
      {{errorMessage.file}}
    </div>
    <div class="mat-error mt-4">
      <span class="font-weight-bold">Message: </span>
      {{errorMessage.message}}
    </div>
  </div>
</div> &ndash;&gt;
-->