import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-flight-fare-rules',
  templateUrl: './review-flight-fare-rules.component.html',
  styleUrls: ['./review-flight-fare-rules.component.css']
})
export class ReviewFlightFareRulesComponent implements OnInit {
  @Input() itineraries: any;
  constructor() { }

  ngOnInit(): void {
  }
}
