<app-navbar></app-navbar>
<div class="container d-flex justify-content-center">
  <div class="card-body-payment">
    <div class="header-card-payment">
      <div class="tittle-card-payment text-center">Important Notice</div>
    </div>
    <div class="body-card-payment text-left">
      <div class="body-card-payment-text">
        <span>We wanted to inform you that your airline ticket has been issued and is now ready for review. We kindly request that you carefully go through all the information provided, including the names, airports, and dates, to ensure their accuracy.</span>
        <span>Additionally, we would like to draw your attention to the airline rules and conditions associated with your ticket.
              It is important to note that some tickets are non-refundable or may incur high charges for date changes in case of any mistakes. We strongly encourage you to thoroughly review these terms to avoid any unforeseen costs or inconveniences.
</span>
        <span class="col-12 pt-2">To ensure that any necessary changes or amendments can be made promptly, we kindly ask that you complete this review within 24 hours from the ticket issue. Please note that failure to do so may result in charges being applied.</span>
        <span>We would also like to clarify that Fly All Over is a ticketing company and not the airline itself. Therefore, we are unable to distribute any charges in the event of
            airline cancellations or rescheduling initiated by the airline.
            We highly recommend purchasing a travel insurance policy to safeguard against such situations.
      </span></div>
      <span>Fly All Over Customer Support Team
</span>
      <div class="btn-ticket-port"></div>
      <div class="btn-ticket" (click)="goToReview()">
        Review Your Ticket
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
