import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {ApisService} from '../../../services/apis.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RoundTripCalenderDesktopDialogComponent} from '../round-trip-calender-desktop-dialog/round-trip-calender-desktop-dialog.component';
import {HotelPassengersNumberComponent} from '../../hotel-passengers-number/hotel-passengers-number.component';
import {TravellersHotelsDesktopDialogComponent} from '../travellers-hotels-desktop-dialog/travellers-hotels-desktop-dialog.component';

@Component({
  selector: 'app-hotels-form',
  templateUrl: './hotels-form.component.html',
  styleUrls: ['./hotels-form.component.css',]
})

export class HotelsFormComponent implements OnInit {
  travellers: any = '1';
  className: any = 'Economy';
  departureDate: any;
  returnDate: any;
  origin: any;
  travellersObject: any;
  originCode: any;
  adult: any;
  child: any;
  seat: any;
  lap: any;
  destination: any;
  destinationCode: any;
  loading = false;
  errors = false;
  errorMessage = 'City , Date of Departing, and Returning are required';
  datesPrices: any;
  dialogRef: any;
  directFlight: any;
  stops: any;
  airline: any;
  hotelDest: any;
  childrenAges: any;
  rooms: any;
  @ViewChild(HotelPassengersNumberComponent)
  private HotelPassengersNumberComponent: HotelPassengersNumberComponent;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private apis: ApisService,
    private datePipe: DatePipe,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.loadOldSearch();
  }

  setHotelDest(hotelDest): void {
    this.hotelDest = hotelDest;
  }

  openDesktopCalender(e): void {
    this.dialogRef = this.matDialog.open(RoundTripCalenderDesktopDialogComponent, {
      panelClass: 'roundTripCalenderDesktopDialog',
      maxWidth: '60vw',
      data: {
        origin: this.origin ? this.origin.slice(0, 3) : undefined,
        destination: this.destination ? this.destination.slice(0, 3) : undefined,
        departDate: this.departureDate ? this.departureDate : undefined,
        returnDate: this.returnDate ? this.returnDate : undefined,
        type: e,
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.departureDate = data.departureDate;
        this.returnDate = data.returnDate;
      }
    });
  }

  initRequest() {
    // if (!this.travellersObject) {
    //   return {
    //     starte_date: this.datePipe.transform(this.departureDate, 'yyyy-MM-dd'),
    //     end_date: this.datePipe.transform(this.returnDate, 'yyyy-MM-dd'),
    //     adults: 1,
    //     children: 0,
    //     children_ages: this.childrenAges,
    //     travellers: this.travellers,
    //     radius: 20,
    //     sabre_rating_min: 3,
    //     sabre_rating_max: 5,
    //     type: 'city',
    //     country: this.hotelDest.split(',')[1].trim(),
    //     city_name: this.hotelDest.split(',')[0].trim(),
    //     rooms : this.rooms
    //   };
    // }
    return {
      starte_date: this.datePipe.transform(this.departureDate, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.returnDate, 'yyyy-MM-dd'),
      adults: this.travellersObject[0].currentValue,
      children: this.travellersObject[1].currentValue,
      children_ages: this.childrenAges,
      travellers: this.travellers,
      radius: 20,
      sabre_rating_min: 3,
      sabre_rating_max: 5,
      type: 'city',
      country: this.hotelDest.split(',')[1].trim(),
      city_name: this.hotelDest.split(',')[0].trim(),
      rooms : this.rooms
    };
  }

  openTravellersDesktopDialog(): void {
    this.dialogRef = this.matDialog.open(TravellersHotelsDesktopDialogComponent, {
      panelClass: 'travellersClassesDesktopDialog',
      width: '50vw'
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.travellersObject = data.travellersObject;
        if (data.childrenAges) {
          this.childrenAges = data.childrenAges.toString();
        }
        this.travellers = data.travellers;
        this.rooms = data.rooms[0].currentValue;
        // console.log(this.rooms);

      }
    });
  }

  loadOldSearch() {
    const searchRequest = JSON.parse(localStorage.getItem('searchRequest'));
    if (searchRequest) {
      this.travellers = searchRequest.travellers;
      this.className = searchRequest.class;
      this.departureDate = searchRequest.starte_date;
      this.returnDate = searchRequest.end_date;
      this.destination = searchRequest.city_name;
      this.destinationCode = searchRequest.country;
      this.hotelDest=this.destination+', '+this.destinationCode
      this.rooms=searchRequest.rooms
      if (searchRequest.children_ages) {
        this.childrenAges = searchRequest.children_ages;
      }
      if (searchRequest.adults) {
        this.adult = searchRequest.adults.currentValue;
      }
      if (searchRequest.children) {
        this.child = searchRequest.children.currentValue;
      }
    }
  }

  submit() {
    if (!this.travellers || !this.departureDate || !this.returnDate || !this.hotelDest) {
      this.errors = true;
    } else {
      localStorage.setItem('searchRequest', JSON.stringify(this.initRequest()));
      this.router.navigate(['hotel-results'], {queryParams: this.initRequest()});
    }
  }

}

