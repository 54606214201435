import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApisService} from 'src/app/services/apis.service';
import {SEOService} from '../../services/seo.service';
import {AppComponent} from '../../app.component';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {EnginePointComponent} from '../../components/engine-point/engine-point.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-more-popular-destinations',
  templateUrl: './more-popular-destinations.component.html',
  styleUrls: ['./more-popular-destinations.component.css']
})
export class MorePopularDestinationsComponent implements OnInit {

  page: number = 1;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    autoplayTimeout:3000,
    dots: false,
    navSpeed: 700,
    nav: true,
    navText: ['<i class="fas fa-arrow-left"></i>', '<i class="fas fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },

  };



  show = false;
  data: any = [];
  text: any;
  cityName: any;
  countryName: any;
  airportName: any;
  imageUrl: any;
  about: any;
  latitude: any;
  longitude: any;
  showEngine: any = false;
  destination: any = 'CAI';
  origin: any = 'JFK';
  stops: any = '0';
  airline: any = 'MS';
  tripType: any = 'Return';
  isvalid = true;
  val = 0;
  slug: any;
  // countryId: any = [];
  related: any = [];
  popItemData: any;
  thingsData: any = [];
  thingsallData: any = [];
  relatedThingsData: any = [];
  count: any;
  display = true;
  hostUrl: string;
  routerUrl: string;
  articleSlug: string;
  linkToBeShared: any;
  metaTitle: any;
  metaDescription: string;
  address: string;
  pageNumber: any;
  relatedPopularDestination: any;
  firstName: any;
  token: any;
  relatedThingsToDo: any;
  reviewData: any = [];
  showRev = false;
  countryId: any ;
  cityId: number;
  // tslint:disable-next-line:variable-name
  total_review: number;
  star5: number;
  star4: number;
  star3: number;
  star2: number;
  star1: number;
  averageReview: number;
  popularDestinationsId: any;
  airportcode: string;
  copied: boolean=false;

  constructor(
    private apis: ApisService,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private clipboard: Clipboard,
  ) {
    // this.seoUpdate();
    this.routerUrl = this.router.url;
    this.slug = this.activatedRoute.snapshot.paramMap.get('slug');
    this.getPopularDestination();
  }

  seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {

    this.seoService.updateUrl();
    // this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

    if (title != null) {
      this.seoService.updateTitle(title);
      // console.log(title);

    }
    if (description != null) {
      this.seoService.updateDescription(description);
      // console.log(description);

    }
    if (image != null) {
      this.seoService.updateImage(image);
      // console.log(image);

    }
  }

  ngOnInit(): void {
    // this.hostUrl = AppComponent.hostUrl;

    this.getthings();
    if (typeof document === 'undefined') {
      this.display = false;
    } else {
      window.scrollTo(0, 0);
      this.display = true;
    }
  }


  refresh(id) {
    this.slug = id;
    this.getPopularDestination();
    this.getthings();
    this.getReviews();
    if (typeof document !== 'undefined') {
      window.scrollTo(0, 0);
    }

  }
  getrelatedpopularDest(countryId){
    this.apis.relatedPopularDest(countryId).subscribe((data: any) => {
      this.relatedPopularDestination = data.data;
    });
  }

  getPopularDestination() {
    this.apis.popularDestDetails(this.slug).subscribe((data: any) => {
        this.data = data.data;
        this.imageUrl = 'https://api.flyallover.com/storage/'+this.data.image;
        this.metaTitle = this.data.title_tag;
        this.metaDescription = this.data.meta_description;
        this.seoUpdate(this.metaTitle, this.metaDescription, this.imageUrl);
        this.getrelatedpopularDest(this.countryId);
        this.popularDestinationsId = data.data.id;
        this.cityName = this.data.city.name;
        this.countryName = this.data.country.name;
        this.countryId = this.data.country.id;
        this.getReviews();
        this.airportName = this.data.airport.name;
        this.airportcode = this.data.airport.code;
        this.address = this.data.address;
        this.latitude = this.data.city.lat;
        this.longitude = this.data.city.lng;
        this.about = this.data.about;

        // console.log(this.data);

        this.getPopular();

      },
      err => {
        console.log(err);
      });
    this.getthings();
  }

  getthings() {
    this.apis.getThingsToDo().subscribe((data: any) => {
      this.thingsallData = data.data.data;
      this.relatedThingsData = this.thingsallData;
      for (let i = 0; i < this.thingsallData.length; i++) {
        this.count = this.thingsallData[i].country.name;
        // tslint:disable-next-line:triple-equals
        if (this.count == this.popItemData?.country.name) {
          this.relatedThingsData[i] = this.thingsallData[i];
        }
      }
    });
  }

  getPopular() {
    this.apis.popularDestPagination(this.pageNumber).subscribe((data: any) => {
        this.getRelatedPopularDestination(data.data.data);
      },
      err => {
        console.log(err);
      });
  }

  getRelatedPopularDestination(data) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      this.count = data[i].country.name;
      // tslint:disable-next-line:triple-equals
      if (this.count == this.countryName && data[i].slug != this.slug) {
        // tslint:disable-next-line:triple-equals
        if (this.related.includes(data[i]) === false && data[i].slug != this.slug) {
          this.related.push(data[i]);
        }
      }
    }
    if (this.related.length < 20) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.length; i++) {
        // tslint:disable-next-line:triple-equals
        if (this.related.includes(data[i]) === false && data[i].slug != this.slug) {
          this.related.push(data[i]);
        }
      }
    }
  }

  openDialog() {
    this.dialog.open(EnginePointComponent, {
      panelClass: 'custom-dialog',

    });
  }
  getReviews() {
    this.apis.getPopularReview(this.countryId).subscribe((data: any) => {
        this.reviewData = data.data;
      },
      err => {
        console.log(err);
      });
  }
  setReview(event:any){
    this.apis.addPopularDestinationReview(event).subscribe((data: any) => {
          this.data = data.data;
          this.getReviews();
        },
        err => {
          console.log(err);
        });
    // console.log('xxxxxxxxx =>' , event)
  }
  showReviews() {
    this.showRev = !this.showRev;
  }

  cop() {
    this.clipboard.copy('https://www.flyallover.com' + this.router.url)
    this.copied=!this.copied;
    setTimeout(()=>{
      this.copied = false;
    },1500)
  }

  fb() {
    let url = "www.google.com";

    var facebookWindow = window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
        "https://www.flyallover.com" +
        this.router.url,
        "facebook-popup",
        "height=350,width=600"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }

    return false;
  }

  tw() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://twitter.com/intent/tweet?url=' + 'https://www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

  in() {
    let url = 'www.google.com';
    var Window = window.open(
        'https://linkedin.com/shareArticle?mini=true&url=' + 'https://www.flyallover.com' + this.router.url,
        '-popUp',
        'width= 600px, height= 350px'
    );
    if (Window.focus) {
      Window.focus();
    }
  }

}

