<div class="details-traveler">
  <app-navbar></app-navbar>
  <div class="container section-deatails">
    <div class="row">
      <div class="col-xl-4 col-xxl-3">
        <app-dash-sidebar></app-dash-sidebar>
      </div>
      <div class="col-xl-8 col-xxl-9">
        <div class="header-image">
          <h1 class="text-center Font-family ">Welcome Back {{name}}</h1>
        </div>
        <div class="form-details" >
          <div class="header-content">
            <img src="../../../../assets/images/dashboard/Group%2015472.svg" title="Feedback" alt="Group">
            <div class="item-header">
              <h4 class=" Font-family ">Feedback</h4>
              <h4  class=" Font-family ">Word your Concerns & appreciations here and help us serve you better.</h4>
            </div>
          </div>
          <hr class="hr">
          <div *ngIf="feedbackSubmit" class="alert-success Font-family alert" role="alert">Thank You for sharing your experience and feedback with us we will make sure to take your feedback into consideration to try and improve your experience.</div>
          <form class="row passport-form form-selection" [formGroup]="feedbackForm" (submit)="submitFeedback()">
            <div class="col-4">
              <label class="star Font-family"> E-mail</label>
              <input type="text" formControlName="email" [ngModel]="email">
            </div>
            <div class="col-4">
              <label class="star Font-family"> Phone Number</label>
              <input type="text" formControlName="phoneNumber" [ngModel]="phoneNumber">
            </div>
            <div class="col-12 mb-4">
              <label class="star Font-family"> Write your feedback</label>
              <input style="height: 100px" type="text" formControlName="feedback" [ngModel]="feedback">
            </div>
            <div class="col-12 btns-group">
              <div class="">
                <button class="btn fao_des_btn_large Font-family" type="submit">Send Feedback</button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</div>
  <app-footer></app-footer>
