import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-home-video-main-section',
  templateUrl: './home-video-main-section.component.html',
  styleUrls: ['./home-video-main-section.component.css']
})

export class HomeVideoMainSectionComponent implements OnInit, AfterViewChecked {
  @Input() offsetHeight: any;
  @Input() images: any;
  @Output() finishedLoading: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    // document.getElementById('myBtn').style.height = this.offsetHeight;
  }

  ngAfterViewChecked() {
    // console.log(this.offsetHeight);
    // // you could also do this after a service call of some sort
    // this.finishedLoading.emit(false);
    // let elements = Array.from(document.getElementsByClassName('image-slider') as HTMLCollectionOf<HTMLElement>);
    // if (this.offsetHeight !== 'undefined') {
    //   elements.forEach((element) => {
    //     element.style.height = `${this.offsetHeight + 130}` + 'px';
    //   });
    // }
  }
}
