import {Component, OnInit , ElementRef, ViewChild } from '@angular/core';
import {HelpCenterServices} from 'src/app/services/help-center.service';
// import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
import {SEOService} from '../../services/seo.service';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-cancellation-policy',
  templateUrl: './cancellation-policy.component.html',
  styleUrls: ['./cancellation-policy.component.css']
})
export class CancellationPolicyComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  chargeBackPolicy: any;
  chargeBackPolicyExist: boolean;
  searchText = '';
  cancellationPolicy: any;
  cancellationPolicyExist: boolean;
  word: '';
  searchTerm: string = '';
  constructor(
    private seoService: SEOService,
    private apis: HelpCenterServices,
    private router: Router,
    private title: Title,
    private metaService: MetaTagsService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
  ) { }
 



 

  ngOnInit(): void {
    this.apis.getCancellationPolicy().subscribe(
      (res) => {
        this.cancellationPolicy = res['data'];
        this.cancellationPolicyExist = true;
      },
      (err) => {
        this.router.navigate(['/no-result']);
      }
    );
  }
  onSearch() {
    this.router.navigate(['search-results'], {
      queryParams: {
        text: this.searchText
      }
    })
  }
  highlight() {
    const contentText = this.cancellationPolicy.nativeElement.textContent;
    const regex = new RegExp(this.searchTerm, 'gi');
    const highlightedText =  contentText.replace(regex,match=> this.highlight) ;
    this.content.nativeElement.innerHTML = highlightedText;
  }
  seoUpdate() {

    this.seoService.updateTitle(' Cancellation Policy | flyallover ');
    this.seoService.updateDescription('Data protection regulations: Flyallover data protection regulations regulate our handling of personal user data and other information.');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }


}
