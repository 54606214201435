import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpCenterServices} from 'src/app/services/help-center.service';
import {CookieService} from 'ngx-cookie-service';
import {Title} from '@angular/platform-browser';
import {MetaTagsService} from 'src/app/services/meta-tags.service';
// social login
import {SocialAuthService} from 'angularx-social-login';
import {GoogleLoginProvider, SocialUser} from 'angularx-social-login';
import {ApisService} from 'src/app/services/apis.service';
import {LocalStorageService} from 'src/app/services/webApis/local-storage.service';
import {AppComponent} from 'src/app/app.component';
import {SEOService} from '../../services/seo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formSubmitted = false;
  backendError = false;
  @Input() closedLogin: any;
  cookieService: any;
  // tslint:disable-next-line:variable-name
  user_id: any;
  // form
  loginForm: FormGroup;
  // validation messages
  validationMessages = {
    'email': [
      {type: 'required', message: 'Email is required'},
      {type: 'pattern', message: 'Enter a valid email'}
    ],
    'password': [
      {type: 'required', message: 'Password is required'},
      {type: 'minlength', message: 'Password must be at least 6 characters long'},
      // { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, and one number' }

    ]
  }
  url: string;
  // social login
  user: SocialUser;
  loggedIn: boolean;

  // spinner
  roundSpinner: boolean = false;

  constructor(
    private seoService: SEOService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private centerApis: HelpCenterServices,
    private authService: SocialAuthService,
    private cookie: CookieService,
    private apis: ApisService,
    private localStorage: LocalStorageService,
    private title: Title,
    private metaService: MetaTagsService
  ) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.url = this.router.url;
    this.createForm();
  }

  seoUpdate() {

    this.seoService.updateTitle('login | flyallover ');
    this.seoService.updateDescription('Login for your account in Flyallover for booking The cheapest tickets with best airlines');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
      ])),
      checkbox1: new FormControl(false),
      newsLetter: new FormControl(false)
    })
  }

  onSubmitLoginForm(formValue) {
    this.roundSpinner = true;
    // console.log(formValue)
    this.centerApis.login(formValue).subscribe(
      (res: any) => {
        console.log(res)
        localStorage.setItem( 'user_id', res.data.user_id);
        localStorage.setItem( 'email_log', formValue.email);
        if (formValue.newsLetter === true) {
          // this.apis.subscribeByEmail(formValue.email).subscribe(
          //   (res) => { },
          //   (err) => { }
          // )
        }

        this.roundSpinner = false;
        this.cookie.set('flyallover_token', res.data.access_token);
        this.localStorage.setItem('flyallover_token', res.data.access_token);
        this.router.navigate(['/profile/dashboard']);
      },
      (err) => {
        this.roundSpinner = false;
        this.backendError = true;
        console.log(err)
      }
    );
  }
  //user-Id
  // setCookieUserId(){
  //   this.cookieService.set('user_id','user_id');
  //   console.log(this.user_id);
  // }

  // socail login
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((socialUser) => {
        let user = {

          email: socialUser.email,
          first_name: socialUser.firstName,
          last_name: socialUser.lastName,
          provider: socialUser.provider,
          provider_id: socialUser.id
        }
        this.centerApis.socialLogin(user).subscribe(
          (res: any) => {
            this.cookie.set('flyallover_token', res.data.access_token);
            this.router.navigate(['/profile/dashboard']);

          },
          (err) => {

          }
        )
      },
      (err) => {
      }
    )
  }

  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  // }

  setMetaTags() {
    const hostUrl = AppComponent.hostUrl;
    const metaData = {
      title: "Sign In | Flyallover",
      meta_data: {
        "og:title": "Sign In | Flyallover",
        "og:url": `${hostUrl}${this.url}`,
        "og:type": "website",
        "og:image": `${hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
        "og:description": "Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now",
        "twitter:card": "summary_large_image",
        "twitter:title": "Sign In | Flyallover",
        "twitter:image": `${hostUrl}/assets/images/FlyAllOver-blue-logo.png`,
        "twitter:description": "Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now",
        "twitter:url": `${hostUrl}${this.url}`,
        "title": "Sign In | Flyallover",
        "description": "Flyallover providing you the cheapest flight tickets, hotels, and Trips with more than 250 airlines available, don’t miss the chance and book online now",
      }
    }
    this.title.setTitle(metaData.title);
    this.metaService.modifyMetaTags(metaData.meta_data);
  }

  signOut(): void {
    this.authService.signOut();
  }
}
