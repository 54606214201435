import {Component, OnInit, NgModule} from '@angular/core';
import {ApisService} from 'src/app/services/apis.service';
import {SEOService} from '../../services/seo.service';
import {Router} from "@angular/router";
import {AppComponent} from "../../app.component";
import { WindowService } from 'src/app/services/webApis/window.service';


const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
  selector: 'app-all-popular-dest',
  templateUrl: './all-popular-dest.component.html',
  styleUrls: ['./all-popular-dest.component.css']
})
export class AllPopularDestComponent implements OnInit {
  data: any = [];
  name: '';
  showEngine: any = false;
  destination: any = 'CAI';
  origin: any = 'JFK';
  stops: any = '0';
  airline: any = 'MS';
  tripType: any = 'Return';
  val: number = 0;
  page: any = 1;
  pageSize = 10;
  collectionSize: number;
  metaTitle: any;
  metaDescription: string;
  metaImage: any;
  countries: any;
  country_id: any;
 
  // 
  constructor(private apis: ApisService,private router: Router, private seoService: SEOService,private _Window:WindowService) {
    this.seoUpdate();
  }

  ngOnInit(): void {
    this.getPopularDestination(0);
    this.selectCountries()
  }

  prevent(e): void {
    e.preventDefault();
  }

  seoUpdate(title = null, description = null, image = null, url = 'https://api.flyallover.com/storage/') {
    this.seoService.updateTitle(this.router.url);
    this.seoService.updateDescription(this.router.url);
    this.seoService.updateUrl();
    this.seoService.updateImage(AppComponent.hostUrl + '/assets/images/blog/header.png');

    if (title != null) {
      this.seoService.updateTitle(title);
    }
    if (description != null) {
      this.seoService.updateDescription(description);
    }
    if (image != null) {
      this.seoService.updateImage(image);
    }
  }

  // getPopularDestination(pageNumber: any) {
    // if (typeof document !== 'undefined') {
    //   this._Window.nativeWindow.scrollTo({top: 0, behavior: 'smooth'});
    // }
    // this.apis.popularDestPagination(pageNumber ).subscribe((data: any) => {
    //     this.data = data.data.data;
    //     this.collectionSize = data.data.total;
    //     this.pageSize = data.data.per_page;
    //     this.page = +pageNumber + 1;
    //     if (typeof document !== 'undefined') {
    //       localStorage.setItem('pageNumber', this.page);
    //     }
    //     this.metaDescription= this.data[0].meta_description;
    //     this.metaTitle= this.data[0].name;
    //     this.metaImage= this.data[0].image
    //     this.seoUpdate(this.metaTitle, this.metaDescription, this.metaImage);

    //   },
    //   err => {
    //     console.log(err);
    //   });
    
  // }
  getPopularDestination(pageNumber: any) {
    if (typeof document !== 'undefined') {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    this.apis.PaginationPopularDest(pageNumber).subscribe((data: any) => {
        this.data = data.data.data;
        console.log(this.data ,'this.data');
        this.collectionSize = data.data.total;
        this.pageSize = data.data.per_page;
        this.page = +pageNumber + 1;
        if (typeof document !== 'undefined') {
          localStorage.setItem('pageNumber', this.page);
        }
        this.metaDescription= this.data[0].meta_description;
          this.metaTitle= this.data[0].name;
          this.metaImage= this.data[0].image
          this.seoUpdate(this.metaTitle, this.metaDescription, this.metaImage);
    },
      err => {
        console.log(err);
      });
  }

  formatInput(input: HTMLInputElement) {
    input.value = input.value.replace(FILTER_PAG_REGEX, '');
  }
  search_Popular() {
    this.apis.searchPopularDestinations(  this.country_id ,this.name).subscribe((data: any) => {

            this.data = data.data.data;
        },
        err => {
            console.log(err);
        });
}
selectCountries() {
  this.apis.getCountries().subscribe(
      (data: any) => {
          this.countries = data;
          for (let i = 0; i < this.data.length; i++) {
              if (this.name == this.data[i].name) {
                  this.country_id = this.data[i].id;
              }
              // console.log('hghjgj', this.country_id)
          }
          this.search_Popular();
          console.log(this.data)
      },
      (err) => {
          console.log(err);
      }
  );
}


}
