import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hotels-filter',
  templateUrl: './hotels-filter.component.html',
  styleUrls: ['./hotels-filter.component.css']
})
export class HotelsFilterComponent implements OnInit {
  priceRange: any = [0, 100];
   PriceSliderValue: number;
   PriceSliderHighValue: number;
   filterValues: any;
   PriceSliderOptions: { draggableRange: boolean; step: number; ceil: number; floor: number; translate: (value: number) => string };

  constructor() { }

  ngOnInit(): void {
  }
  changeFilterPrice(value: any) {
    console.log(value);
    this.priceRange = [value[0], value[1]];
  }
  initPricesSlider(data = this.filterValues.prices) {
    this.PriceSliderValue = Math.floor(data?.min);
    this.PriceSliderHighValue = Math.floor(data?.max);
    const t = this.filterValues.arrivalTime.max;
    const r = Number(t?.split(':')[0]) * 60 + Number(t?.split(':')[1]) * 1000;
    this.PriceSliderOptions = {
      floor: this.PriceSliderValue,
      ceil: this.PriceSliderHighValue,
      step: 1,
      draggableRange: true,
      translate: (value: number): string => {
        return ' $ ' + value;
      }
    };
  }

}
