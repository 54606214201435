import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-loading-fake-flight',
  templateUrl: './loading-fake-flight.component.html',
  styleUrls: ['./loading-fake-flight.component.scss']
})
export class LoadingFakeFlightComponent implements OnInit {
  itinerariesFackData: any [] = [];
  searchRequest: any;
  val = 0;
  val2 = 1;
  flights: any;
  itineraries: any;
  constructor(
    private dataFake: DataService,
    private route: ActivatedRoute,
  ) {
    this.searchRequest = this.route.snapshot.queryParams;
  }
  get(e): boolean{
    if (e == 'Aus-Air'){
      return true;
    }
    return false;
  }
  ngOnInit(): void {
    this.counterData(0);
    // console.log( this.itineraries[0]?.passengerDetails[0].Tax);



  }
  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = ` ${Math.floor(minutes / 60)}h`;
    }
    if (Math.floor(minutes % 60) > 0) {
      convertedMinutes = ` ${minutes % 60}m`;
    }
    return convertedHours + ' ' + convertedMinutes;
  }
    counterData(count){
      let refreshIntervalId = setInterval(() => {
        if (count == this.dataFake.data.length ) {
          this.itinerariesFackData = [];
          this.flights =this.itinerariesFackData
          this.itineraries =this.itinerariesFackData
          clearInterval(refreshIntervalId);
          return;
        }
        if (this.searchRequest?.offers == 'false'){
          this.itinerariesFackData.splice(0 , 0 , this.dataFake.dataFakeEgyptair[count]);
        }else{
          this.itinerariesFackData.splice(0 , 0 , this.dataFake.data[count]);
        }
        // this.itinerariesFackData.reverse();
        count++;
      }, 500);
    }
}
