import {Component, EventEmitter, Input, OnInit, Output, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.scss']
})

export class EngineComponent implements OnInit {
  @Output() modifiedSearchRequestEvent = new EventEmitter();
  @Output() offsetHeight = new EventEmitter();
  @Input() searchRequest;
  @Input() airLine;
  @Input() color;
  @Input() disabeled :Boolean = true;
  @Input() payLater = true;
  classTrip  = true ;
  className: any = 'Economy';
  codeAirLine = '';
  private dialogRef = null;
  airline: any;
  tab = 1;
  // public dialogRef: MatDialogRef<EngineComponent>

  constructor(
    // private injector: Injector,

    // public dialogRef: MatDialogRef<EngineComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dialogRef = this.injector.get(MatDialogRef, null);
    // this.airline=this.airLine
  }

  close(): void {
    // this.dialogRef?.close();

  }

  ngOnInit(): void {
   this.payLater =true
    // console.log('engine',this.airLine);

  }

  setOffsetHeight() {
    this.offsetHeight.emit();
  }

  applyModificationEvent(searchRequest): void {

    this.searchRequest = searchRequest;
    this.modifiedSearchRequestEvent.emit(this.searchRequest);
    // console.log(this.searchRequest);

  }
  isActive(tabId: any): boolean {
    return this.tab === tabId;
  }

  openTab(tabId: any): void {
    this.tab = tabId;
  }
  setClassName(){
    console.log('class name' , this.className);
  }

}


// @Inject(MAT_DIALOG_DATA) public data: any
