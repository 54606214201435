import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HotelsApisService {
  hotelInfo :any;
  domain: string = environment.domain;
  header = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');


  constructor(private http: HttpClient) { }

  searchHotels(searchData) {
    return this.http.get(`${this.domain}/api/hotels/hotelavail`, { params: searchData, headers: this.header });
  }

  newResultPage(requestId: string, page: number) {
    return this.http.get(`${this.domain}/api/hotels/paginate-results?requestID=${requestId}&pageNo=${page}`)
  }
  allVacations() {
    return this.http.post(`${this.domain}/api/get-all-vacations`,{header:this.header})
  }
  vacationDetails(id :number) {
    return this.http.post(`${this.domain}/api/get-vacation-details`,{header:this.header,hotel_vacation_id:id})
  }
  getVacationsAminities(id:number){
    return this.http.post(`${this.domain}/api/get-vacation-amenity`,{header:this.header,f_a_o_hotel_branch_id:id})
  }
  hotelDetails(hotelQuery) {
    return this.http.get(`${this.domain}/api/hotels/hoteldetails`, { params: hotelQuery, headers: this.header });
  }
  hotelDetailsMetaData(HotelCode) {
    return this.http.get(`${this.domain}/api/hotels/hotel-meta-tags/${HotelCode}`)
  }
  filters() {
    return this.http.get(`${this.domain}/api/hotels/filter-code`);
  }

  checkRoomPrice(rateKey) {
    return this.http.post(`${this.domain}/api/hotels/pricecheck`, { rate_key: rateKey });
  }

  hotelsData() {
    return this.http.post(`${this.domain}/api/search-fao-hotel`,{header:this.header});
  }
}
