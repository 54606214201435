import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {DOCUMENT} from '@angular/common';
import {HelpCenterServices} from '../../../services/help-center.service';
import {LocalStorageService} from '../../../services/webApis/local-storage.service';
import {Router} from '@angular/router';
import {CovidPopupComponent} from '../../../components/covid-popup/covid-popup.component';
import {MatDialog} from '@angular/material/dialog';
import { FlightDetailsDropDownComponent } from 'src/app/components/flight-details-drop-down/flight-details-drop-down.component';
import { PnrInDashboardComponent } from 'src/app/components/review-flight/pnr-in-dashboard/pnr-in-dashboard.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  details: boolean = false;
  itinerary: any;
  token: any;
  flights: any;
  flightData: any;
  closedStatus = true;
  travellersNum: number;
  recommendedPlaces: any;
  select = true;
  elemNumber;
  addOne:boolean=false
  addTwo:boolean=false
  carouselOptions: OwlOptions = {
    loop: false,
    navText: ['<i class=\'arrow arrowNext fa fa-chevron-left\'></i>', '<i class=\'arrow arrowPrev fa fa-chevron-right\'></i>'],
    autoplay: true,
    dots: false,
    navSpeed: 700,
    nav: true,
    responsive: {
      0: {
        items: 3
      }
    },

  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apis: ApisService,
    private cookie: CookieService,
    private centerApis: HelpCenterServices,
    private localStorage: LocalStorageService,
    private router: Router,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    // this.openDialog();
    this.token = this.localStorage.getItem('flyallover_token');
    if (!this.token) {
      this.router.navigate(['/']);
    }
    this.apis.upcomingBooking(this.token).subscribe((data: any) => {
        this.flights = data.data;
        // console.log(data);
        this.getdataLegnth(this.flights.length);
        this.itinerary = JSON.parse(this.flights.itinerary_details);
        this.flightDetails(this.flights[0]?.id);

      },
      err => {
        console.log(err);
      });
    this.recommended();
  }
  openDialog() {
    const dialogRef = this.dialog.open(CovidPopupComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  ngAfterViewInit() {
    this.ref.detectChanges();
  }
  getdataLegnth(legnth){
  if(legnth%3==1){
    this.addTwo=true;
  }
  else if(legnth%3==2){
    this.addOne=true
  }
  }
 openFlighetDetailsDailog(details){
  const dialogRef=this.dialog.open(PnrInDashboardComponent, {
    panelClass: 'pnrTicketDialog',
      autoFocus: false,
      minHeight: '85%',
      width: '440px',
    data: {
      dataKey: details,
      details: details,
      flights:this.flights,
      flightData:this.flightData
    }})

 }

  flightDetails(orderId) {
    this.select=!this.select
    this.apis.flightDetails(this.token, orderId).subscribe((data: any) => {
        this.flightData = data.data;
        // console.log(this.flightData)
        this.travellersNum = +this.flightData.traveller_num;
        this.itinerary = JSON.parse(this.flightData.itinerary_details);
        this.details = true;
        // console.log(this.itinerary)
        this.openFlighetDetailsDailog(this.itinerary);
      },
      err => {
        console.log(err);
      });
  }

  calcTotalFlightTime(flights) {
    let totalTime = 0;
    flights.forEach(function(flight) {
      if (Array.isArray(flight)) {
        flight.forEach(function(stop) {
          totalTime += stop.ElapsedTime;
          totalTime += stop.FlightLayoverTime;
        });
      } else {
        totalTime += flight.ElapsedTime;
        totalTime += flight.FlightLayoverTime;
      }
    });
    return (`${Math.floor(totalTime / 60)}h ${totalTime % 60}m`);
  }

  hideShowStops(event) {
    const item = event.target.closest('.items-container').querySelector('.stops-tooltip');
    if (item) {
      item.classList.toggle('hide');
    }
  }

  toggleFlightDetails() {
    this.document.querySelector('.flightDetails.flightNumber').classList.toggle('hide');
  }

  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}h`;
    }
    convertedMinutes = `${minutes % 60}m`;
    return convertedHours + ' ' + convertedMinutes;
  }

  public closeCovidPopUp() {
    this.closedStatus = true;
  }

  counter(i: number) {
    return new Array(i);
  }

  recommended() {
    this.apis.recommendedPlaces().subscribe((data: any) => {
        this.recommendedPlaces = data.data.slice(0, 3);

      },
      err => {
        console.log(err);
      });
  }
  click(e){
      if (this.elemNumber !=undefined) {
        document.getElementById(this.elemNumber).style.border="1px solid lightgrey "
      }
     // document.getElementById(e).style.visibility="visible"
    let elem = document.getElementById(e)
    elem.style.border="1px solid red"
    this.elemNumber = e
  }

}
