<div class="billing-information review-trip-section my-3" *ngIf="offers == 'false' && !loading">
  <ng-container>
    <ng-container *ngIf="!messageError">
      <div class="w-100 row">
        <span class="price col-4">PRICE :</span>
        <span class="price amount_returned col-4">{{dataPnr?.amount_returned | currency}}</span>
        <ng-container *ngIf="dataPnr?.amount_returned == dataPnr?.amount_specified">
          <span class="price text-danger col-4">There is no discount applied.</span>
        </ng-container>
        <ng-container *ngIf="dataPnr?.amount_returned != dataPnr?.amount_specified">
        <span class="price amount_specified col-4">{{dataPnr?.amount_specified | currency}}</span>
        </ng-container>
      </div>
      <hr>
      <div class="w-100 row">
        <span class="price col-4" *ngIf="dataPnr?.amount_returned_for_child">PRICE FOR CHILD :</span>
        <span class="price amount_returned col-4">{{dataPnr?.amount_returned_for_child | currency}}</span>
        <ng-container *ngIf="dataPnr?.amount_returned == dataPnr?.amount_specified">
          <span class="price text-danger col-4">There is no discount applied.</span>
        </ng-container>
        <ng-container *ngIf="dataPnr?.amount_returned != dataPnr?.amount_specified">
        <span class="price amount_specified col-4">{{dataPnr?.amount_specified_for_child | currency}}</span>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="messageError">
      <p class="alert alert-danger ">{{messageError}} </p>
    </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="loading">
  <div class="box-img mb-3">
    <img class="img-loading" src="/assets/images/home/coparess/logo4.png" alt="logo">
  </div>
  <p class="mx-auto price-loading">Please wait we are working on getting you trip price </p>
  <!-- <i class="fas fa-spinner fa-spin airLineSpinner ml-2"></i> -->
</ng-container>
<div class="alert-body mb-3" *ngIf="messageError">
  <div class="alert-text col-12">
    <div class="alert-text  p-0">
      <div class="alert-Info">i</div>
      <label class="form-check-label " style="color: #fd5021;">We are unable to complete this booking.
      </label>
    </div> 
  </div>
  <div class="alert-text ms-2 col-12" style="margin-left: 3.5rem!important;">
    <div class="form-check p-0 ">
      <label class="form-check-label" style="
      font-weight: 500 !important;
  ">You're on the final step. Only a few more minutes to finish. Please provide a valid credit or debit card number
</label>
    </div>
  </div>
</div>
<div class="billing-information review-trip-section" *ngIf="!loading && !messageError">
  <form [formGroup]="form">
    <div class="row title">
    </div>
    <div class="row form border1 herefounddate">
      <div class="col-12">
        <h6 class=" title">Billing Details</h6>
        <p>
          Please Enter your card details to proceed the payment process as written on your card to avoid any mistakes.
        </p>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
          <div class="col-6 item">
            <div class="d-flex">
              <span class="star"></span>
              <label class="date-label">Name On Card</label>
            </div>
            <input formControlName="cardHolderFullName" type="text" class="form-control"
              placeholder="please Enter Name On Card" id="cardHolderFullName"
              (ngModelChange)="validationForm('cardHolderFullName')" autocomplete="off" required minlength="1"
              maxlength="50" />
            <mat-error *ngIf="form.controls.cardHolderFullName.errors?.pattern">
              Please Type Only Letters At least Three Names</mat-error>
            <mat-error
              *ngIf="form.controls.cardHolderFullName.errors?.required && form.controls.cardHolderFullName.dirty">
              Name On Card Is Requied</mat-error>
          </div>
          <!-- <div class="col-sm-4 item letters position-relative">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">First Name</label>
                </div>
                <input [ngClass]="{'input-error': submitError && form.controls.cardHolderFirstName.status == 'INVALID'}"
                       formControlName="cardHolderFirstName" type="text" [value]="" class="form-control" placeholder="First Name"
                       id="name" (change)="updateHolderName()"
                       autocomplete="off" required minlength="1" maxlength="50"/>
                <div class="mat-error position-absolute b-2" *ngIf="form.controls.cardHolderFirstName.errors?.pattern"> Please Type Only Letters</div>
                <div class="mat-error position-absolute b-2" *ngIf="form.controls.cardHolderFirstName.errors?.required && form.controls.cardHolderFirstName.touched">First Name Is Requied</div>

              </div>
              <div class="col-sm-4 item letters">
                <label for="middlename">Middle Name </label>
                <input
                  [ngClass]="{'input-error': submitError && form.controls.cardHolderMiddleName.status == 'INVALID'}"
                  formControlName="cardHolderMiddleName" type="text" class="form-control" placeholder="Middle Name"
                  id="middlename" (change)="updateHolderName()"
                  autocomplete="off" minlength="1" maxlength="50"/>
                <div class="mat-error position-absolute b-2" *ngIf="form.controls.cardHolderMiddleName.errors?.pattern"> Please Type Only Letters</div>
                <div class="mat-error position-absolute b-2" *ngIf="form.controls.cardHolderMiddleName.errors?.required && form.controls.cardHolderMiddleName.touched">
                  Middle Name Is Requied
                </div>
              </div>
              <div class="col-sm-4 position-relative item letters">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">Last Name</label>
                </div>
                <input [ngClass]="{'input-error': submitError && form.controls.cardHolderLastName.status == 'INVALID'}"
                       formControlName="cardHolderLastName" type="text" class="form-control" placeholder="Last Name"
                       id="lastname" (change)="updateHolderName()"
                       autocomplete="off" required minlength="1" maxlength="50"/>
                <div class="mat-error position-absolute b-2" *ngIf="form.controls.cardHolderLastName.errors?.pattern"> Please Type Only Letters</div>
                <div class="mat-error position-absolute b-2" *ngIf="form.controls.cardHolderLastName.errors?.required && form.controls.cardHolderLastName.touched">
                  Last Name Is Requied
                </div>
              </div> -->

          <div class="col-6 position-relative">
            <div class="item ">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">Card Number</label>
              </div>
              <!--              <app-review-flight-credit-card (setPaymentCardEvent)="setPaymentCardEvent($event)"-->
              <!--                                             [submitError]="submitError">-->
              <!--              </app-review-flight-credit-card>-->

              <div class="position-relative">
                <input type="text" formControlName="card" class="num form-control"
                  placeholder="Please Enter Card Number" autocomplete="off"
                  (ngModelChange)="testCreditCard();validationForm('card')" id="card" required />
                <img *ngIf="vendorCode" class="vendor-image" alt="vendor-image" title="Vendor Image"
                  src="assets/images/hotels/{{vendorCode}}.png">
              </div>
              <mat-error *ngIf="form.controls.card.errors?.pattern"> Please Type Only
                Numbers</mat-error>
                <mat-error *ngIf="form.controls.card.errors?.pattern"> Card Number Should be 16 Number
                </mat-error>
            </div>
          </div>

          <div class="col-6 position-relative">
            <div class="item">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">cvc</label>
              </div>
              <input maxlength="4" formControlName="cvc" (ngModelChange)="validationForm('cvc')" type="text"
                class="num form-control" placeholder="cvc" id="cvc" autocomplete="off" required />
                <img (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')" style="top: 60%;" class="vendor-image" alt="" title="Vendor Image"
                  src="assets/images/hotels/cvc.jpg">
            </div>
          </div>
          <div #dialog (mouseover)="dialog.classList.add('hidden-dialog')" (mouseout)="dialog.classList.remove('hidden-dialog')" class="col-9 position-absolute dialog-cvv">
            <div class="col-12">
            <h1>Why do we need this?</h1>
            <p>to Help us keep your data safe! Please provide us with your credit card verification number for added security measures.</p>
            </div>
            <div class="col-12 d-flex">
              <div class="col-6">
                <img src="assets/images/hotels/visa-cvv.png" alt="">
                <h2>The verification number for Visa, MasterCard, Discover, Diners Club and Carte Blanche</h2>
                <p>is the 3-digit number on the back of the credit card.</p>
              </div>
              <div class="col-6">
                <img src="assets/images/hotels/American-express-cvv.png" alt="">
                <h2>The Verification number for American Express credit cards</h2>
                <p>is the 4-digit number located on the front of the card.</p>
              </div>
            </div>
          </div>

          <div class="col-6 position-relative">
            <div class="item">
              <div class="d-flex">
                <span class="star"></span>
                <label class="date-label">Expiration Date</label>
              </div>
              <div class="d-flex justify-content-between " (keyup)="checkDate()">
                <div class="col-5">
                  <input type="text" formControlName="CreditCardExpireDateMonth" placeholder="MM"
                    class="num month form-control text-center"  required maxlength="2"
                    (ngModelChange)="validationForm('CreditCardExpireDateMonth')" id="CreditCardExpireDateMonth"
                    (change)="updateExpireDate()" (focusout)="format($event)" autocomplete="off" />

                </div>
                <div class="col-6">
                  <input type="text" formControlName="CreditCardExpireDateYear" placeholder="YYYY"
                    class="num year form-control text-center" required
                    (ngModelChange)="validationForm('CreditCardExpireDateYear')" id="CreditCardExpireDateYear"
                    (change)="updateExpireDate()" autocomplete="off" />
                </div>
              </div>
            </div>
          </div>
          <div class="errors col-12 position-relative ">
            <div class="mat-errors">
              <div class="b-2" *ngIf="form.controls.cvc.errors?.pattern"><p>Please Type Only 3 Numbers</p>
              <p>4 Numbers for American Express</p></div>
              <div class="b-2" *ngIf="form.controls.CreditCardExpireDateMonth.errors?.pattern"> Please
                Type Only Two Numbers Such As 07</div>
              <div class="b-2" *ngIf="errorMessageYear"> {{errorMessageYear}}</div>
              <div class="b-2" *ngIf="form.controls.CreditCardExpireDateYear.errors?.pattern"> Please Type
                Only Four Numbers Such As 2022</div>
              <div class="b-2" *ngIf="errorMessageMonth"> {{errorMessageMonth}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row form billing 
    " *ngIf="offers != 'false'">
      <div class="col-12 ">
        <h6 class="title">Billing Address</h6>
        <p>Your billing address is totally secure and confidential.</p>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
       
         <div class="col-6 position-relative">
            <div class="item">
                  <div class="d-flex">
                    <span class="star"></span>
                    <label class="date-label">Address</label>
                  </div>
              <input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" formControlName="CreditCardstreetAddress" class="form-control input" />
              <!-- <p>Address: {{ userAddress }}</p> -->
          </div>
        </div>
        <div class="col-6 position-relative">
          <div class="item">
            <div class="d-flex">
              <span class="star"></span>
              <label class="date-label">Country</label>
            </div>
            <!-- [matAutocomplete]="country_id"  -->
            <!-- (click)="$event.target.select()" -->
            <!-- (ngModelChange)="validationCountry('CreditCardCountry')" -->
            <input class="form-control" type="text"
              formControlName="CreditCardCountry" autocomplete="on" placeholder="Select your Country"
               id="CreditCardCountry"
               [ngModel]="country" (ngModelChange)="onChangeCountry($event)">
            <!-- <mat-autocomplete #country_id="matAutocomplete">
              <mat-option *ngFor="let option of filteredCountries | async" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete> -->
          </div>
          <mat-error *ngIf="form.get('CreditCardCountry').errors?.someProp">
            {{form.get('CreditCardCountry').errors?.someProp}}
          </mat-error>
          <mat-error *ngIf="f['CreditCardCountry'].errors?.required  && f['CreditCardCountry'].dirty"> Country Is
            Required
          </mat-error>
        </div>
            <div class=" col-6 position-relative ">
              <div class="item letters">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">City</label>
                </div>
                <input formControlName="CreditCardCity" type="text" class="form-control" placeholder="City"
                (ngModelChange)="onChangeCity($event)" id="CreditCardCity" autocomplete="off" required  [ngModel]="city"/>
                <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern"> Please Type Only Letters</mat-error>
                <mat-error *ngIf="f['CreditCardCity'].errors?.required  && f['CreditCardCity'].dirty"> City Is Required
                </mat-error>
              </div>
            </div>

            <div class="col-6 position-relative">
              <div class="item">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">Zip</label>
                </div>
                <input formControlName="CreditCardZip" type="text" class="num form-control" placeholder="Zip"
                  (ngModelChange)="onChangeZip($event)" id="CreditCardZip" autocomplete="off" required [ngModel]="ZipCode" />
              </div>
              <!-- <mat-error *ngIf="form.controls.CreditCardZip.errors?.pattern"> Please Type Only
                Numbers
              </mat-error> -->
              <mat-error *ngIf="f['CreditCardZip'].errors?.required  && f['CreditCardZip'].dirty"> Zip Is Required
              </mat-error>
            </div>

            <div class="col-6 position-relative">
              <div class="item">
                <div class="d-flex">
                  <span class="star"></span>
                  <label class="date-label">State</label>
                </div>
                <input formControlName="State" type="text" placeholder="State Code" class="form-control"
                (ngModelChange)="onChangeState($event)" id="State" autocomplete="off"
                  required [ngModel]="state" />
                <mat-error *ngIf="f['State'].errors?.required  && f['State'].dirty"> State Is Required
                </mat-error>
                <mat-error *ngIf="f['State'].errors?.pattern" >Please Type Only Letters</mat-error>
              </div>
            </div>
                    
          <div class="col-12 agree-share-contact my-3">
            <input type="checkbox" id="agreeShareContact" name="agreeShareContact" checked >
            <label for="agreeShareContact">I agree to share this contact information with the airline(s) associated with this booking</label>
          </div>
          
        </div>
      </div>
    </div>
    <!-- <div class="row" *ngIf="offers != 'false'">
      <div class="col-12">
        <h6 class="title">Contact Information</h6>
        <p>Please Enter Your Contact Information .</p>
      </div>
      <div class="row">
        <div class="col-6 position-relative">
          <div class="item">
            <div class="d-flex">
              <span class="star"></span>
              <label class="date-label">Email</label>
            </div>
            <input  class="form-control" formControlName="email" type="email" email placeholder="Email" id="email"
              autocomplete="off" (change)="validationForm('email')" required />
          </div>
        <div  class="col-1 validationMark">
          <div *ngIf="f['email'].valid && !messageCheckEmail" class="ml-2 ">
            <img style="height:3vh;" src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e" class="check-icon" alt="check" title="check" />
        </div>
      </div>
          <mat-error *ngIf="messageCheckEmail">{{messageCheckEmail}}</mat-error>
          <mat-error *ngIf="f['email'].errors?.required && f['email'].dirty">Your email is required</mat-error>
          <mat-error *ngIf="f['email']?.errors?.email && f['email'].dirty">
            Please Enter a Valid Email
          </mat-error>
        </div>
        <div class="col-6">
          <p class="booking-confirmation-note">Booking confirmation will be sent to this email address</p>
        </div>
        <div class="col-6 mobile-number position-relative">
          <div class="item">
            <div class="d-flex">
              <span class="star"></span>
              <label class="date-label">Phone Number</label>
            </div>
            <input style="display: block; width: 100%;" matInput class="form-control border" ng2TelInput placeholder="Phone Number" type="text" name="phone"
              formControlName="phone" id="phone" (ngModelChange)="validationForm('phone')" />
          </div>
          <mat-error *ngIf="form.controls.phone.errors?.pattern">
            Phone Number Is Required and Type Only Numbers
          </mat-error>
        </div>
        <div class="col-6 d-flex agree-share-contact">
          <input type="checkbox" id="agreeShareContact" name="agreeShareContact" checked >
          <label for="agreeShareContact">I agree to share this contact information with the airline(s) associated with this booking</label>
        </div>
      </div>
    </div> -->
  </form>
</div>
<div class="navigationButtons">
  <div class="col-12 my-4">
    <div class="row justify-content-center d-flex flex-row align-items-center">
      <button   class="btn navBtn previousStepBtn" (click)="previousStepEvent.emit()">Back To Travellers Details</button>
      <button  [disabled]="form.invalid" class="btn navBtn nextStepBtn" (click)="submit()">
        Review & Book
      </button>
    </div>
  </div>
</div>













<!-- <div class="col-6 position-relative">
  <div class="item">
    <div class="d-flex">
      <span class="star"></span>
      <label class="date-label">Address</label>
    </div> -->
    <!-- <input formControlName="CreditCardstreetAddress" type="text" placeholder="Address" autocomplete="off"
      (ngModelChange)="validationForm('CreditCardstreetAddress')" id="CreditCardstreetAddress"
      class="form-control" id="CreditCardstreetAddress" required /> -->
      <!-- <input formControlName="CreditCardstreetAddress" placeholder="Address" class="form-control input" id="CreditCardstreetAddress" required
      type="text" [(ngModel)]="autocompleteInput" (onAddressChange)="getAddress($event)"  adressType="geocode" #addresstext style="padding: 12px 20px; border: 1px solid #ccc; width: 400px"> -->
                  
      <!-- <AutocompleteComponent (setAddress)="getAddress($event)" adressType="geocode"></AutocompleteComponent>        -->
                 
                  
    <!-- <mat-error *ngIf="f['CreditCardstreetAddress'].errors?.required  && f['CreditCardstreetAddress'].dirty">
      Address Is Required
    </mat-error>
  </div>
</div> -->


<!-- <div class="col-6 position-relative">
  <div class="item letters">
    <div class="d-flex">
      <span class="star"></span>
      <label class="date-label">City</label>
    </div>
    <input formControlName="CreditCardCity" type="text" class="form-control" placeholder="City"
      (ngModelChange)="validationForm('CreditCardCity')" id="CreditCardCity" autocomplete="off" required />
    <mat-error *ngIf="form.controls.CreditCardCity.errors?.pattern"> Please Type Only Letters</mat-error>
    <mat-error *ngIf="f['CreditCardCity'].errors?.required  && f['CreditCardCity'].dirty"> City Is Required
    </mat-error>
  </div>
</div> -->

<!-- <div class="col-6 position-relative">
  <div class="item">
    <div class="d-flex">
      <span class="star"></span>
      <label class="date-label">Zip</label>
    </div>
    <input formControlName="CreditCardZip" type="text" class="num form-control" placeholder="Zip"
      (ngModelChange)="validationForm('CreditCardZip')" id="CreditCardZip" autocomplete="off" required />
  </div>
  <mat-error *ngIf="form.controls.CreditCardZip.errors?.pattern"> Please Type Only
    Numbers
  </mat-error>
  <mat-error *ngIf="f['CreditCardZip'].errors?.required  && f['CreditCardZip'].dirty"> Zip Is Required
  </mat-error>
</div> -->

<!-- <div class="col-6 position-relative">
  <div class="item">
    <div class="d-flex">
      <span class="star"></span>
      <label class="date-label">State</label>
    </div>
    <input formControlName="State" type="text" placeholder="State Code" class="form-control"
      (ngModelChange)="validationForm('State')" id="State" autocomplete="off"
      required />
    <mat-error *ngIf="f['State'].errors?.required  && f['State'].dirty"> State Is Required
    </mat-error>
    <mat-error *ngIf="f['State'].errors?.pattern" >Please Type Only Letters</mat-error>
  </div>

</div> -->

<!-- <div class="col-12 position-relative">
            <div class="item">
              <div class="d-flex">
                <label class="date-label">State Or Province (Optional)</label>
              </div>
              <input
                [ngClass]="{'input-error': (submitError && form.controls.stateOrProvince.status == 'INVALID')|| form.controls.stateOrProvince.errors?.pattern}"
                formControlName="stateOrProvince" type="text" placeholder="Credit Card state Or Province"
                class="form-control" id="creditCardstateOrProvince" autocomplete="off" />
            </div>
            <mat-error
              *ngIf="form.controls.stateOrProvince.errors?.pattern"> Please Type Only
              Letters
            </mat-error>
          </div> -->