import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ApisService} from '../../../services/apis.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DOCUMENT} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {DeleteMemberComponent} from '../delete-member/delete-member.component';
import {AddMemberComponent} from '../add-family-member/add-member.component';
import {LocalStorageService} from "../../../services/webApis/local-storage.service";

@Component({
  selector: 'app-family-members',
  templateUrl: './family-members.component.html',
  styleUrls: ['./family-members.component.css']
})
export class FamilyMembersComponent implements OnInit , OnChanges{
  @Input() selectable;
  @Input() searchRequest: any;
  @Output() selectTravellerEvent = new EventEmitter();
  @Output() unSelectTravellerEvent = new EventEmitter();
  addTraveler = false;
  showTraveler = false;
  passportInfo = false;
  addPassport = false;
  memberId: any;
  memberName: any;
  memberRelation: any;
  token: any;
  name: any;
  familyMembers: any;
  countries: any;
  relations: any;
  loading = true;
  dialogRef: any;
  owner: any;
  noPassport: any = [];
  localFlight: any;
  dataInto: boolean = false

  constructor(private apis: ApisService, private cookie: CookieService, private router: Router, public datePipe: DatePipe,
              @Inject(DOCUMENT) private document: Document, private matDialog: MatDialog ,private localStorage: LocalStorageService,) {
  }

  ngOnInit(): void {
    this.getCountries();
    // this.token = this.cookie.get('flyallover_token');
    this.token = this.localStorage.getItem('flyallover_token')
    this.getProfile();
    this.getFamilyMembers();
    this.getFamilyRelations();
    this.getAccountInfo();
  }
  getCountries(){
    this.apis.getCountries().subscribe((data: any) => {
      this.countries = data;
    }, err => {
    });
  }
  getAccountInfo(){
      this.apis.getAccountInfo(this.token).subscribe((data: any) => {
        this.name = data.data.first_name.toUpperCase() + ' ' + data.data.last_name.toUpperCase();
      },
      err => {
        console.log(err);
      });
  }

  ngOnChanges(changes) {
    if (changes.searchRequest.currentValue) {
      const countryFrom = changes.searchRequest.currentValue.fullDestination.split(',')[2].trim().toLowerCase();
      const CountryTo = changes.searchRequest.currentValue.fullOrigin.split(',')[2].trim().toLowerCase();
      if (countryFrom === CountryTo) {
        this.localFlight = true;
      }
    }
  }

  getProfile() {

      this.dataInto= true ;
    this.apis.getAccountInfo(this.token).subscribe((data: any) => {
        this.owner = data.data;
      },
      err => {
        console.log(err);
      });
  }

  opedAddMemberDialog() {
    this.dialogRef = this.matDialog.open(AddMemberComponent, {
      panelClass: 'ReviewFlightAddMemberDialog',
      autoFocus: false,
      maxHeight: '90vh',
      width: '60%',
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getFamilyMembers();
      }
    });
  }

  getFamilyMembers() {
    this.apis.familyMembers(this.token).subscribe((data: any) => {
        this.familyMembers = data.data;
        // console.log(data)
        this.loading = false;
        this.addTraveler = false;
        this.showTraveler = false;
        this.passportInfo = false;
        this.addPassport = false;
      },
      err => {
        console.log(err);
      });
  }

  getFamilyRelations() {
    this.apis.familyRelations(this.token).subscribe((data: any) => {
        this.relations = data.data;
      },
      err => {
        console.log(err);
      });
  }

  info(id) {
    this.addPassport = false;
    this.passportInfo = false;
    this.memberId = id;
  }

  getPassportInfo(id, name, relation = '') {
    this.memberId = id;
    this.memberName = name;
    this.memberRelation = relation;
  }

  newPassport(id) {
    this.memberId = id;
  }

  openDialog(id): void {
    this.dialogRef = this.matDialog.open(DeleteMemberComponent, {
      panelClass: 'delete-member',
      width: '50%',
      data: id
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getFamilyMembers();
      }
    });
  }

  closePassport() {
    this.passportInfo = false;
  }

  closeMember() {
    this.showTraveler = false;
  }

  closeAddPassport() {
    this.addPassport = false;
  }

  toggleCheck(e, member) {
    if (e.checked == true) {
      this.selectTravellerEvent.emit(member);
    } else {
      this.unSelectTravellerEvent.emit(member);
    }

    if (!this.localFlight && !member.passport) {
      this.noPassport[this.owner.id] = true;
    }
  }

}
