import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../services/webApis/local-storage.service';
import { ApisService } from '../../../services/apis.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { FlightDetailsDashboardComponent } from '../../flight-details-dashboard/flight-details-dashboard.component';
import { NotePayLaterComponent } from '../note-pay-later/note-pay-later.component';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {

  errorMessages;
  ticketData;
  ticketArray;
  paymentErrorMessages;

  errorData: any[];


  constructor(
    // @Inject(DOCUMENT) private document: Document,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    // private dialogRef: MatDialogRef<PaymentDetailsComponent>,
    private router: Router,
    private apis: ApisService,
    private localStorage: LocalStorageService,
    private _HttpClient: HttpClient,
    // public dialog: MatDialog,
  ) {
    // this.ticketData= data?.dataKey
    // this.ticketArray= this.ticketData.data
  }
  ngOnInit(): void {
  }

  goToReview() {
    this.router.navigate(['/profile/dashboard']);
  }


  payment() {


    this.apis.continueToPaymentStep.next(3);


  }

/*  routToDashboard(orderId) {
    this.token = this.localStorage.getItem('flyallover_token');

    this.apis.flightDetails(this.token, orderId).subscribe((data: any) => {
      this.flightData = data.data;

      this.travellersNum = +this.flightData.traveller_num;

      this.itinerary = JSON.parse(this.flightData.itinerary_details);
      this.router.navigate(['/profile'])
      this.openFlighetDetailsDailog(this.itinerary);
    },
      err => {
        console.log(err);
      });
  }*/

/*
  convertTime(minutes) {
    let convertedHours = '';
    let convertedMinutes = '';
    let space = ' ';
    if (Math.floor(minutes / 60) > 0) {
      convertedHours = `${Math.floor(minutes / 60)}hr(s)`;
    }
    convertedMinutes = `${minutes % 60}min(s)`;
    return convertedHours + space + convertedMinutes;
  }
*/
}
