import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SEOService} from '../../../services/seo.service';

@Component({
  selector: 'app-review-flight-itinerary-details',
  templateUrl: './review-flight-itinerary-details.component.html',
  styleUrls: ['./review-flight-itinerary-details.component.css']
})

export class ReviewFlightItineraryDetailsComponent implements  OnInit{
  @Input() itineraries;
  @Input() sabre
  @Input() resultArrived;
  @Input() loading;
  @Input() origin;
  @Input() destination;
  @Input() flights;
  @Output() nextStepEvent = new EventEmitter();
  @Output() fareDate = new EventEmitter();
  @Input() airlines;
  @Input() flightResultsPage;
  constructor(private seoService: SEOService){
    this.seoUpdate();
  }

  ngOnInit() {
    // console.log('check selected',this.itineraries);

  }
  foo(e){
    this.fareDate.emit(e);
  }
  seoUpdate() {

    this.seoService.updateTitle( 'Review Flight');
    this.seoService.updateDescription('Get all information about trips, flights and hotels with your profile in flyallover just sign up ');
    this.seoService.updateUrl();
    this.seoService.updateImage();
  }
}
