
import { Component, Injector, OnInit, Input,EventEmitter, ViewChild, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelsApisService } from 'src/app/services/hotels-apis.service';
import {OwlOptions} from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-new-hotels',
  templateUrl: './new-hotels.component.html',
  styleUrls: ['./new-hotels.component.css']
})
export class NewHotelsComponent implements OnInit {
  // @Output() hotelsDataflow: EventEmitter<string> = new EventEmitter<string>();

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    autoplay: false,
    autoplayTimeout: 2000,
    dots: false,
    navSpeed: 300,
    nav: true,
    navText: ['<i class="fas fa-arrow-left bg-nav"></i>', '<i class="fas fa-arrow-right bg-nav"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },

  };
  items = Array.from({length: 2}).map((_, i) => `Item #${i}`);
  step = 0;
  id: number;
  vacationDetails: any;
  long: any;
  lat: any;
  carouselImages: any;
  aminities: any;
  branchId: any;
  loadingSpinner: any;

  hotelsData: any;
  imgHotel: any;

  constructor(private activatedRoute: ActivatedRoute,
              private hotelsService: HotelsApisService,
              private router: Router,
  ) {
  }

  ngOnInit(): void {
    // this.getHotelsDetails();
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    // console.log(this.id);
    this.hotelsService.vacationDetails(this.id).subscribe((res: any) => {
      this.vacationDetails = res.data;
      this.branchId = this.vacationDetails.hotel.Branches[0].id;
      this.lat = this.vacationDetails.address.latitude;
      this.long = this.vacationDetails.address.longitude;
      this.carouselImages = this.vacationDetails.images;
      // console.log(this.vacationDetails);
      // console.log(this.branchId);
      this.hotelsService.getVacationsAminities(this.branchId).subscribe((res: any) => {
        this.aminities = res.data;


      });

      // console.log('hotelsData binding data', this.hotelsData);
    });


  }
  routToHotelPage(){
    this.router.navigate(['vacation-hotel-details/', this.id]);
  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  addhotelsDataNew(e): void {

    this.hotelsData = e;
    this.imgHotel = this.hotelsData.images;

    // console.log('this.hotelsDatthis.imgHotel', this.imgHotel);
    
  }

  // getHotelsDetails(){
  //   this.hotelsService.hotelsData().subscribe((res: any) => {
  //     this.hotelsData = res.data;
  //     console.log('this.hotelsDatthis.hotelsDat', this.hotelsData);
  //   });

  // }

}
