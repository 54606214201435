<!-- start navbar -->
<app-navbar></app-navbar>
<!-- end navbar -->

<!-- start help-center-page -->
<div class="help-center-page">

    <div class="help-center-header">
        <div class="container">
            <h1 class="text-center Font-Family ">Terms and Conditions</h1>
            <div class="frm">
                <input type="text" placeholder="How can we help you?" [(ngModel)]="searchText"
                    (keyup.enter)="onSearch()" />
                <button (click)="onSearch()"><img title="Terms and Conditions" alt="Search"  src="./assets/images/help-center/search.svg" height="30" /></button>
            </div>
        </div>
    </div>
    <!-- start skeleton preloader -->
    <div class="help-center-body" *ngIf="loading">
        <div class="container">
            <div class="search-results">
                <div class="search-results-header Font-Family">
                    <h4 class="Font-Family">
                        <ngx-skeleton-loader count="1" [theme]="{width:'200px'}"></ngx-skeleton-loader>
                    </h4>
                </div>

                <div class="search-results-body">
                    <div class="item Font-Family">
                        <ngx-skeleton-loader count="1" [theme]="{width:'300px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="4" [theme]="{width:'100%', height:'15px', 'margin-bottom':'0px'}">
                        </ngx-skeleton-loader>
                    </div>



                </div>
            </div>
        </div>
    </div>
    <!-- end skeleton preloader -->
    <!-- start search-results -->
    <div class="help-center-body" *ngIf="!loading && !backEndError">
        <div class="container">
            <div class="search-results my-5 Font-Family" >
                <div class="search-results-header Font-Family">
                    <h6 class="fao_mobile_title_large" [innerHTML]="termsConditions['title_content']"></h6>
                </div>

                <div class="search-results-body">
                    <div class="item">
                        <p class="Font-Family my-5" [innerHTML]="termsConditions['content']"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end search-results -->
    <div *ngIf="backEndError && !loading">
        <app-no-result-error [homePath]="'/support'"></app-no-result-error>
    </div>
</div>
<!-- end help-center-page -->

<!-- start footer -->
<app-footer></app-footer>
<!-- end footer -->
